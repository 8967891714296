import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Form, Button, Card, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { getITParkList } from "../../services/Park";
import { getITPhaseList, createBuilding } from "../../services/Phase";
import { showToast } from "../shared/Toaster";
import { useSelector } from "react-redux";
import { getBuildingDetails, updateBuildingDetails } from "../../services/Company";

export default function CreateBuilding() {
  const [roleName, setRoleName] = useState("");

  const [buildingname, setBuildingName] = useState("");
  const [numberoffloors, setNumberOfFloors] = useState("");

  const phaseId = useSelector((state) => state.phase.phaseId);
  const parkId = useSelector((state) => state.park.parkId);

  const role_name = localStorage.getItem("role_name");

  const defaultITPark =
    role_name === "Super Admin" ? parkId : localStorage.getItem("userPark");
  const defaultPhase =
    role_name === "Super Admin" || role_name === "Park Admin"
      ? phaseId
      : localStorage.getItem("userPhase");

  const [itpark, setITPark] = useState(defaultITPark);
  const [phase, setPhase] = useState(defaultPhase);

  // State variables for error messages
  const [buildingnameError, setBuildingNameError] = useState("");
  const [itparkError, setITParkError] = useState("");
  const [phaseError, setPhaseError] = useState("");
  const [numberoffloorsError, setNumberOfFloorsError] = useState("");

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const buildingId = query.get("id");

  //For IT Park List
  const [itparkList, setITParkList] = useState("");
  //For Phase List
  const [itphaseList, setITPhaseList] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGoBack = () => {
    // navigate("/dashboard/storage"); // Navigate to the storage page
    navigate("/dashboard/building-list/?gen");
  };

  //For getting the building details in the case of edit building
  useEffect(() => {
    const fetchBuildingDetails = async () => {
      try {
        const response = await getBuildingDetails(buildingId);
        const building_details = response.data.data;
        if (response) {
          setBuildingName(building_details?.name);
          setNumberOfFloors(building_details?.numberOfFloors)
        }
      } catch (error) {
      }
    };
    fetchBuildingDetails();
  }, [buildingId]);

  useEffect(() => {
    if (buildingId) {
      document.title = "Edit Building";
    } else {
      document.title = "Create Building";
    }
    const role_name = localStorage.getItem("role_name");
    if (role_name == "Super Admin") {
      setRoleName("Super Admin");
    } else if (role_name == "Park Admin") {
      setRoleName("Park Admin");
    } else if (role_name == "Phase Admin") {
      setRoleName("Phase Admin");
    }
    const fetchITParkList = async () => {
      if (role_name == "Super Admin") {
        try {
          const response = await getITParkList(); // Assuming getITParkList is an async function that fetches data
          setITParkList(response.data.data.park_list.results); // Store the data in state
        } catch (error) {
        }
      } else {
        const data = [
          {
            id: localStorage.getItem("userPark"),
            parkName: localStorage.getItem("userParkName"),
          },
        ];
        setITParkList(data);
      }
    };

    const fetchITPhaseList = async (park_id) => {
      if (role_name == "Phase Admin") {
        const data = [
          {
            id: localStorage.getItem("userPhase"),
            PhaseName: localStorage.getItem("userPhaseName"),
            IT_PARK: localStorage.getItem("userParkName"),
            IT_PARK_Id: localStorage.getItem("userPark"),
          },
        ]; //Mock data for park
        setITPhaseList(data);
      } else {
        try {
          const response = await getITPhaseList(park_id); // Assuming getITParkList is an async function that fetches data
          setITPhaseList(response.data.data.phase_list.results); // Store the data in state
        } catch (error) {
        }
      }
    };

    fetchITParkList();
    if (itpark) {
      fetchITPhaseList(itpark);
    }
    switchSkin(skin);
  }, [skin, itpark]);

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  // Event handler for building name change
  const handleBuildingNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setBuildingName(value);
      const error = validateBuildingName(value);
      setBuildingNameError(error);
    }
  };

  // Event handler for number of floors change
  const handleNumberOfFloorsChange = (event) => {
    const value = event.target.value;
    setNumberOfFloors(value);
    const error = validateNumberOfFloors(value);
    setNumberOfFloorsError(error);
  };

  const handleITParkChange = async (event) => {
    const value = event.target.value;
    setITPark(value);
    const error = validateITPark(value);
    setITParkError(error);

    if (!error) {
      try {
        const response = await getITPhaseList(value); // Assuming getITPhaseList fetches phase data for the selected park
        if (response.data.data.phase_list.results.length > 0) {
          setITPhaseList(response.data.data.phase_list.results);
        } else {
          setITPhaseList(undefined);
        }
        setPhase("");
      } catch (error) {
        setITPhaseList(undefined);
        setPhase("");
      }
    }
  };

  // Event handler for phase change
  const handlePhaseChange = (event) => {
    const value = event.target.value;
    setPhase(value);
    const error = validatePhase(value);
    setPhaseError(error);
  };

  // Function to validate building name

  const validateBuildingName = (value) => {
    if (!value.trim()) {
      return "Building name is required";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Building name must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return "Building name must not exceed 100 characters";
    }
  };

  // Function to validate number of floors
  const validateNumberOfFloors = (value) => {
    if (typeof value !== 'string') {
      value = String(value);
    }
    if (!value.trim()) {
      return "Number of floors is required";
    }
    if (/\s/.test(value)) {
      return "Number of floors should not contain spaces";
    }
    if (isNaN(value) || Number(value) <= 0 || Number(value) > 100) {
      return "Number of floors should be a number between 1 and 100";
    }
    return "";
  };

  const validateITPark = (value) => {
    if (!value || value === "undefined") {
      return "IT Park is required";
    }
    return "";
  };

  const validatePhase = (value) => {
    if (!value || value === "undefined") {
      return "Phase is required";
    }
    return "";
  };

  // Submit handler
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if(!buildingId) {
      // Validate all fields
    const buildingNameError = validateBuildingName(buildingname);
    const itparkError = validateITPark(itpark);
    const phaseError = validatePhase(phase);
    const numberOfFloorsError = validateNumberOfFloors(numberoffloors);

    // Set errors for each field
    setBuildingNameError(buildingNameError);
    setITParkError(itparkError);
    setPhaseError(phaseError);
    setNumberOfFloorsError(numberOfFloorsError);

    if (buildingNameError || numberOfFloorsError || itparkError || phaseError) {
      setLoading(false);
      return;
    } else {
      try {
        const formData = {
          buildingName: buildingname,
          numberOfFloors: numberoffloors,
          phase: phase,
        };
        // Proceed with form submission
        const response = await createBuilding(formData);
        setLoading(false);
        setBuildingName("");
        setNumberOfFloors("");
        if (roleName === "Park Admin" && roleName === "Super Admin") {
          setPhase("");
        }
        if (roleName === "Super Admin") {
          setITPark("");
        }

        showToast("success", response.data.message);
        navigate("/dashboard/building-list/?gen");
      } catch (error) {
        // Handle submission error
        setLoading(false);
        if (error.response) {
          showToast("error", error.response.data.message);
        } else {
          showToast("error", "Internal server Error");
        }
      }
    }
    }else {
      // Validate all fields
    const buildingNameError = validateBuildingName(buildingname);
    const phaseError = validatePhase(phase);
    const numberOfFloorsError = validateNumberOfFloors(numberoffloors);

    // Set errors for each field
    setBuildingNameError(buildingNameError);
    setPhaseError(phaseError);
    setNumberOfFloorsError(numberOfFloorsError);

    if (buildingNameError || numberOfFloorsError || phaseError) {
      setLoading(false);
      return;
    } else {
      try {
        const formData = {
          buildingName: buildingname,
          numberOfFloors: numberoffloors,
          phase: phase,
        };
        // Proceed with form submission
        const response = await updateBuildingDetails(formData, buildingId);
        setLoading(false);
        setBuildingName("");
        setNumberOfFloors("");
        if (roleName === "Park Admin" && roleName === "Super Admin") {
          setPhase("");
        }
        showToast("success", response.data.message);
        navigate("/dashboard/building-list/?gen");
      } catch (error) {
        // Handle submission error
        setLoading(false);
        if (error.response) {
          showToast("error", error.response.data.message);
          // }
        } else {
          showToast("error", "Internal server Error");
        }
      }
    }
    }
  };

  return (
    <div>
      <Header onSkin={setSkin} />
      <div className=" main main-app d-flex flex-column align-items-start p-3 p-lg-4">
        <div className="my-3 ps-md-3 align-self-start">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line "
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/building-list/?gen">
                    <span>Go back</span>
                  </Link>
                </li>
              </ol>
              {buildingId ? (
                <h4 className="main-title mb-0">Edit Building</h4>
              ) : (
                <h4 className="main-title mb-0">Create Building</h4>
              )}
            </div>
          </div>
        </div>

        <Col xs="12" md="12">
          <hr className="mb-5" />
          <Card.Body className="ps-md-3 " style={{ maxWidth: "960px" }}>
            <Form onSubmit={handleSubmit}>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                  <div className="d-flex flex-column gap-2">
                    <Card.Title as="h6">
                      <b>Building Details</b>
                    </Card.Title>
                    <div style={{ maxWidth: "320px" }} className="text-muted">
                      Provide the necessary information to create a building.
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter building name"
                          value={buildingname}
                          onChange={handleBuildingNameChange}
                        />
                        {buildingnameError && (
                          <div className="text-danger">{buildingnameError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          Number of floors{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter number of floors in building "
                          value={numberoffloors}
                          onChange={handleNumberOfFloorsChange}
                        />
                        {numberoffloorsError && (
                          <div className="text-danger">
                            {numberoffloorsError}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                    {!buildingId && (
                      <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          IT Park<span className="text-danger"></span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          as="select"
                          value={itpark}
                          onChange={handleITParkChange}
                          disabled={
                            roleName === "Phase Admin" ||
                            roleName === "Park Admin"
                          } // Disable the select if roleName is not set ( if role_name is Phase Admin )
                        >
                          <option value="">Select IT Park</option>
                          {itparkList === undefined && (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}
                          {Array.isArray(itparkList) &&
                            itparkList.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.parkName}
                              </option>
                            ))}
                        </Form.Select>

                        {itparkError && (
                          <div className="text-danger">{itparkError}</div>
                        )}
                      </Form.Group>
                    </div>
                    )}
                    
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          Phase<span className="text-danger"></span>
                        </Form.Label>

                        <Form.Select
                          aria-label="Default select example"
                          as="select"
                          value={phase}
                          onChange={handlePhaseChange}
                          disabled={roleName === "Phase Admin"} // Disable the select if roleName is not set ( if role_name is Phase Admin )
                        >
                          <option value="">Select Phase</option>
                          {itphaseList === undefined && (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}
                          {Array.isArray(itphaseList) &&
                            itphaseList.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.PhaseName}
                              </option>
                            ))}
                        </Form.Select>

                        {phaseError && (
                          <div className="text-danger">{phaseError}</div>
                        )}
                      </Form.Group>
                    </div>

                    <div style={{ minWidth: "320px" }}>
                      {buildingId ? (
                        <Button
                          type="submit"
                          variant="primary"
                          className="btn-sign mt-4"
                          disabled={loading}
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Save"
                          )}
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="primary"
                          className="btn-sign mt-4"
                          disabled={loading}
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Create Building"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Col>
      </div>
    </div>
  );
}
