import { axiosInstance } from "./axiosInstance";


// Request interceptor for adding the bearer token
export function axiosInterceptor() {
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Assuming you store the token in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
}


// Export the api instance
export default axiosInterceptor;
