import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { showToast } from "../components/shared/Toaster";
import { send_email } from "../services/auth";


export default function ForgotPassword() {
  useEffect(() => {
    document.title = "Forgot Password";
  }, []);

  document.body.classList.remove("sidebar-show");

  const [email, setEmail] = useState("");
  const emailRef = useRef(null);
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  // Event handler for email change
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    const error = validateEmail(value);
    setEmailError(error);
  };

  // Function to validate mobile number
  const validateEmail = (value) => {
    if (!value.trim()) {
      return "Email id is required";
    }
   
    const emailRegex =
      /^(?!.*\.\.)(?!.*\.[0-9])[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;
    // Check if the value matches the email regex
    if (!emailRegex.test(value)) {
      return "Please enter a valid email id";
    }
    return "";
  };
  const navigate = useNavigate();


  //submit handler
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate email field
    setLoading(true);
    const emailError = validateEmail(email);

    // Set errors for each field
    setEmailError(emailError);

    if (emailError) {
      setLoading(false);
      return;
    } else {
      try {
        const user = await send_email({ email });
        setLoading(false);
        setEmail("");
        showToast("success", user.data.message);
      } catch (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.data.message) {
            setEmailError(error.response.data.message);
          } else if (error.response.status == 500) {
            showToast("error", error.response.statusText);
          }
        } else {
          showToast("error", "Internal Server Error");
        }
      }
    }
  };

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">
            Entry Pass
          </Link>
          <Card.Title>Reset your password</Card.Title>
          <Card.Text>
            Enter your email id and we will send you a link to reset your
            password.
          </Card.Text>
        </Card.Header>

        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label>
                Email ID<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email id "
                value={email}
                onChange={handleEmailChange}
                ref={emailRef}
              />
              {emailError && <div className="text-danger">{emailError}</div>}
   
            </div>
            {loading && (
              <Button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Send Email</span>
              </Button>
            )}
            {!loading && (
              <Button type="submit" variant="primary" className="btn-sign">
                Send Email
              </Button>
            )}
            <div className="mt-3 text-center">
              {/* <Link to="/pages/signin">Back to Login</Link> */}
              <Link to="/">Back to Login</Link>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
