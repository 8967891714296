import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import Signin from "./pages/Signin";
import AuthGuard from "./services/AuthGuard";
import Forbidden from "../src/pages/Forbidden";

import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import { useEffect } from "react";



// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});


export default function App() {

  return (
    <React.Fragment>
      <ToastContainer />
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/pages/error-505" element={<Forbidden />} />
          {/* Public routes */}
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.element}
            />
          ))}

          {/* Protected routes */}
          <Route path="/" element={<Main />}>
            {protectedRoutes?.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<AuthGuard element={route.element}
                requiredRoles={route.requiredRoles} // Pass requiredRoles here 
                />} 
                // Guard setting can enable once the login integration is done for the time being its commented
              />
              
            ))}
          </Route>

          {/* Not found route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}
