import { axiosInstance } from "./axiosInstance";

export const markEntry = (data) => {
  return axiosInstance.post(`/api/pass/markentry`, data);
}

export const fetchVisitorCompanyLogs = (CompanyId, currentPage, pageSize, startDate, endDate) => {
    return axiosInstance.get(`/api/pass/visitor-logs?CompanyId=${CompanyId}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&page_size=${pageSize}`);
  };

  export const fetchVisitorLogs = (currentPage, pageSize, startDate, endDate) => {
    return axiosInstance.get(`/api/pass/visitor-logs?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&page_size=${pageSize}`);
  };
