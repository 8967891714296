import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const AuthGuard = ({ element: Element, requiredRoles, ...rest }) => {
  const { isAuthenticated, role } = useAuth();
  const reset_password = localStorage.getItem("reset_password");
  if (!isAuthenticated || reset_password == "false") {
    return <Navigate to="/" />;
  }

  if (requiredRoles && !requiredRoles.includes(role)) {
    return <Navigate to="/pages/error-505" />;
  }

  return <Element {...rest} />;
};

export default AuthGuard;
