import { SET_COMPANY_ID, SET_PHASE_ID } from './Actions';
import { SET_PARK_ID} from './Actions';
import { SET_BUILDING_ID } from './Actions';
import { SET_USER_ID } from './Actions';
import { LOGOUT } from './Actions';

const initialState = {
  phaseId: null,
  parkId:null,
  BuildingId:null,
  userId:null
};

export const phaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PHASE_ID:
      return {
        ...state,
        phaseId: action.payload,
      };
      case 'LOGOUT':
        return {
          ...state,
          phaseId: null,
        };
    default:
      return state;
  }
}

export const parkReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARK_ID:
      return {
        ...state,
        parkId: action.payload,
      };
      case 'LOGOUT':
        return {
          ...state,
          parkId: null,
        };
    default:
      return state;
  }
};
export const buildingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUILDING_ID:
      return {
        ...state,
        BuildingId: action.payload,
      };
      case 'LOGOUT':
        return {
          ...state,
          BuildingId: null,
        };
    default:
      return state;
  }
};
export const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_ID:
      return {
        ...state,
        CompanyId: action.payload,
      };
      case 'LOGOUT':
        return {
          ...state,
          ComapnyId: null,
        };
    default:
      return state;
  }
};
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
      case 'LOGOUT':
        return {
          ...state,
          userId: null,
        };
    default:
      return state;
  }
};