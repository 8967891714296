// Action Types
export const SET_PHASE_ID = "SET_PHASE_ID";
export const SET_PARK_ID = "SET_PARK_ID";
export const SET_BUILDING_ID = "SET_BUILDING_ID";
export const SET_COMPANY_ID = "SET_COMPANY_ID";
export const SET_USER_ID = "SET_USER_ID";
export const LOGOUT = "LOGOUT";

// Action Creators
export const setPhaseId = (id) => ({
  type: SET_PHASE_ID,
  payload: id,
});

// Action Creators
export const setParkId = (id) => ({
  type: SET_PARK_ID,
  payload: id,
});

export const setBuildingId = (id) => ({
  type: SET_BUILDING_ID,
  payload: id,
});

export const setCompanyId = (id) => ({
  type: SET_COMPANY_ID,
  payload: id,
});

export const setUserId = (id) => ({
  type: SET_USER_ID,
  payload: id,
});
