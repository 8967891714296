import React, { useEffect, useState, useRef } from "react";
import Header from "../layouts/Header";
import { Button, Card, Col, Row, Table, Pagination } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { QrReader } from "react-qr-reader";
import { Html5QrcodeScanner } from "html5-qrcode";
import photo1 from "../assets/img/photo1.jpg";
import pancard from "../assets/img/pancard.jpg";
import VisitorDetailsModal from "../components/Company/VisitorDetailsModal";
import { getVisitorDetails } from "../services/Phase";
import { showToast } from "../components/shared/Toaster";
import { fetchVisitorLogs } from "../services/GateUser";
import MessageModal from "../components/Company/messageModal";

const QRCodeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 24 24"
    style={{ stroke: "white", fill: "gray" }}
  >
    <path d="M4 4h4.01V2H2v6h2V4zm0 12H2v6h6.01v-2H4v-4zm16 4h-4v2h6v-6h-2v4zM16 4h4v4h2V2h-6v2z"></path>
    <path d="M5 11h6V5H5zm2-4h2v2H7zM5 19h6v-6H5zm2-4h2v2H7zM19 5h-6v6h6zm-2 4h-2V7h2zm-3.99 4h2v2h-2zm2 2h2v2h-2zm2 2h2v2h-2zm0-4h2v2h-2z"></path>
  </svg>
);

export default function GateUserDashboard() {
  const [roleName, setRoleName] = useState("");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");
  const scannerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [visitor, setVisitor] = useState(null);
  const [visitorDetails, setVisitorDetails] = useState(null);
  const [validity, setValidity] = useState("");
  const [loading, setLoading] = useState(false);
  const [visitorLogs, setVisitorLogs] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);
  const [messageModal, setMessageModal] = useState({
    show: false,
    title: "",
    message: "",
    variant: "primary",
  });
  // Set initial visitor logs
  // Get today's date
  const today = new Date();

  // Get the day, month, and year
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const year = today.getFullYear();

  // Format the date as DD/MM/YYYY
  const formattedDate = `${day}/${month}/${year}`;
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Page size of 6
  const [totalItems, setTotalItems] = useState(0);
  const [displayedPages, setDisplayedPages] = useState([]);

  const handleClose = (result) => {
    setShow(false);
    if (result.success) {
      setModalSuccess(true);
    }
  };
  useEffect(() => {
    updateDisplayedPages();
  }, [currentPage, totalItems]);

  useEffect(() => {
    if (startScan && scannerRef.current) {
      const scanner = new Html5QrcodeScanner(
        "reader",
        {
          fps: 10,
          qrbox: 250,
        },
        false
      );

      scanner.render(
        async (text) => {
          if (typeof text !== "string") {
            setMessageModal({
              show: true,
              title: "Error",
              message: "Invalid QR code",
              variant: "error",
            });
            setStartScan(false);
            return;
          }

          const urlPattern = /^http:\/\/|^https:\/\/wa\.me\/qr\/[A-Z0-9]+/;
          if (urlPattern.test(text)) {
            setMessageModal({
              show: true,
              title: "Error",
              message: "Invalid QR code",
              variant: "error",
            });
            setStartScan(false);
            return;
          }

          setStartScan(false);

          try {
            const details = await getVisitorDetails(text);
            let data = {
              id: details.data.data.id,
              name: details.data.data.name,
              email: details.data.data.email,
              phonenumber: details.data.data.phonenumber,
              purpose: details.data.data.purpose,
              company: details.data.data.company.name,
              floor: details.data.data.company.floor,
              building: details.data.data.company.building,
              phase: details.data.data.company.phase,
              park: details.data.data.company.park,
              start_datetime: details.data.data.start_datetime,
              end_datetime: details.data.data.end_datetime,
              files: {
                photo: details.data.data.files.photo,
                id_proof: details.data.data.files.id_proof,
              },
            };

            const validity = details.data.data.validity.validity;
            const reason = details.data.data.validity.reason;

            if (validity === "invalid") {
              setValidity(validity);
              setVisitor({ ...data, reason });
              setShow(true);
            } else if (validity === "valid") {
              setVisitor(data);
              setValidity(validity);
              setShow(true);
            }
          } catch (error) {
            let errorMessage = "Invalid QR code"; // Default error message
            // Check if error response contains the custom error message
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMessage = error.response.data.message;
            }
            setMessageModal({
              show: true,
              title: "Error",
              message: errorMessage,
              variant: "error",
            });
          } finally {
            scanner.clear();
            setStartScan(false);
          }
        },
        (err) => {}
      );

      return () => {
        scanner.clear();
      };
    }
  }, [startScan]);
  useEffect(() => {
    document.title = "Dashboard";
    const storedRoleName = localStorage.getItem("role_name");
    if (storedRoleName) {
      // If role_name is found in local storage, set it in state
      setRoleName(storedRoleName);
    }
  }, []); // empty dependency array to run the effect only once when the component mounts

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    //Api integration of visitor logs
    const getVisitorLogs = async () => {
      try {
        setLoading(true);
        const response = await fetchVisitorLogs(
          currentPage,
          pageSize,
          startDate,
          endDate
        );
        setVisitorLogs(response.data.data.results);
        setTotalItems(response.data.data.count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    getVisitorLogs();
    switchSkin(skin);
    if (modalSuccess === true) {
      getVisitorLogs(); // Call visitorLog function when modalSuccess is true
      setModalSuccess(false); // Reset modalSuccess to false
    }
  }, [skin, currentPage, pageSize, modalSuccess]);

  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  // Function to handle page change
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updateDisplayedPages = () => {
    const totalPages = Math.ceil(totalItems / pageSize);
    const maxDisplayedPages = 3;
    const halfRange = Math.floor(maxDisplayedPages / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

    if (endPage - startPage < maxDisplayedPages - 1) {
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    setDisplayedPages(pages);
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const closeMessageModal = () => {
    setMessageModal({ ...messageModal, show: false });
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
            </ol>
            <h4 className="main-title mb-0">Welcome to Dashboard</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="5">
            <Card className="card-one">
              {!startScan && (
                <Card.Body className="p-3 d-flex justify-content-center align-items-center">
                  <Row className="g-3">
                    <Col xs="12">
                      <div className="pb-4 d-flex flex-column gap-4  justify-content-center align-items-center">
                        <QRCodeIcon />

                        <div className="d-flex flex-column gap-2">
                          Scan your QR code here
                          <div className="d-flex justify-content-center">
                            <Button
                              className="bg-primary text-white"
                              onClick={() => {
                                setStartScan(!startScan);
                              }}
                            >
                              {startScan ? "Stop Scanning" : "Start Scanning"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              )}
              {startScan && (
                <Card.Body className="p-3">
                  <Row className="g-3">
                    <Col xs="12" className="pe-4">
                      <div id="reader" ref={scannerRef}></div>
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Col>

          <Col xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Visited Logs</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <Table className=" mb-4" responsive>
                  <thead>
                    <tr>
                      <th className="text-left">Visitor Name</th>
                      <th className="text-left">Company Name</th>
                      <th className="text-left">Entry Date & Time</th>
                      <th className="text-left">Purpose</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100px" }}
                          >
                            <span
                              className="spinner-border spinner-border-lg text-primary"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    )}
                    {Array.isArray(visitorLogs) && !loading ? (
                      visitorLogs.map((item, index) => (
                        <tr key={index}>
                          <td className="text-left">{item.name}</td>
                          <td className="text-left">{item.company}</td>
                          <td className="text-left">{item.entry_datetime}</td>
                          <td className="text-success text-left">
                            {item.purpose}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <h6 className="fw-semibold mb-1 text-left">
                  <div>
                    {!loading && visitorLogs?.length > 0 && (
                      <Pagination className="pagination-space justify-content-start pagination-filled  p-3 p-lg-4">
                        <Pagination.Prev
                          onClick={() =>
                            currentPage > 1 && onPageChange(currentPage - 1)
                          }
                          disabled={currentPage === 1}
                        />
                        {displayedPages.map((number) => (
                          <Pagination.Item
                            key={number}
                            active={number === currentPage}
                            onClick={() => onPageChange(number)}
                          >
                            {number}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={() =>
                            currentPage < totalPages &&
                            onPageChange(currentPage + 1)
                          }
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>
                    )}
                  </div>
                </h6>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>

      {visitor && (
        <VisitorDetailsModal
          show={show}
          handleClose={handleClose}
          visitor={visitor}
          validity={validity}
        />
      )}
      <MessageModal
        show={messageModal.show}
        onHide={closeMessageModal}
        title={messageModal.title}
        message={messageModal.message}
        variant={messageModal.variant}
      />
    </React.Fragment>
  );
}
