import { axiosInstance } from "./axiosInstance";

  export const passSearch = (currentPage, pageSize, url) => {
    return axiosInstance.get(`/api/pass/pass?page=${currentPage}&page_size=${pageSize}&${url}`);
  };

  export const passSearchCompanyWise = (companyId,currentPage, pageSize, url) => {
    return axiosInstance.get(`/api/pass/pass?companyId=${companyId}&page=${currentPage}&page_size=${pageSize}&${url}`);
  };

  export const cancelPass = (pass_id) => {
    return axiosInstance.put(`/api/pass/pass-cancel/${pass_id}/`);
  };

  export const passHistory = (visitor_id) => {
    return axiosInstance.get(`/api/pass/visitor-history/?visitorId=${visitor_id}`);
  };
  

  