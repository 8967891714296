// AuthContext.js

import React, { createContext, useContext, useState, useEffect } from "react";
import {
  login as apiLogin,
  password_reset_with_token as reset_with_token,
  password_reset_without_token as reset_without_token,
  check_token_validity as token_validity,
} from "./auth"; // Import the login function from auth.js
import { setParkId, setPhaseId } from "../Redux/Actions";
import { LOGOUT } from "../Redux/Actions"; // Import the logout action type
import { useDispatch } from "react-redux";

// Create a new context
const AuthContext = createContext();

// AuthProvider component to wrap your app and provide authentication state and functions
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Initialize the state based on localStorage
    const storedAuthState = localStorage.getItem("isAuthenticated");
    return storedAuthState === "true";
  });
  const [role, setRole] = useState(() => {
    const storedRole = localStorage.getItem("role_name");
    return storedRole || null;
  });

  const dispatch = useDispatch();

  // Function to handle login
  const login = async (credentials) => {
    try {
      // Call the login function from auth.js
      const response = await apiLogin(credentials);
      // If login successful, set isAuthenticated to true
      setIsAuthenticated(true);
      if (response.data.data.user_type != 2) {
        setRole(response.data.data.roles.role_name); // Assuming the response contains user role
      } else if (response.data.data.user_type === 2) {
        setRole("Gate User"); // Since the gate user has no role assuming this is the Gate User role
      }
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("role", response.data.data.roles.role_name);
      
      return response;
    } catch (error) {
      // Handle login errors
      
      throw error; // Rethrow the error for components to handle
    }
  };

  // Function to handle logout
  const logout = () => {
    // Perform logout logic here
    localStorage.clear();
    setIsAuthenticated(false);
    dispatch({ type: LOGOUT });
  };

  // Function to handle reset password with token
  const password_reset_with_token = async (data) => {
    try {
      // Call the login function from auth.js
      const response = await reset_with_token(data);
      // If login successful, set isAuthenticated to true

      return response;
    } catch (error) {
      // Handle login errors
      
      throw error; // Rethrow the error for components to handle
    }
  };

  // Function to handle reset password without token
  const password_reset_without_token = async (data) => {
    try {
      // Call the login function from auth.js
      const response = await reset_without_token(data);
      // If login successful, set isAuthenticated to true
      return response;
    } catch (error) {
      // Handle login errors
      
      throw error; // Rethrow the error for components to handle
    }
  };

  // Function to check the token is expired or not
  const check_link_expired = async (data) => {
    try {
      // Call the login function from auth.js
      const response = await token_validity(data);
      // If login successful, set isAuthenticated to true
      return response;
    } catch (error) {
      // Handle login errors
      
      throw error; // Rethrow the error for components to handle
    }
  };

  // Value object to provide to the context consumers
  const value = {
    isAuthenticated,
    role,
    login,
    logout,
    password_reset_with_token,
    password_reset_without_token,
    check_link_expired,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Custom hook to use the authentication context
export const useAuth = () => useContext(AuthContext);
