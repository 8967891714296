import React from "react";

// Dashboard

import StorageManagement from "../dashboard/StorageManagement";
import GenericDashboard from "../dashboard/GenericDashboard";
import GateUserDashboard from "../dashboard/GateUserDashboard";
import VisitorsLogs from "../components/Company/VistorsLogs";
import GeneratedPassList from "../components/Pass/generatedPassList";


// Pages

import Profile from "../pages/Profile";

// UI Elements

import ChartJs from "../docs/ChartJs";


// Newly Created Pages Imports 
import CreateBuilding from "../components/phase-admin/createBuilding";
import CreateGate from "../components/phase-admin/createGate";
import CreatePark from "../dashboard/createpark";

import AddPark from "../components/super-admin/addPark";
import CreateCompany from "../components/phase-admin/createCompany";
import CreatePhase from "../components/park-admin/createPhase";
import CreateParkAdmin from "../components/super-admin/createParkAdmin";
import CreatePhaseAdmin from "../components/park-admin/createPhaseAdmin";
import ParkList from "../components/super-admin/ParkList";
import PhaseList from "../components/park-admin/PhaseList";
import UserList from "../components/Company/UserList";
import BuildingList from "../components/phase-admin/BuildingList";
import GateList from "../components/phase-admin/GateList";
import CreateCompanyUser from "../components/Company/CreateCompanyUser";
import ParkDetailsScreen from "../components/super-admin/ParkDetailsScreen";
import CreateNewPass from "../components/Pass/createNewPass";
import PhaseDetailsScreen from "../components/phase-admin/PhaseDetailsScreen";
import BuildingDetailsScreen from "../components/phase-admin/BuildingDetailsScreen";



const protectedRoutes = [
	
	{ path: "dashboard/storage", element: StorageManagement }, // For the Guard to work the element has to be like this
	
	{ path: "pages/profile", element: <Profile /> },


	{ path: "dashboard/createpark", element: CreatePark, requiredRoles: ['Super Admin']  },

	// Phase Admin
	{ path: "dashboard/create-building", element: CreateBuilding, requiredRoles: ['Super Admin','Park Admin','Phase Admin']  },
	{ path: "dashboard/create-company", element: CreateCompany, requiredRoles: ['Super Admin', 'Park Admin', 'Phase Admin']  },
	{ path: "dashboard/generic", element: GenericDashboard, requiredRoles: ['Super Admin', 'Park Admin', 'Phase Admin','Company Admin','Company User'] },
	{ path: "dashboard/building-list", element: BuildingList, requiredRoles: ['Super Admin', 'Park Admin', 'Phase Admin']  },

	{ path: "dashboard/create-gate", element: CreateGate , requiredRoles: ['Super Admin', 'Park Admin', 'Phase Admin'] },
	//Super Admin
	{ path: "dashboard/create-park", element: AddPark, requiredRoles: ['Super Admin'] },
	{ path: "dashboard/create-park-admin", element: CreateParkAdmin, requiredRoles: ['Super Admin']  },

	// Park Admin
	{ path: "dashboard/create-phase", element: CreatePhase , requiredRoles: ['Super Admin','Park Admin'] },
	{ path: "dashboard/create-phase-admin", element: CreatePhaseAdmin, requiredRoles: ['Super Admin','Park Admin'] },
	{ path: "dashboard/park-list", element: ParkList , requiredRoles: ['Super Admin']},

	{ path: "dashboard/phase-list", element: PhaseList, requiredRoles: ['Park Admin','Super Admin'] },
	{ path: "dashboard/gate-list", element: GateList, requiredRoles: ['Super Admin', 'Park Admin', 'Phase Admin'] },

  	//Company
  	{ path:"dashboard/create-company-user",element: CreateCompanyUser, requiredRoles: ['Company Admin'] },
  	{ path:"dashboard/park-details", element: ParkDetailsScreen, requiredRoles: ['Super Admin'] },

  	{ path:"dashboard/phase-details", element: PhaseDetailsScreen, requiredRoles: ['Super Admin', 'Park Admin'] },
  	{ path:"dashboard/building-details", element: BuildingDetailsScreen, requiredRoles: ['Super Admin', 'Park Admin', 'Phase Admin'] },

// 	//Company
	{ path: "dashboard/visitor-logs", element: VisitorsLogs , requiredRoles: ['Super Admin', 'Park Admin', 'Phase Admin','Company Admin','Company User'] },
	{ path: "dashboard/visitor-logs/:key", element: VisitorsLogs , requiredRoles: ['Super Admin','Park Admin','Phase Admin'] },


	{ path: "dashboard/create-new-pass", element: CreateNewPass , requiredRoles: ['Company Admin','Company User'] },

	//Gate
	{ path:"dashboard/gate-user",element: GateUserDashboard, requiredRoles: ['Gate User'] },

	{ path: "dashboard/user-list", element: UserList, requiredRoles: ['Company Admin','Company User'] },

	//pass
	{ path: "dashboard/pass-list", element: GeneratedPassList , requiredRoles: ['Super Admin', 'Park Admin', 'Phase Admin','Company Admin','Company User'] },


]

export default protectedRoutes;