import { axiosInstance } from "./axiosInstance";

// API endpoint for get IT park list
export const getITParkList = () => {
    return axiosInstance.get(`/api/park/park`);
};

// API endpoint for creation of park admin
export const createParkAdmin = (data) => {
    return axiosInstance.post('/api/user/parkadmin', data);
};

// API endpoint for createpark
export const createPark = (data) => {
    return axiosInstance.post('/api/park/park', data);
};
 
export const getParkListDropdown = () => {
    return axiosInstance.get('/api/park/park-dropdown/');
};

export const getParkList = () => {
    return axiosInstance.get(`/api/park/park?active=1&page=1&page_size=6`);
};

export const getParkDetails = (park_id) => {
    return axiosInstance.get('/api/park/park-detail/' + park_id);
};

export const getParkListApi = (currentPage, pageSize, active) => {
    return axiosInstance.get(`/api/park/park?active=${active}&page=${currentPage}&page_size=${pageSize}`);
};

export const getParkAdminList = (data, active) => {
    return axiosInstance.get(`/api/user/parkadmin?active=${active}&ParkId=` + data);
};

export const getParkAdminDetails = (id) => {
    return axiosInstance.get('/api/user/parkadmin-details/' + id);
};

export const updateParkAdmin = (id, data) => {
    return axiosInstance.put('/api/user/parkadmin-details/' + id , data);
};

export const deleteParkAdmin = (id) => {
    return axiosInstance.delete('/api/user/parkadmin-details/' + id);
};

// API endpoint for updatepark
export const updatePark = (data, id) => {
    return axiosInstance.put('/api/park/park-detail/' + id, data);
};

// API endpoint for deletepark
export const deletePark = (id) => {
    return axiosInstance.delete('/api/park/park-detail/' + id);
};