// RandomColorGenerator.js
import React from "react";

const RandomColorGenerator = ({ name, location }) => {

    
  // Define your own set of colors
  const customColorOptions = [
    "#6bb9f0",
    "#6ed98a",
    "#bf9cd8",
    "#fbe087",
    "#ff99a9",
  ];

  // Generate a unique color based on the park's name and location
  const generateColor = (name, location) => {
    const combinedString = name.toLowerCase() + location.toLowerCase();
    let hash = 0;
    for (let i = 0; i < combinedString.length; i++) {
      hash = combinedString.charCodeAt(i) + ((hash << 5) - hash);
    }
    const colorIndex = Math.abs(hash % customColorOptions.length);
    return customColorOptions[colorIndex];
  };

  // Get a unique color for the park
  const randomColor = generateColor(name, location);
  return randomColor
//   return <div className="circle" style={{ backgroundColor: randomColor, minWidth: '90px' }}>{firstLetter}{secondLetter}</div>;
};

export default RandomColorGenerator;
