import React, { useEffect, useState, useRef } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Form } from "react-bootstrap";
import { Button, Card, Col } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { createPhaseAdmin, getITPhaseList, getPhaseAdminDetails, updatePhaseAdmin } from "../../services/Phase";
import { getITParkList } from "../../services/Park";
import { showToast } from "../shared/Toaster";
import { useSelector } from "react-redux";

export default function CreatePhaseAdmin() {
  // State variables

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [alternateemail, setAlternateEmail] = useState("");
  const [phonenumber, setMobileNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [itparkList, setITParkList] = useState("");
  const [itphaseList, setITPhaseList] = useState("");

  //State  variable to manage role
  const [roleName, setRoleName] = useState("");

  //fetching park id on navigation
  const parkId = useSelector((state) => state.park.parkId);

  //fetching phase id on navigation
  const phaseId = useSelector((state) => state.phase.phaseId);

  const role_name = localStorage.getItem("role_name");

  const defaultITPark =
    role_name === "Super Admin" ? parkId : localStorage.getItem("userPark");
  const defaultPhase =
    role_name === "Super Admin" || role_name === "Park Admin"
      ? phaseId
      : localStorage.getItem("selectedPhase");

  const [itpark, setITPark] = useState(defaultITPark);
  const [phase, setPhase] = useState(defaultPhase);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const phaseAdminId = query.get("id");

  // State variable to manage password visibility
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/dashboard/phase-details/?gen"); // Navigate to the signin page
  };

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  // State variables for error messages
  const [emailError, setEmailError] = useState("");
  const [alternateemailError, setAlternateEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [contactnameError, setContactNameError] = useState("");
  const [mobilenumberError, setMobileNumberError] = useState("");
  const [usernameError, setUserNameError] = useState("");
  const [phaseError, setPhaseError] = useState("");
  const [itparkError, setITParkError] = useState("");

  const [loading, setLoading] = useState(false);

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    const role_name = localStorage.getItem("role_name");
    if (role_name == "Super Admin") {
      setRoleName(true);
    } else if (role_name == "Park Admin") {
      setRoleName(false);
    }
    const fetchITParkList = async () => {
      if (role_name === "Super Admin") {
        try {
          const response = await getITParkList(); // Assuming getITParkList is an async function that fetches data
          const parks = response.data.data.park_list.results;
          setITParkList(parks); // Store the data in state
        } catch (error) {
        }
      } else {
        const data = [
          {
            id: localStorage.getItem("userPark"),
            parkName: localStorage.getItem("userParkName"),
          },
        ];
        setITParkList(data);
      }
    };

    const fetchITPhaseList = async (park_id) => {
      try {
        const response = await getITPhaseList(park_id); // Assuming getITParkList is an async function that fetches data
        setITPhaseList(response.data.data.phase_list.results); // Store the data in state
      } catch (error) {
      }
    };
    fetchITParkList();
    fetchITPhaseList(itpark);
  }, [itpark]);

   //For getting the phase admin details in the case of edit phase admin
   useEffect(() => {
    const fetchPhaseAdminDetails = async () => {
      try {
        const response = await getPhaseAdminDetails(phaseAdminId);
        if (response) {
          setContactName(response.data.data.first_name);
          setEmail(response.data.data.email);
          setAlternateEmail(response.data.data.alternate_email);
          setMobileNumber(response.data.data.phonenumber);
        }
      } catch (error) {
      }
    };
    fetchPhaseAdminDetails();
  }, [phaseAdminId]);

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  //function for toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleUserNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setUserName(value);
      const error = validateUserName(value);
      setUserNameError(error);
    }
  };

  useEffect(() => {
    if(phaseAdminId) {
      document.title = "Edit Phase Admin";
    }else {
      document.title = "Create Phase Admin";
    }
    switchSkin(skin);
  }, [skin]);

  // Function to validate user name
  const validateUserName = (value) => {
    if (!value.trim()) {
      return "Username is required";
    }
    if (!/^[a-zA-Z0-9]*$/.test(value)) {
      return "Username must contain only alphanumeric characters";
    }
    if (/^\d/.test(value)) {
      return "Username should not start with a number";
    }
    if (value.length > 100) {
      return "Username must not exceed 100 characters";
    }

    return "";
  };

  // Event handler for password change
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    if (value.length <= 20) {
      setPassword(value);
      const error = validatePassword(value);
      setPasswordError(error);
      if (confirmPassword && value !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handleConfirmPassword = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    const error = validateConfirmPassword(value);
    setConfirmPasswordError(error);
  };

  // Function to validate password
  const validatePassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }
    if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@$&])/.test(value)) {
      return "Password must contain at least one uppercase letter, one lowercase letter, one numeric digit, and one special character (@, $, !, & etc.)";
    }

    if (value.length <= 8) {
      return "Password length must be greater than 8 characters";
    }
    if (value.length > 20) {
      return "Password must not exceed 20 characters";
    }

    return "";
  };
  const validateConfirmPassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    if (value !== password) {
      return "Passwords do not match";
    }

    return "";
  };

  // Event handler for contact name change
  const handleContactNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setContactName(value);
      const error = validateContactName(value);
      setContactNameError(error);
    }
  };

  // Function to validate contact name
  const validateContactName = (value) => {
    if (!value.trim()) {
      return "Contact name is required";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Contact name must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return "Contact name must not exceed 100 characters";
    }
    return "";
  };

  // Event handler for mobile number change
  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    if (value.length <= 14) {
      setMobileNumber(value);
      const error = validateMobileNumber(value);
      setMobileNumberError(error);
    }
  };

  // Function to validate mobile number
  const validateMobileNumber = (value) => {
    // Check if the value is empty
    if (!value.trim()) {
      return "Contact number is required";
    }
    const numericRegex = /^[0-9]+$/;
    const noConsecutiveSameDigitRegex = /(.)\1{9}/;

    if (!numericRegex.test(value)) {
      return "Contact number must contain only numeric characters";
    }
    if (noConsecutiveSameDigitRegex.test(value)) {
      return "Continuous sequence of 10 same numbers is not allowed";
    }

    if (value.length > 14) {
      return "Contact number must not exceed 14 characters";
    }
    if (value.length < 10) {
      return "Contact number must contain at least 10 numeric characters";
    }

    return "";
  };

  // Event handler for email change
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    const error = validateEmail(value);
    setEmailError(error);
  };

  // Function to validate email
  const validateEmail = (value) => {
    if (!value.trim()) {
      return "Email id is required";
    }
    // // Regular expression for email validation
    const emailRegex =
      /^(?!.*\.\.)(?!.*\.[0-9])[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;
    // Check if the value matches the email regex
    if (!emailRegex.test(value)) {
      return "Please enter a valid email id";
    }
    return "";
  };

  // Event handler for alternate email change
  const handleAlternateEmailChange = (event) => {
    const value = event.target.value;
    setAlternateEmail(value);
    const error = validateAlternateEmail(value);
    setAlternateEmailError(error);
  };

  // Function to validate alternate email
  const validateAlternateEmail = (value) => {
    // Check if the value is empty
    if (value == null || value.trim() === "") {
      return ""; // Return empty string if value is empty
    }
    // // Regular expression for email validation
    const emailRegex =
      /^(?!.*\.\.)(?!.*\.[0-9])[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;

    // Check if the value matches the email regex
    if (!emailRegex.test(value)) {
      return "Please enter a valid alternate email id";
    }
    return "";
  };

  // Event handler for phase change
  const handlePhaseChange = (event) => {
    const value = event.target.value;
    setPhase(value);
    const error = validatePhase(value);
    setPhaseError(error);
  };

  const validatePhase = (value) => {
    if (!value || value === "undefined") {
      return "Phase is required";
    }
    return "";
  };

  const validateITPark = (value) => {
    if (!value || value === "undefined") {
      return "IT Park is required";
    }
    return "";
  };

  const handleITParkChange = async (event) => {
    const value = event.target.value;
    setITPark(value);
    const error = validateITPark(value);
    setITParkError(error);

    if (!error) {
      try {
        const response = await getITPhaseList(value); // Assuming getITPhaseList fetches phase data for the selected park
        if (response.data.data.phase_list.results.length > 0) {
          setITPhaseList(response.data.data.phase_list.results);
        } else {
          setITPhaseList(undefined);
        }
        setPhase("");
      } catch (error) {
        setITPhaseList(undefined);
        setPhase("");
      }
    }
  };

  // const handleITParkChange = async (event) => {
 
  //submit handler
  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!phaseAdminId) {
      // Validate all fields
    const userNameError = validateUserName(username);
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(confirmPassword);
    const emailError = validateEmail(email);
    const mobilenumberError = validateMobileNumber(phonenumber);
    const contactnameError = validateContactName(first_name);
    const alternateemailError = validateAlternateEmail(alternateemail);
    const phaseError = validatePhase(phase);
    const itparkError = validateITPark(itpark);

    // Set errors for each field
    setUserNameError(userNameError);
    setPasswordError(passwordError);
    setConfirmPasswordError(confirmPasswordError);
    setContactNameError(contactnameError);
    setMobileNumberError(mobilenumberError);
    setEmailError(emailError);
    setAlternateEmailError(alternateemailError);
    setPhaseError(phaseError);
    setITParkError(itparkError);

    if (
      userNameError ||
      passwordError ||
      confirmPasswordError ||
      contactnameError ||
      mobilenumberError ||
      emailError ||
      alternateemailError ||
      phaseError ||
      itparkError
    ) {
      return;
    } else {
      const formData = {
        username,
        password,
        first_name,
        email,
        phonenumber,
        phase,
        itpark,
      };

      // Conditionally add alternate email if it's not empty
      if (alternateemail.trim()) {
        formData.alternate_email = alternateemail;
      }


      try {
        setLoading(true);
        const response = await createPhaseAdmin(formData);

        //Set the form values to null once created
        setUserName("");
        setPassword("");
        setConfirmPassword("");
        setContactName("");
        setEmail("");
        setAlternateEmail("");
        setMobileNumber("");
        if (roleName) {
          setPhase("");
          setITPark("");
        }

        setLoading(false);
        showToast("success", response.data.message);
        navigate("/dashboard/phase-details/?gen");
      } catch (error) {
        setLoading(false);
        if (error.response) {
          showToast("error", error.response.data.message);
        } else {
          showToast("error", "Internal server Error");
        }
      }
    }
    }else {
      // Validate all fields
      const emailError = validateEmail(email);
      const mobilenumberError = validateMobileNumber(phonenumber);
      const contactnameError = validateContactName(first_name);
      const alternateemailError = validateAlternateEmail(alternateemail);
      const phaseError = validatePhase(phase);
      const itparkError = validateITPark(itpark);

      // Set errors for each field
      setContactNameError(contactnameError);
      setMobileNumberError(mobilenumberError);
      setEmailError(emailError);
      setAlternateEmailError(alternateemailError);
      setPhaseError(phaseError);
      setITParkError(itparkError);

    if (
      contactnameError ||
      mobilenumberError ||
      emailError ||
      alternateemailError ||
      phaseError ||
      itparkError
    ) {
      return;
    } else {

      const formData = {
        contactName : first_name,
        mobileNumber : phonenumber,
        email: email,
        phase: phase 
      }

      // Conditionally add alternate email if it's not empty
      if ( alternateemail && alternateemail.trim()) {
        formData.alternate_email = alternateemail;
      }

      try {
        setLoading(true);
        const response = await updatePhaseAdmin(formData, phaseAdminId);

        //Set the form values to null once created
        setContactName("");
        setEmail("");
        setAlternateEmail("");
        setMobileNumber("");
        if (roleName) {
          setPhase("");
          setITPark("");
        }

        setLoading(false);
        showToast("success", response.data.message);
        navigate("/dashboard/phase-details/?gen");
      } catch (error) {
        setLoading(false);
        if (error.response) {
          showToast("error", error.response.data.message);
        } else {
          showToast("error", "Internal server Error");
        }
      }
    }
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className=" main main-app d-flex flex-column align-items-start p-3 p-lg-4">
        <div className="my-3 ps-md-3 align-self-start">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line "
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/phase-details/?gen">
                    <span>Go back</span>
                  </Link>
                </li>
              </ol>
              {phaseAdminId ? (
                <h4 className="main-title mb-0">Edit Phase Admin</h4>
              ):(
                <h4 className="main-title mb-0">Create Phase Admin</h4>
              )}
            </div>
          </div>
        </div>
        <Col xs="12" md="12">
          <hr className="mb-5" />
          {/* <Card className="p-4"> */}
          <Card.Body className="ps-md-3 " style={{ maxWidth: "960px" }}>
            <Form onSubmit={handleSubmit}>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-column flex-md-row gap-md-5 gap-4 ">
                  {!phaseAdminId && (
                    <div className="d-flex flex-column gap-2">
                    <Card.Title as="h6">
                      <b>Admin Credentials</b>
                    </Card.Title>
                    <div style={{ maxWidth: "320px" }} className="text-muted">
                        Create credentials for phase admin to login to their
                        platform. Kindly ensure the security of your chosen
                        username and password.
                    </div>
                  </div>
                  )}

                  <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                    {!phaseAdminId && (
                      <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          IT Park<span className="text-danger"></span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          as="select"
                          value={itpark}
                          onChange={handleITParkChange}
                          disabled={!roleName} // Disable the select if roleName is not set ( if role_name is Park Admin )
                        >
                          <option value="">Select IT Park</option>
                          {itparkList === undefined && (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}
                          {Array.isArray(itparkList) &&
                            itparkList.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.parkName}
                              </option>
                            ))}
                        </Form.Select>

                        {itparkError && (
                          <div className="text-danger">{itparkError}</div>
                        )}
                      </Form.Group>
                    </div>
                    )}
                    {!phaseAdminId && (
                      <div style={{ minWidth: "320px" }}>
                        <Form.Label>
                          Phase<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          as="select"
                          value={phase}
                          onChange={handlePhaseChange}
                        >
                          <option value="">Select Phase</option>
                          {itphaseList === undefined && (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}

                          {Array.isArray(itphaseList) &&
                            itphaseList.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.PhaseName}
                              </option>
                            ))}
                        </Form.Select>
                        {phaseError && (
                          <div className="text-danger">{phaseError}</div>
                        )}
                      </div>
                    )}
                    {!phaseAdminId && (
                      <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Username <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter username for phase admin login"
                        value={username}
                        onChange={handleUserNameChange}
                      />
                      {usernameError && (
                        <div className="text-danger">{usernameError}</div>
                      )}
                    </div>
                    )}
                    {!phaseAdminId && (
                      <div
                        style={{ minWidth: "320px" }}
                        className="d-flex flex-column"
                      >
                        <Form.Label>
                          Password <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter password for phase admin login"
                          value={password}
                          onChange={handlePasswordChange}
                        />
                        {showPassword ? (
                          <RiEyeOffFill
                            className="eye-icon-gate"
                            style={{ marginTop: "4px" }}
                            onClick={togglePasswordVisibility}
                          />
                        ) : (
                          <RiEyeFill
                            className="eye-icon-gate"
                            style={{ marginTop: "4px" }}
                            onClick={togglePasswordVisibility}
                          />
                        )}

                        {passwordError && (
                          <div
                            className="text-danger mb-3"
                            style={{ marginTop: "-18px", maxWidth: "480px" }}
                          >
                            {passwordError}
                          </div>
                        )}
                      </div>
                    )}
                    {!phaseAdminId && (
                      <div
                        style={{ minWidth: "320px", marginTop: "-18px" }}
                        className="d-flex flex-column "
                      >
                        <Form.Label>
                          Confirm Password <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Re-enter password for phase admin login"
                          value={confirmPassword}
                          onChange={handleConfirmPassword}
                        />
                        {showConfirmPassword ? (
                          <RiEyeOffFill
                            className="eye-icon-gate"
                            style={{ marginTop: "4px" }}
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        ) : (
                          <RiEyeFill
                            className="eye-icon-gate"
                            style={{ marginTop: "4px" }}
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        )}

                        {confirmPasswordError && (
                          <div
                            className="text-danger mb-3"
                            style={{ marginTop: "-18px", maxWidth: "480px" }}
                          >
                            {confirmPasswordError}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                  <div className="d-flex flex-column gap-2">
                    <Card.Title as="h6">
                      <b>Admin Contact Details</b>
                    </Card.Title>
                    <div style={{ maxWidth: "320px" }} className="text-muted">
                      Update phase admin contact details for future
                      communications.
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                    {phaseAdminId && (
                      <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Phase<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        as="select"
                        value={phase}
                        onChange={handlePhaseChange}
                      >
                        <option value="">Select Phase</option>
                        {itphaseList === undefined && (
                          <option value="" disabled>
                            No Data Found
                          </option>
                        )}

                        {Array.isArray(itphaseList) &&
                          itphaseList.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.PhaseName}
                            </option>
                          ))}
                      </Form.Select>
                      {phaseError && (
                        <div className="text-danger">{phaseError}</div>
                      )}
                    </div>
                    )}
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Contact Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter contact name for phase admin"
                        value={first_name}
                        onChange={handleContactNameChange}
                      />
                      {contactnameError && (
                        <div className="text-danger">{contactnameError}</div>
                      )}
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Email ID <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter email id for phase admin contact"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {emailError && (
                        <div className="text-danger">{emailError}</div>
                      )}
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>Alternate Email ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter alternate email id for phase admin contact"
                        value={alternateemail}
                        onChange={handleAlternateEmailChange}
                      />
                      {alternateemailError && (
                        <div className="text-danger">{alternateemailError}</div>
                      )}
                    </div>

                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Contact Number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter contact number for phase admin"
                        value={phonenumber}
                        onChange={handleMobileNumberChange}
                      />
                      {mobilenumberError && (
                        <div className="text-danger">{mobilenumberError}</div>
                      )}
                    </div>
                    {phaseAdminId ? (
                      <div style={{ minWidth: "320px" }}>
                      <Button
                        type="submit"
                        variant="primary"
                        className="btn-sign mt-4"
                        disabled={loading}
                        style={{ width: "160px", textAlign: "center" }}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                          </>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </div>
                    ):(
                      <div style={{ minWidth: "320px" }}>
                      <Button
                        type="submit"
                        variant="primary"
                        className="btn-sign mt-4"
                        disabled={loading}
                        style={{ width: "160px", textAlign: "center" }}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                          </>
                        ) : (
                          "Create Phase Admin"
                        )}
                      </Button>
                    </div>
                    )}
                    
                  </div>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Col>
      </div>
      <Footer />
    </React.Fragment>
  );
}
