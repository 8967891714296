import React, { useEffect, useState, useCallback } from "react";
import Header from "../../layouts/Header";
import Table from "react-bootstrap/Table";
import { Card, Col, Row, Pagination, Form } from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import RandomColorGenerator from "../shared/RandomColorGenerator";
import VisitorDetailsModal from "./VisitorDetailsModal";

import { useLocation } from "react-router-dom";

import {
  searchByName,
  searchWithCompany,
} from "../../services/VisitorLogsSearch";
import {
  getCompanyDetails,
  getCompanyUserDropDown,
} from "../../services/Company";
import { getGateDropdown } from "../../services/Phase";

import { useSelector } from "react-redux";
import { debounce } from "lodash";

import { format } from "date-fns";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";

export default function VisitorsLogs() {
  const [visitorLogs, setVisitorLogs] = useState(""); // Set initial visitor logs

  const role_name = localStorage.getItem("role_name");
  const [show, setShow] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [loading, setLoading] = useState(false);

  // Get today's date
  const today = new Date();

  // Get the day, month, and year
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const year = today.getFullYear();

  // Format the date as DD/MM/YYYY
  const formattedDate = `${day}/${month}/${year}`;
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Page size of 6
  const [totalItems, setTotalItems] = useState(0);
  const [displayedPages, setDisplayedPages] = useState([]);

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [focusedInput, setFocusedInput] = useState(null);

  const [searchTerms, setSearchTerms] = useState({
    name: "",
    phone: "",
    email: "",
    createdBy: "",
    gate: "",
    startDate: format(new Date(), "dd/MM/yyyy"),
    endDate: format(new Date(), "dd/MM/yyyy"),
  });

  const [isActive, setIsActive] = useState(false);
  const [isActiveContact, setIsActiveContact] = useState(false);
  const [isActiveEmail, setIsActiveEmail] = useState(false);
  const [isActiveVisiting, setIsActiveVisiting] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const CompanyId = useSelector((state) => state.company.CompanyId);
  const role = localStorage.getItem("role_name");
  const companyUser = localStorage.getItem("userCompany");

  const phase_id = localStorage.getItem("userPhase");

  const [userDropDown, setUserDropDown] = useState("");
  const [gateDropDown, setGateDropDown] = useState("");
  const PhaseId = useSelector((state) => state.phase.phaseId);
  const UserId = useSelector((state) => state.user.userId);

  const [companyDetails, setCompanyDetails] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const fetchUserListDropdown = async () => {
      try {
        setLoading(true);
        let response;
        if (role_name === "Company Admin" || role_name === "Company User") {
          response = await getCompanyUserDropDown(companyUser); // Assuming getITParkList is an async function that fetches data
          setUserDropDown(response.data.data); // Store the data in state
        } else {
          response = await getCompanyUserDropDown(CompanyId); // Assuming getITParkList is an async function that fetches data
          setUserDropDown(response.data.data); // Store the data in state
        }
      } catch (error) {
        setLoading(false);
      }
    };
    fetchUserListDropdown();
  }, []);

  useEffect(() => {
    const fetchGateListDropdown = async () => {
      try {
        setLoading(true);
        let response;
        if (role === "Company Admin" || role === "Company User") {
          response = await getGateDropdown(phase_id);
        } else {
          response = await getGateDropdown(PhaseId);
        }
        // Assuming getITParkList is an async function that fetches data
        setGateDropDown(response.data.data); // Store the data in state
      } catch (error) {
        setLoading(false);
      }
    };
    fetchGateListDropdown();
  }, []);

  useEffect(() => {
    const getCompanyDetail = async () => {
      try {
        setLoading(true);

        const response = await getCompanyDetails(CompanyId);
        // Split the address string by '+'
        const parts = response.data.data.address.split("+");
        setCompanyDetails({
          name: parts[0],
          address: response.data.data.address,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getCompanyDetail();
  }, [CompanyId]);

  useEffect(() => {
    if (UserId) {
      setSearchTerms({
        ...searchTerms,
        createdBy: UserId,
      });

      debouncedSearch({
        ...searchTerms,
        createdBy: UserId,
      });
    }
  }, [UserId]);

  const handleFocusContact = () => {
    setIsActiveContact(true);
  };

  const handleBlurContact = () => {
    setIsActiveContact(false);
  };

  const handleFocusEmail = () => {
    setIsActiveEmail(true);
  };

  const handleBlurEmail = () => {
    setIsActiveEmail(false);
  };

  useEffect(() => {
    document.title = "Visitor Logs";
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    updateDisplayedPages();
  }, [currentPage, totalItems]);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const gen = searchParams.get("gen");
  const list = searchParams.get("list");

  const handleGoBack = () => {
    if (role_name === "Company Admin" || role_name === "Company User") {
      if (gen !== null) {
        navigate("/dashboard/generic");
      } else if (list !== null) {
        navigate("/dashboard/user-list");
      }
    } else if (
      role_name === "Super Admin" ||
      role_name === "Phase Admin" ||
      role_name === "Park Admin"
    ) {
      navigate("/dashboard/building-details/?gen");
    }
  };

  const goToCreatePass = (company_name) => {
    if (role_name === "Company Admin" || role_name === "Company User") {
      navigate("/dashboard/create-new-pass");
    }
  };

  const showVisitorLog = () => {};

  const EntryPointIcon = ({ entryPoint }) => {
    const initials = entryPoint
      .split(" ")
      .slice(0, 2)
      .map((word) => word.slice(0, 1))
      .join("")
      .toUpperCase();
    const color = RandomColorGenerator({ name: entryPoint, location: "" });

    return (
      <div className="d-flex align-items-center">
        <div
          className="entry-point-icon me-2"
          style={{ backgroundColor: color }}
        >
          {initials}
        </div>
        <span>{entryPoint}</span>
      </div>
    );
  };

  const entryPointIconStyle = `
    .entry-point-icon {
      width: 32px;
      aspect-ratio: 1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 400;
      font-size: 14px;
      text-transform: uppercase;
    }
  `;

  const handleSearchChange = (e) => {
    const { name, value } = e.target;

    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [name]: value,
    }));

    // Reset the current page to 1
    // setCurrentPage(1);

    // Trigger the search immediately with the updated state
    debouncedSearch({ ...searchTerms, [name]: value });
  };

  // Get today's date
  const getToday = () => new Date();
  const handleDateChange = (range) => {
    if (range && range[0] && range[1]) {
      // If range is valid
      const formattedStartDate = format(range[0], "dd/MM/yyyy");
      const formattedEndDate = format(range[1], "dd/MM/yyyy");

      setSearchTerms((prevTerms) => ({
        ...prevTerms,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }));

      setDateRange({
        startDate: range[0],
        endDate: range[1],
      });

      debouncedSearch({
        ...searchTerms,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
    } else {
      // If range is invalid (e.g., cleared), set to today's date
      const today = getToday();
      const formattedToday = format(today, "dd/MM/yyyy");

      setSearchTerms((prevTerms) => ({
        ...prevTerms,
        startDate: formattedDate,
        endDate: formattedDate,
      }));

      setDateRange({
        startDate: today,
        endDate: today,
      });

      debouncedSearch({
        ...searchTerms,
        startDate: formattedToday,
        endDate: formattedToday,
      });
    }
  };

  const debouncedSearch = useCallback(
    debounce((updatedSearchTerms) => {
      getVisitorLogs(updatedSearchTerms);
    }, 300),
    []
  );

  // Trigger debounced search whenever searchTerms or currentPage changes
  useEffect(() => {
    debouncedSearch({ ...searchTerms });
  }, [searchTerms, debouncedSearch]);

  const getVisitorLogs = async (searchParams) => {
    try {
      setLoading(true);
      const searchUrl = constructSearchUrl(searchParams);
      console.log(searchUrl, "search url");
      let response;
      if (
        role === "Gate User" ||
        role === "Company Admin" ||
        role === "Company User"
      ) {
        response = await searchByName(currentPage, pageSize, searchUrl);
      } else {
        response = await searchWithCompany(
          CompanyId,
          currentPage,
          pageSize,
          searchUrl
        );
      }
      setVisitorLogs(response.data.data.results);
      setTotalItems(response.data.data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const constructSearchUrl = (searchParams) => {
   
    const queryParams = [];

    if (searchParams.name) {
      queryParams.push(`name=${searchParams.name}`);
    }
    if (searchParams.email) {
      queryParams.push(`email=${searchParams.email}`);
    }
    if (searchParams.phone) {
      queryParams.push(`phone=${searchParams.phone}`);
    }
    if (searchParams.startDate) {
      queryParams.push(`startDate=${searchParams.startDate}`);
    }
    if (searchParams.endDate) {
      queryParams.push(`endDate=${searchParams.endDate}`);
    }
    if (searchParams.gate) {
      queryParams.push(`gate=${searchParams.gate}`);
    }
    if (searchParams.createdBy) {
      queryParams.push(`createdBy=${searchParams.createdBy}`);
    }

    return queryParams.join("&");
  };

  const clearSearchTerm = (name) => {
    const updatedSearchTerms = {
      ...searchTerms,
      [name]: "",
    };
    setSearchTerms(updatedSearchTerms);
    debouncedSearch(updatedSearchTerms); // Trigger search after clearing the term
  };

  useEffect(() => {
    getVisitorLogs(searchTerms); // Initial load
  }, [currentPage]);

  // Trigger debounced search whenever searchTerms or currentPage changes
  useEffect(() => {
    debouncedSearch({ ...searchTerms });
  }, [searchTerms, debouncedSearch]);

  const customStyleWrap = {
    whiteSpace: "normal",
    width: "50px",
    wordBreak: "break-word",
  };

  const handleShow = (visitor) => {
    setSelectedVisitor(visitor);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedVisitor(null);
  };

  const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #ced4da",
    padding: "7px 13px",
    fontSize: "0.875rem",
    outline: "none",
    paddingRight: "2rem", // Ensure there is enough space for the clear button
    boxSizing: "border-box",
  };

  const crossIconStyle = {
    position: "absolute",
    right: "0.5rem", // Position the button to the right side of the input
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    zIndex: "1",
    color: "#adb5bd", // Adjust the color to match the select box close icon
    fontSize: "23px", // Adjust the font size to match the select box close icon
  };

  const selectStyle = {
    borderRadius: "4px",
    border: "1px solid #ced4da",
    padding: "7px 13px",
    fontSize: "0.875rem",
    outline: "none",
    paddingRight: "2rem", // Ensure there is enough space for the clear button
    boxSizing: "border-box",
    appearance: "none", // Hide the default dropdown arrow
    background: `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNzA2NTYgNkw4IDExLjU4NjRMMTMuMjkzNCA2IiBzdHJva2U9IiM5QjlCOUIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=') no-repeat right 0.75rem center`, // Custom arrow icon
    backgroundSize: "10px",
  };

  const crossIconSelectStyle = {
    position: "absolute",
    right: "2rem", // Position the clear button to the left of the custom dropdown arrow
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    zIndex: "1",
    color: "#adb5bd",
    fontSize: "23px",
  };

  // Function to handle page change
  const onPageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updateDisplayedPages = () => {
    const totalPages = Math.ceil(totalItems / pageSize);
    const maxDisplayedPages = 3;
    const halfRange = Math.floor(maxDisplayedPages / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

    if (endPage - startPage < maxDisplayedPages - 1) {
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    setDisplayedPages(pages);
  };

  const totalPages = Math.ceil(totalItems / pageSize);

  return (
    <React.Fragment>
      <Header />
      <style>{entryPointIconStyle}</style>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex flex-md-row align-items-center justify-content-between mb-4">
          <div className="align-self-start w-100">
            <div className="d-flex gap-2">
              <i
                className="ri-arrow-left-line"
                onClick={handleGoBack}
                style={{
                  marginRight: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              ></i>
              <div className="py-1 w-100">
                <div className="d-flex flex-column flex-md-row justify-content-between mb-1">
                  <div className="mb-3 mb-md-0">
                    <ol className="breadcrumb fs-sm mb-1">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard/generic">
                          <span>Go back</span>
                        </Link>
                      </li>
                    </ol>
                    <h4 className="main-title mb-0">Visitor Logs</h4>
                  </div>
                  <div className="d-flex flex-column flex-md-row align-items-start gap-3">
                    <div className="earning-item d-flex gap-2">
                      <div className="earning-icon bg-success">
                        <i className="ri-team-line"></i>
                      </div>
                      <div className="d-flex flex-column">
                        <h4>{totalItems || 0}</h4>
                        <label>Total Visited</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0 flex-wrap"></div>
        </div>
        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one" style={{ minHeight: "650px" }}>
              <Card.Body>
                <Table
                  responsive
                  hover
                  style={{ overflow: "hidden", position: "relative" }}
                >
                  <thead>
                    <tr>
                      <th className="fixed-width-last">Visitor Name</th>
                      <th className="fixed-width-last">Contact Number</th>
                      <th className="fixed-width-last">Email</th>
                      <th className="fixed-width">Created By</th>
                      <th className="daterangepicker-width">
                        Visiting Date & Time
                      </th>
                      <th className="fixed-width">Entered Through</th>
                    </tr>
                    <tr>
                      <th>
                        <div className="table-input-container">
                          <input
                            type="text"
                            name="name"
                            value={searchTerms.name}
                            onChange={handleSearchChange}
                            style={{
                              ...inputStyle,
                              border: isActive
                                ? "0.15rem solid #2684ff"
                                : "1px solid #ced4da",
                            }}
                          />
                          {searchTerms.name && (
                            <button
                              type="button"
                              className="clear-button"
                              onClick={() => clearSearchTerm("name")}
                              style={crossIconStyle}
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="table-input-container">
                          <input
                            type="text"
                            name="phone"
                            value={searchTerms.phone}
                            onChange={handleSearchChange}
                            onFocus={handleFocusContact}
                            onBlur={handleBlurContact}
                            style={{
                              ...inputStyle,
                              border: isActiveContact
                                ? "0.15rem solid #2684ff"
                                : "1px solid #ced4da",
                            }}
                          />
                          {searchTerms.phone && ( // Render clear button only if there's a search term
                            <button
                              type="button"
                              className="clear-button"
                              onClick={() => clearSearchTerm("phone")}
                              style={crossIconStyle}
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="table-input-container">
                          <input
                            type="text"
                            name="email"
                            value={searchTerms.email}
                            onChange={handleSearchChange}
                            onFocus={handleFocusEmail}
                            onBlur={handleBlurEmail}
                            style={{
                              ...inputStyle,
                              border: isActiveEmail
                                ? "0.15rem solid #2684ff"
                                : "1px solid #ced4da",
                            }}
                          />
                          {searchTerms.email && ( // Render clear button only if there's a search term
                            <button
                              className="clear-button"
                              onClick={() => clearSearchTerm("email")}
                              style={crossIconStyle}
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </th>
                      <th className="createSelect">
                        <div className="table-input-container">
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              as="select"
                              name="createdBy"
                              value={searchTerms.createdBy}
                              onChange={handleSearchChange}
                              style={selectStyle}
                            >
                              <option value="">Select Users</option>
                              {userDropDown === undefined && (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                              {Array.isArray(userDropDown) &&
                                userDropDown.map((item, index) => (
                                  <option key={index} value={item?.user__id}>
                                    {item?.user__first_name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                          {searchTerms.createdBy && ( // Render clear button only if there's a search term
                            <button
                              className="clear-button"
                              onClick={() => clearSearchTerm("createdBy")}
                              style={crossIconSelectStyle}
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </th>
                      <th>
                        <div style={{ position: "relative", maxWidth: "100%" }}>
                          <DateRangePicker
                            className="w-150"
                            style={{ minWidth: "235px", position: "relative" }}
                            ranges={[dateRange]}
                            value={[dateRange.startDate, dateRange.endDate]}
                            onChange={handleDateChange}
                            format="dd/MM/yyyy"
                            placeholder="Select Date Range"
                            showOneCalendar
                          />
                        </div>
                      </th>
                      <th className="createSelect">
                        <div className="table-input-container">
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              as="select"
                              value={searchTerms.gate}
                              name="gate"
                              style={selectStyle}
                              onChange={handleSearchChange}
                            >
                              <option value="">Select Gate</option>
                              {gateDropDown === undefined && (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                              {Array.isArray(gateDropDown) &&
                                gateDropDown.map((item, index) => (
                                  <option key={index} value={item?.gateId}>
                                    {item?.gateName}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                          {searchTerms.gate && ( // Render clear button only if there's a search term
                            <button
                              className="clear-button"
                              onClick={() => clearSearchTerm("gate")}
                              style={crossIconSelectStyle}
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100px" }}
                          >
                            <span
                              className="spinner-border spinner-border-lg text-primary"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    )}
                    {Array.isArray(visitorLogs) &&
                    visitorLogs.length > 0 &&
                    !loading
                      ? visitorLogs.map((item, index) => (
                          <tr key={index}>
                            <td
                              onClick={() => handleShow(item)}
                              className="text-primary fixed-width-last"
                              style={{
                                ...customStyleWrap,
                                cursor: "pointer",
                              }}
                            >
                              {item.name}
                            </td>
                            <td
                              className="fixed-width-last"
                              style={customStyleWrap}
                            >
                              {item.phonenumber}
                            </td>
                            {/* <td style={{ whiteSpace: 'normal', width:'150px'}}>{item.email}</td> */}
                            <td
                              className="fixed-width-last"
                              style={customStyleWrap}
                            >
                              {item.email}
                            </td>
                            <td className="fixed-width" style={customStyleWrap}>
                              {item.created_by}
                            </td>
                            <td className="fixed-width" style={customStyleWrap}>
                              {item.entry_datetime}
                            </td>
                            <td
                              className="fixed-width-last"
                              style={customStyleWrap}
                            >
                              <EntryPointIcon entryPoint={item.entry_gate} />
                            </td>
                          </tr>
                        ))
                      : !loading && (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
                {selectedVisitor && (
                  <VisitorDetailsModal
                    show={show}
                    handleClose={handleClose}
                    visitor={selectedVisitor}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <div>
        {!loading && visitorLogs?.length > 0 && (
          <Pagination className="pagination-space justify-content-start pagination-filled  p-3 p-lg-4">
            <Pagination.Prev
              onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {displayedPages.map((number) => (
              <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => onPageChange(number)}
              >
                {number}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                currentPage < totalPages && onPageChange(currentPage + 1)
              }
              disabled={currentPage === totalPages}
            />
          </Pagination>
        )}
      </div>
    </React.Fragment>
  );
}
