import { combineReducers } from 'redux';
import { phaseReducer } from './Reducer';
import { parkReducer } from './Reducer';
import { buildingReducer } from './Reducer';
import { companyReducer } from './Reducer';
import { userReducer } from './Reducer';

export const rootReducer = combineReducers({
  phase: phaseReducer,
  park:parkReducer,
  building:buildingReducer,
  company:companyReducer,
  user:userReducer
  // Add other reducers here if needed
});