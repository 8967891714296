import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Custom toast components
const SuccessToast = ({ message }) => <div>{message}</div>;
const ErrorToast = ({ message }) => <div>{message}</div>;
const WarningToast = ({ message }) => <div>{message}</div>;
const InfoToast = ({ message }) => <div>{message}</div>; // Info toast component

// Function to show toast messages
export const showToast = (type, message) => {
  switch (type) {
    case "success":
      toast.success(<SuccessToast message={message} />, {
        position: "top-right",
        hideProgressBar: true, // Hide the progress bar
        autoClose: 3500, // Set the time limit to 2000 milliseconds (2 seconds)
      });
      break;
    case "error":
      toast.error(<ErrorToast message={message} />, {
        position: "top-right",
        hideProgressBar: true,
        autoClose: 3500,
      });
      break;
    case "warning":
      toast.warn(<WarningToast message={message} />, {
        position: "top-right",
        hideProgressBar: true,
        autoClose: 3500,
      });
      break;
    case "info": // Handle info toast
      toast.info(<InfoToast message={message} />, {
        position: "top-right",
        hideProgressBar: true,
        autoClose: 3500,
      });
      break;
    default:
      toast(message);
  }
};
