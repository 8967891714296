import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { showToast } from "../components/shared/Toaster";

import { useAuth } from "../services/AuthContext";
import { useParams } from "react-router-dom";

export default function ResetPassword() {
  const { token } = useParams(); // Extract token from URL
  const { check_link_expired } = useAuth();
  const [linkExpired, setLinkExpired] = useState(false); // State to track if the link has expired

  useEffect(() => {
    document.title = "Reset Password";
    const checkTokenValidity = async () => {
      if (localStorage.getItem("reset_password") != "false") {
        try {
          // Make API call to check if the token is valid
          setLoading(true);
          await check_link_expired(token);
          // If the token is valid, show the form
          setLinkExpired(false);
        } catch (error) {
          // If there's an error, set linkExpired to true
          setLinkExpired(true);
          if (error.response) {
            // showToast("error", error.response.data.message);
          } else {
            // showToast("error", "Internal Server Error");
          }
        } finally {
          setLoading(false);
        }
      }
    };

    checkTokenValidity();
    //Function to check the token validity If the token is not valid link expired message will appear
  }, []);

  document.body.classList.remove("sidebar-show");
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State variable to manage password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const currentPasswordRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { password_reset_with_token } = useAuth();
  const { password_reset_without_token } = useAuth();

  const navigate = useNavigate();

  const validateCurrentPassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    return "";
  };

  const validatePassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@$&])/.test(value)) {
      return "Password must contain at least one uppercase letter, one lowercase letter, one numeric digit, and one special character (@, $, !, & etc.)";
    }

    if (value.length <= 8) {
      return "Password length must be greater than 8 characters";
    }

    if (value.length > 20) {
      return "Password must not exceed 20 characters";
    }

    return "";
  };

  const validateConfirmPassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    if (value !== password) {
      return "Passwords do not match";
    }

    return "";
  };

  const handleCurrentPassword = (event) => {
    setApiError("");
    const value = event.target.value;
    if (value.length <= 20) {
      setCurrentPassword(value);
      const error = validateCurrentPassword(value);
      setCurrentPasswordError(error);
    }
  };

  const handlePassword = (event) => {
    setApiError("");
    const value = event.target.value;
    if (value.length <= 20) {
      setPassword(value);
      const error = validatePassword(value);
      setPasswordError(error);
      if (confirmPassword && value !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handleConfirmPassword = (event) => {
    setApiError("");
    const value = event.target.value;
    setConfirmPassword(value);
    const error = validateConfirmPassword(value);
    setConfirmPasswordError(error);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    //Checking the first time reset or not
    const reset_password = localStorage.getItem("reset_password");
    const currentPasswordError = validateCurrentPassword(currentPassword);
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(confirmPassword);

    setCurrentPasswordError(currentPasswordError);
    setPasswordError(passwordError);
    setConfirmPasswordError(confirmPasswordError);

    if (passwordError || confirmPasswordError) {
      setLoading(false);
      return;
    } else {
      try {
        if (reset_password == "false") {
          let data = {
            current_password: currentPassword,
            new_password: password,
            confirm_password: confirmPassword,
          };
          const user = await password_reset_with_token(data);
          setLoading(false);

          showToast("success", user.data.message);
          navigate("/");

        } else {
          let data = {
            token: token,
            new_password: password,
            confirm_password: confirmPassword,
          };
          const user = await password_reset_without_token(data);
          setLoading(false);

          showToast("success", user.data.message);
          navigate("/");
        }
      } catch (error) {
        setLoading(false);
        
        if (error.response) {
          if (error.response.data.message) {
            setApiError(error.response.data.message);
          } else if (error.response.status == 500) {
            showToast("error", error.response.statusText);
          }
        } else {
          showToast("error", "Internal Server Error");
        }
      }
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }

    // Additional logic for handling the form submission
    // For example, you can submit the form or perform other actions here
  };
  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="page-sign">
      {/* Header section */}
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">
            Entry Pass
          </Link>
          <Card.Title>Create new password</Card.Title>
        </Card.Header>
        <Card.Body>
          {!loading && linkExpired && (
            <p>
              The password reset link has expired. Please request a new one.
            </p>
          )}
          {!linkExpired && (
            <Form onSubmit={handleSubmit}>
              {localStorage.getItem("reset_password") == "false" && (
                <div className="mb-2">
                  <Form.Label>
                    Current Password<span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    type={showCurrentPassword ? "text" : "password"}
                    value={currentPassword}
                    onChange={handleCurrentPassword}
                    placeholder="Enter current password"
                    ref={currentPasswordRef}
                  />
                  {/* Eye icon to toggle password visibility */}
                  {showCurrentPassword ? (
                    <RiEyeOffFill
                      className="eye-icon"
                      onClick={toggleCurrentPasswordVisibility}
                    />
                  ) : (
                    <RiEyeFill
                      className="eye-icon"
                      onClick={toggleCurrentPasswordVisibility}
                    />
                  )}
                  {currentPasswordError && (
                    <div className="text-danger password-error">
                      {currentPasswordError}
                    </div>
                  )}
                </div>
              )}
              <div className="mb-2">
                <Form.Label>
                  New Password<span className="text-danger">*</span>
                </Form.Label>

                <Form.Control
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePassword}
                  placeholder="Enter new password"
                  ref={passwordRef}
                />
                {/* Eye icon to toggle password visibility */}
                {showPassword ? (
                  <RiEyeOffFill
                    className="eye-icon"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <RiEyeFill
                    className="eye-icon"
                    onClick={togglePasswordVisibility}
                  />
                )}
                {passwordError && (
                  <div className="text-danger password-error">
                    {passwordError}
                  </div>
                )}
              </div>
              {/* Confirm password input field */}
              <div className="mb-2">
                <Form.Label>
                  Confirm New Password<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                  placeholder="Re-enter new password"
                  ref={confirmPasswordRef}
                />
                {/* Eye icon to toggle confirm password visibility */}
                {showConfirmPassword ? (
                  <RiEyeOffFill
                    className="eye-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                ) : (
                  <RiEyeFill
                    className="eye-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                )}
                {confirmPasswordError && (
                  <div className="text-danger password-error">
                    {confirmPasswordError}
                  </div>
                )}
              </div>

              {apiError && (
                <div className="text-danger api-error">
                  {apiError}
                </div>
              )}

              {/* Submit button */}
              {loading && (
                <Button className="btn btn-primary" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Reset</span>
                </Button>
              )}
              {!loading && (
                <Button type="submit" variant="primary" className="btn-sign">
                  Reset
                </Button>
              )}
              <div className="mt-3 text-center">
                <Link to="/">Back to Login</Link>
              </div>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
