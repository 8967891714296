// Create Phase.js
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Form, Button, Card, Col } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getITParkList } from "../../services/Park";
import { showToast } from "../shared/Toaster";
import { createPhase, getPhaseDetails, updatePhase } from "../../services/Phase";
import { useSelector } from "react-redux";

export default function CreatePhase() {
  // State variables
  const [roleName, setRoleName] = useState("");
  const [phasename, setPhaseName] = useState("");
  const [location, setLocation] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [loading, setLoading] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const phaseId = query.get("id");


  const parkId = useSelector((state) => state.park.parkId);
  console.log("park id", parkId);

  //For getting the phase details in the case of edit phase
  useEffect(() => {
    const fetchPhaseDetails = async () => {
      try {
        const response = await getPhaseDetails(phaseId);
        console.log(response.data, "phase details .....");
        if (response) {
          setPhaseName(response.data.data.PhaseName);
          setLocation(response.data.data.Location);
          const addressParts = response.data.data.address?.split("+") || [];

          // Assigning address parts to state variables
          setStreet1(addressParts[0] || "");
          setStreet2(addressParts[1] || "");
          setCity(addressParts[2] || "");
          setDistrict(addressParts[3] || "");
          setState(addressParts[4] || "");
          setPincode(addressParts[5] || "");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchPhaseDetails();
  }, [phaseId]);

  const role_name = localStorage.getItem("role_name");

const defaultITPark = role_name === "Super Admin" ? parkId : localStorage.getItem("userPark");


const [park, setPark] = useState(defaultITPark);

  

 

  //For IT Park List
  const [itparkList, setITParkList] = useState("");

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/dashboard/phase-list/?gen"); // Navigate to the dashboard page
  };

  // State variables for error messages
  const [phasenameError, setPhaseNameError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [parkError, setParkError] = useState("");

  const [street1Error, setStreet1Error] = useState("");
  const [street2Error, setStreet2Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [stateError, setStateError] = useState("");
  const [pincodeError, setPincodeError] = useState("");

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    if(phaseId) {
      document.title = "Edit Phase";
    }else{
      document.title = "Create Phase";
    }
    const role_name = localStorage.getItem("role_name");
    if (role_name == "Super Admin") {
      setRoleName(true);
    } else if (role_name == "Park Admin") {
      setRoleName(false);
    }
    const fetchITParkList = async () => {
      if (role_name == "Super Admin") {
        try {
          const response = await getITParkList();
          const parks = response.data.data.park_list.results;
          setITParkList(parks); // Store the data in state
         } catch (error) {
        }
      } else {
        const data = [
          {
            id: localStorage.getItem("userPark"),
            parkName: localStorage.getItem("userParkName"),
          },
        ];
        setITParkList(data);
      }
    };
    fetchITParkList();
    switchSkin(skin);
  }, [skin]);

  
  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  // Event handler for park name change
  const handlePhaseNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setPhaseName(value);
      const error = validatePhaseName(value);
      setPhaseNameError(error);
    }
  };

  // Function to validate park name
  const validatePhaseName = (value) => {
    if (!value.trim()) {
      return "Phase name is required";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Phase name must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return "Phase name must not exceed 100 characters";
    }
    return "";
  };

  // Event handler for location change
  const handleLocationChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setLocation(value);
      const error = validateLocation(value);
      setLocationError(error);
    }
  };

  // Function to validate location
  const validateLocation = (value) => {
    if (!value.trim()) {
      return "Location is required";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Location must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return "Location must not exceed  100 characters";
    }
    return "";
  };
  const validateStreet1 = (value) => {
    if (!value.trim()) {
      return "Street 1 is required";
    }
    if (value.length > 50) {
      return "Street 1 must not exceed 50 characters";
    }
    if (value.includes("+")) {
      return "Invalid character";
    }
    return "";
  };
  const handleStreet1Change = (event) => {
    const value = event.target.value;
    if (value.length <= 50) {
      setStreet1(value);
      const error = validateStreet1(value);
      setStreet1Error(error);
    }
  };
  const validateStreet2 = (value) => {
    if (!value.trim()) {
      return "Street 2 is required";
    }
    if (value.length > 50) {
      return "Street 2 must not exceed 50 characters";
    }
    if (value.includes("+")) {
      return "Invalid character";
    }
    return "";
  };
  const handleStreet2Change = (event) => {
    const value = event.target.value;
    if (value.length <= 50) {
      setStreet2(value);
      const error = validateStreet2(value);
      setStreet2Error(error);
    }
  };
  const validateCity = (value) => {
    if (value.startsWith(" ")) {
      return "City must not start with a space";
    }
    if (!value.trim()) {
      return "City is required";
    }
    if (value.length > 20) {
      return "City must not exceed 20 characters";
    }
    if (!/^[a-zA-Z\s]*$/.test(value)) {
      return "City must contain only letters";
    }
    return "";
  };

  const handleCityChange = (event) => {
    const value = event.target.value;
    if (value.length <= 20) {
      setCity(value);
      const error = validateCity(value);
      setCityError(error);
    }
  };
  const validateDistrict = (value) => {
    if (value.startsWith(" ")) {
      return "District must not start with a space";
    }
    if (!value.trim()) {
      return "District is required";
    }
    if (value.length > 20) {
      return "District must not exceed 20 characters";
    }
    if (!/^[a-zA-Z\s]*$/.test(value)) {
      return "District must contain only letters";
    }
    return "";
  };

  const handleDistrictChange = (event) => {
    const value = event.target.value;
    if (value.length <= 20) {
      setDistrict(value);
      const error = validateDistrict(value);
      setDistrictError(error);
    }
  };
  const validateState = (value) => {
    if (value.startsWith(" ")) {
      return "State must not start with a space";
    }
    if (!value.trim()) {
      return "State is required";
    }
    if (value.length > 20) {
      return "State must not exceed 20 characters";
    }
    if (!/^[a-zA-Z\s]*$/.test(value)) {
      return "State must contain only letters";
    }
    return "";
  };

  const handleStateChange = (event) => {
    const value = event.target.value;
    if (value.length <= 20) {
      setState(value);
      const error = validateState(value);
      setStateError(error);
    }
  };
  const validatePinCode = (value) => {
    const trimmedValue = value.trim();
    if (value.startsWith(" ")) {
      return "Pin code must not start with a space";
    }

    if (!trimmedValue) {
      return "Pin code is required";
    }
    if (!/^\d+$/.test(trimmedValue)) {
      return "Pin code must contain only numbers";
    }
    return "";
  };

  const handlePincodeChange = (event) => {
    const value = event.target.value;
    if (value.length <= 6) {
      setPincode(value);
      const error = validatePinCode(value);
      setPincodeError(error);
    }
  };

  const handleParkSelect = (event) => {
    const value = event.target.value;
    setPark(value);
    const error = validateSelectPark(value);
    setParkError(error);
  };
  const validateSelectPark = (value) => {
    console.log("insode validate it park");
    if (!value || value === "undefined") {
      return "IT Park is required";
    }
    return "";
  };

  //submit handler
  const handleSubmit = async (event) => {
    console.log(park, "park    lkkkk");
    event.preventDefault();
    setLoading(true);

    if(!phaseId) {
      // Validate all fields
    const locationError = validateLocation(location);

    const phasenameError = validatePhaseName(phasename);
    const parkError = validateSelectPark(park);
    const street1Error = validateStreet1(street1);
    const street2Error = validateStreet2(street2);
    const cityError = validateCity(city);
    const districtError = validateDistrict(district);
    const stateError = validateState(state);
    const pincodeError = validatePinCode(pincode);

    // Set errors for each field

    setLocationError(locationError);
    setPhaseNameError(phasenameError);
    setParkError(parkError);
    setStreet1Error(street1Error);
    setStreet2Error(street2Error);
    setCityError(cityError);
    setDistrictError(districtError);
    setStateError(stateError);
    setPincodeError(pincodeError);

    if (
      street1Error ||
      street2Error ||
      cityError ||
      districtError ||
      stateError ||
      locationError ||
      phasenameError ||
      parkError ||
      pincodeError
    ) {
      setLoading(false);
      return;
    } else {
      try {
        const address = `${street1}+${street2}+${city}+${district}+${state}+${pincode}`;
        const formData = {
          phaseName: phasename,
          address: address,
          Location: location,
          itPark: park,
        };
        // Proceed with form submission
        const response = await createPhase(formData);
        setLoading(false);
        setPhaseName("");
        setLocation("");
        setCity("");
        setDistrict("");
        setStreet1("");
        setStreet2("");
        setPincode("");
        setState("");

        if (roleName) {
          setITParkList("");
          setPark("");
        }

        showToast("success", response.data.message);
        navigate("/dashboard/phase-list/?gen");
      } catch (error) {
        // Handle submission error
        console.log(error);
        setLoading(false);
        if (error.response) {
          if (error.response.data.message) {
            showToast("error", error.response.data.message);
          } else if (error.response.status === 500) {
            showToast("error", error.response.statusText);
          } else if (error.response.data.address) {
            showToast("error", error.response.data.address);
          }
        } else {
          showToast("error", "Internal Server Error");
        }
      }
    }
    }else {
      // Validate all fields
    const locationError = validateLocation(location);

    const phasenameError = validatePhaseName(phasename);
    const parkError = validateSelectPark(park);
    const street1Error = validateStreet1(street1);
    const street2Error = validateStreet2(street2);
    const cityError = validateCity(city);
    const districtError = validateDistrict(district);
    const stateError = validateState(state);
    const pincodeError = validatePinCode(pincode);

    // Set errors for each field

    setLocationError(locationError);
    setPhaseNameError(phasenameError);
    setParkError(parkError);
    setStreet1Error(street1Error);
    setStreet2Error(street2Error);
    setCityError(cityError);
    setDistrictError(districtError);
    setStateError(stateError);
    setPincodeError(pincodeError);

    if (
      street1Error ||
      street2Error ||
      cityError ||
      districtError ||
      stateError ||
      locationError ||
      phasenameError ||
      parkError ||
      pincodeError
    ) {
      setLoading(false);
      return;
    } else {
      try {
        const address = `${street1}+${street2}+${city}+${district}+${state}+${pincode}`;
        const formData = {
          phaseName: phasename,
          address: address,
          Location: location,
          itPark: park,
        };
        // Proceed with form submission
        const response = await updatePhase(phaseId,formData);
        setLoading(false);
        setPhaseName("");
        setLocation("");
        setCity("");
        setDistrict("");
        setStreet1("");
        setStreet2("");
        setPincode("");
        setState("");

        if (roleName) {
          setITParkList("");
          setPark("");
        }

        showToast("success", response.data.message);
        navigate("/dashboard/phase-list/?gen");
      } catch (error) {
        // Handle submission error
        console.log(error);
        setLoading(false);
        if (error.response) {
          if (error.response.data.message) {
            showToast("error", error.response.data.message);
          } else if (error.response.status === 500) {
            showToast("error", error.response.statusText);
          } else if (error.response.data.address) {
            showToast("error", error.response.data.address);
          }
        } else {
          showToast("error", "Internal Server Error");
        }
      }
    }
    }

    
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className=" main main-app d-flex flex-column align-items-start p-3 p-lg-4">
        <div className="my-3 ps-md-3 align-self-start">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line "
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/phase-list/?gen">
                    <span>Go back</span>
                  </Link>
                </li>
              </ol>
              {phaseId ? (
                <h4 className="main-title mb-0">Edit Phase</h4>
              ):(
                <h4 className="main-title mb-0">Create Phase</h4>
              )}
            </div>
          </div>
        </div>
        <Col xs="12" md="12">
          <hr className="mb-5" />
          <Card.Body className="ps-md-3 " style={{ maxWidth: "960px" }}>
            <Form onSubmit={handleSubmit}>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                  <div className="d-flex flex-column gap-2">
                    <Card.Title as="h6">
                      <b>Phase Details</b>
                    </Card.Title>
                    <div style={{ maxWidth: "320px" }} className="text-muted">
                      Provide the necessary information to create a phase.
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter phase name"
                        value={phasename}
                        onChange={handlePhaseNameChange}
                      />
                      {phasenameError && (
                        <div className="text-danger">{phasenameError}</div>
                      )}
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        IT Park <span className="text-danger"></span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        as="select"
                        value={park}
                        onChange={handleParkSelect}
                        disabled={!roleName}
                      >
                        <option value="">Select Park</option>
                        {itparkList === undefined && (
                          <option value="" disabled>No Data Found</option>
                        )}
                        {Array.isArray(itparkList) &&
                          itparkList.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.parkName}
                            </option>
                          ))}
                      </Form.Select>

                      {parkError && (
                        <div className="text-danger">{parkError}</div>
                      )}
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Location <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter location of the phase"
                        value={location}
                        onChange={handleLocationChange}
                      />
                      {locationError && (
                        <div className="text-danger">{locationError}</div>
                      )}
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          Street 1 <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the primary street address of the phase "
                          value={street1}
                          onChange={handleStreet1Change}
                        />
                        {street1Error && (
                          <div className="text-danger">{street1Error}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          Street 2 <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the additional street address of the phase "
                          value={street2}
                          onChange={handleStreet2Change}
                        />
                        {street2Error && (
                          <div className="text-danger">{street2Error}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          City <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the city where the phase is situated "
                          value={city}
                          onChange={handleCityChange}
                        />
                        {cityError && (
                          <div className="text-danger">{cityError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          District <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the district where the phase is situated"
                          value={district}
                          onChange={handleDistrictChange}
                        />
                        {districtError && (
                          <div className="text-danger">{districtError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          State <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the state where the phase is situated"
                          value={state}
                          onChange={handleStateChange}
                        />
                        {stateError && (
                          <div className="text-danger">{stateError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          Pin Code <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the pin code of the phase's location"
                          value={pincode}
                          onChange={handlePincodeChange}
                        />
                        {pincodeError && (
                          <div className="text-danger">{pincodeError}</div>
                        )}
                      </Form.Group>
                    </div>

                    <div style={{ minWidth: "320px" }}>
                      {phaseId ? (
                        <Button
                        type="submit"
                        variant="primary"
                        className="btn-sign mt-4"
                        disabled={loading}
                        style={{ width: "150px", textAlign: "center" }}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                          </>
                        ) : (
                          "Save"
                        )}
                      </Button>
                      ):(
                        <Button
                        type="submit"
                        variant="primary"
                        className="btn-sign mt-4"
                        disabled={loading}
                        style={{ width: "150px", textAlign: "center" }}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                          </>
                        ) : (
                          "Create Phase"
                        )}
                      </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Col>
      </div>
      <Footer />
    </React.Fragment>
  );
}
