// BuildingList.js
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Button, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import ParkCard from "../super-admin/ParkCard";
import { useSelector } from "react-redux";
import { getBuildingList } from "../../services/Phase";
import { deleteBuilding } from "../../services/Company";

export default function BuildingList() {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const phaseIdFromRedux = useSelector((state) => state.phase.phaseId);
  const [buildingList, setBuildingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Page size of 6
  const [totalItems, setTotalItems] = useState(0);
  const [displayedPages, setDisplayedPages] = useState([]);
  const [active, setActive] = useState(1);

  useEffect(() => {
    const role = localStorage.getItem("role_name");

    if (role == "Super Admin") {
      setRole("Super Admin");
    } else if (role === "Park Admin") {
      setRole("Park Admin");
    } else if (role === "Phase Admin") {
      setRole("Phase Admin");
    }
  });

  const handleGoBack = () => {
    if (role == "Super Admin") {
      navigate("/dashboard/phase-details/?gen"); // Navigate to the dashboard page
    } else if (role === "Park Admin") {
      navigate("/dashboard/phase-details/?gen");
    } else {
      navigate("/dashboard/generic"); // Navigate to the dashboard page
    }
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setActive(newValue);
    setCurrentPage(1);
  };
  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    document.title = "Building List";

    fetchBuildingList();
    switchSkin(skin);
  }, [skin, currentPage, role, phaseIdFromRedux, pageSize, active]);

  const fetchBuildingList = async () => {
	setBuildingList([]);
	try {
	  setLoading(true);
	  let PhaseId = phaseIdFromRedux;
	  if (role === "Phase Admin") {
		PhaseId = localStorage.getItem("userPhase");
	  }
	  const response = await getBuildingList(PhaseId, currentPage, pageSize, active);
	  setBuildingList(response.data.data.Building_list.results);
	  setTotalItems(response.data.data.Building_list.count);
	  setLoading(false);
	} catch (error) {
	  setLoading(false);
	}
  };

  useEffect(() => {
    updateDisplayedPages();
  }, [currentPage, totalItems]);

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const handleDeleteSuccess = () => {
    // Update building list after successful deletion
		const phaseId = localStorage.getItem("userPhase");
		fetchBuildingList(phaseId);
  };

  // Function to handle page change
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updateDisplayedPages = () => {
    const totalPages = Math.ceil(totalItems / pageSize);
    const maxDisplayedPages = 3;
    const halfRange = Math.floor(maxDisplayedPages / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

    if (endPage - startPage < maxDisplayedPages - 1) {
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    setDisplayedPages(pages);
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line "
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  {role === "Super Admin" && (
                    <Link to="/dashboard/phase-details/?gen">
                      <span>Go back</span>
                    </Link>
                  )}
                  {role === "Park Admin" && (
                    <Link to="/dashboard/phase-details/?gen">
                      <span>Go back</span>
                    </Link>
                  )}
                  {role === "Phase Admin" && (
                    <Link to="/dashboard/generic">
                      <span>Go back</span>
                    </Link>
                  )}
                </li>
              </ol>
              <h4 className="main-title mb-0">Building List</h4>
            </div>
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <div
              className="form-check form-switch"
              style={{
                marginRight: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label
                htmlFor="flexSwitchCheckDefault"
                style={{ marginRight: "50px" }}
              >
                {active === 1 ? "Active" : "Inactive"}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                name="active"
                value={active}
                checked={active === 1}
                onChange={handleCheckboxChange}
                style={{ width: "50px", height: "25px" }} // Adjust size as needed
              />
            </div>
            <Button
              as={Link}
              to="/dashboard/create-building"
              variant="primary"
              className="d-flex align-items-center gap-2"
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
              <span>Create Building</span>
            </Button>
          </div>
        </div>

        {/* Render BuildingCard components for each building */}
        <Row xs={1} md={2} lg={2} xl={3} className="g-4">
          {loading ? (
            <div style={{ textAlign: "center", width: "100%" }}>
              <span
                className="spinner-border d-flex justify-content-center spinner-border-lg text-primary"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : buildingList?.length > 0 ? (
            buildingList.map((building, index) => (
              <Col key={index}>
                <ParkCard cardKey={`building`} cardValue={building} onDeleteSuccess={ handleDeleteSuccess } />
              </Col>
            ))
          ) : (
            <p style={{ textAlign: "center" }}>No Data Found</p>
          )}
        </Row>
        <div>
          {!loading && buildingList?.length > 0 && (
            <Pagination className="pagination-space justify-content-start pagination-filled  p-3 p-lg-4">
              <Pagination.Prev
                onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {displayedPages.map((number) => (
                <Pagination.Item
                  key={number}
                  active={number === currentPage}
                  onClick={() => onPageChange(number)}
                >
                  {number}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  currentPage < totalPages && onPageChange(currentPage + 1)
                }
                disabled={currentPage === totalPages}
              />
            </Pagination>
          )}
        </div>
      </div>

      {/* <Footer /> */}
    </React.Fragment>
  );
}
