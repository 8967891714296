import { axiosInstance } from "./axiosInstance";

//Api end point for create phase admin
export const createPhaseAdmin = (data) => {
  return axiosInstance.post("/api/user/phaseadmin", data);
};
// API endpoint for get IT park list
export const getITPhaseList = (park_id) => {
  return axiosInstance.get(`/api/park/phase?active=1&ParkId=${park_id}`);
};
// API endpoint for get IT park list
export const getITPhaseListApi = (park_id, currentPage, pageSize, active) => {
  return axiosInstance.get(
    `/api/park/phase?active=${active}&ParkId=${park_id}&page=${currentPage}&page_size=${pageSize}`
  );
};
export const createBuilding = (data) => {
  return axiosInstance.post("/api/park/building", data);
};

// API endpoint for creation of phase
export const createPhase = (data) => {
  return axiosInstance.post("/api/park/phase", data);
};

// API endpoint for creation of gate
export const createGate = (data) => {
  return axiosInstance.post("/api/park/gate", data);
};

// API endpoint for get building list
export const getBuildingListSelect = (phase_id) => {
  return axiosInstance.get("/api/park/building?PhaseId=" + phase_id);
};

// API endpoint for creation of company
export const createCompany = (data) => {
  return axiosInstance.post("/api/park/company", data);
};
export const getBuildingList = (phase_id, currentPage, pageSize, active) => {
  return axiosInstance.get(
    `/api/park/building?active=${active}&PhaseId=${phase_id}&page=${currentPage}&page_size=${pageSize}`
  );
};
export const getPhaseAdminList = (phase_id, active) => {
  return axiosInstance.get(
    `/api/user/phaseadmin?active=${active}&PhaseId=` + phase_id
  );
};

export const getVisitorDetails = (passId) => {
  return axiosInstance.get(`/api/pass/scan-qr-code?passId=` + passId);
};

// API endpoint for get gate list
export const getGateList = (PhaseId, active) => {
  return axiosInstance.get(`/api/park/gate?active=${active}&PhaseId=` + PhaseId);
};

//api endpoint for fetching phase details
export const getPhaseDetails = (phaseId) => {
  return axiosInstance.get("/api/park/phase-details/" + phaseId);
};

//api endpoint for fetching phase list
export const getPhaseDropdown = (parkId) => {
  return axiosInstance.get("/api/park/phase-dropdown/?is_active=1&ParkId=" + parkId);
};

export const getBuildingDropdown = (phaseId) => {
  return axiosInstance.get("/api/park/building-dropdown/?is_active=1&PhaseId=" + phaseId);
};

export const getGateDropdown = (phaseId) => {
  return axiosInstance.get("/api/park/gate-dropdown/?PhaseId=" + phaseId);
};

export const getPhaseAdminDetails = (phaseAdminId) => {
  return axiosInstance.get("/api/user/phaseadmin-details/" + phaseAdminId);
};

//Api end point for update phase admin
export const updatePhaseAdmin = (data, id) => {
  return axiosInstance.put("/api/user/phaseadmin-details/" + id, data);
};

//Api end point for delete phase admin
export const deletePhaseAdmin = (id) => {
  return axiosInstance.delete("/api/user/phaseadmin-details/" + id);
};

//Api end point for delete phase
export const deletePhase = (id) => {
  return axiosInstance.delete("/api/park/phase-details/" + id);
};

//Api end point for update phase
export const updatePhase = (id, data) => {
  return axiosInstance.put("/api/park/phase-details/" + id, data);
};

//Api end point for fetching gate details
export const getGateDetails = (id) => {
  return axiosInstance.get("/api/park/gate-detail/" + id);
};

//Api end point for delete gate
export const deleteGate = (id) => {
  return axiosInstance.delete("/api/park/gate-detail/" + id);
};

//Api end point for update gate
export const updateGate = (id, data) => {
  return axiosInstance.put("/api/park/gate-detail/" + id, data);
};
