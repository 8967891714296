import React, { useEffect, useState, useRef } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Form, Overlay, Tooltip } from "react-bootstrap";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function CreatePark() {
  // State variables
  const [parkname, setParkName] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [contactname, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [mobilenumber, setMobileNumber] = useState("");
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/dashboard/storage"); // Navigate to the signin page
  };

  // Refs for form fields
  const parknameRef = useRef(null);
  const locationRef = useRef(null);
  const addressRef = useRef(null);
  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const contactnameRef = useRef(null);
  const mobilenumberRef = useRef(null);

  // State variables for error messages
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [parknameError, setParkNameError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [contactnameError, setContactNameError] = useState("");
  const [mobilenumberError, setMobileNumberError] = useState("");
  const [usernameError, setUserNameError] = useState("");
  const [addressError, setAddressError] = useState("");

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  // Event handler for park name change
  const handleParkNameChange = (event) => {
    const value = event.target.value;
    setParkName(value);
    const error = validateParkName(value);
    setParkNameError(error);
  };

  // Function to validate park name
  const validateParkName = (value) => {
    if (!value.trim()) {
      return "Parkname field should not be empty";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Parkname must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return "Maximum length of Park name is 100 characters";
    }
    return "";
  };

  // Event handler for user name change
  const handleUserNameChange = (event) => {
    const value = event.target.value;
    setUserName(value);
    const error = validateUserName(value);
    setUserNameError(error);
  };

  // Function to validate user name
  const validateUserName = (value) => {
    if (!value.trim()) {
      return "Username field should not be empty";
    }
    if (!/^[a-zA-Z0-9]*$/.test(value)) {
      return "Username must contain only alphanumeric characters";
    }
    if (/^\d/.test(value)) {
      return "Username should not start with a number";
    }
    if (value.length > 100) {
      return "Maximum length of Username is 100 characters";
    }
    return "";
  };

  // Event handler for password change
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    const error = validatePassword(value);
    setPasswordError(error);
  };

  // Function to validate password
  const validatePassword = (value) => {
    if (!value.trim()) {
      return "Password field should not be empty";
    }
    if (!/^[A-Z]/.test(value)) {
      return "First letter of the password should be capital";
    }
    if (!/[a-z]/.test(value)) {
      return "Password must contain at least one lowercase letter";
    }
    if (!/[0-9]/.test(value)) {
      return "Password must contain at least one numeric digit";
    }

    if (!/[!@$&]/.test(value)) {
      return "Password must contain a special character (@, $, !, & etc.)";
    }

    if (value.length <= 8) {
      return "Password must be longer than 8 characters";
    }
    if (value.length > 20) {
      return "Password must not exceed 20 characters";
    }

    return "";
  };

  // Event handler for location change
  const handleLocationChange = (event) => {
    const value = event.target.value;
    setLocation(value);
    const error = validateLocation(value);
    setLocationError(error);
  };

  // Function to validate location
  const validateLocation = (value) => {
    if (!value.trim()) {
      return "Location field should not be empty";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Location must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return "Maximum length of Location is 100 characters";
    }
    return "";
  };

  // Event handler for contact name change
  const handleContactNameChange = (event) => {
    const value = event.target.value;
    setContactName(value);
    const error = validateContactName(value);
    setContactNameError(error);
  };

  // Function to validate contact name
  const validateContactName = (value) => {
    if (!value.trim()) {
      return "Contactname field should not be empty";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Contactname must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return "Maximum length of Contactname is 100 characters";
    }
    return "";
  };

  // Event handler for address change
  const handleAddressChange = (event) => {
    const value = event.target.value;
    setAddress(value);
    const error = validateAddress(value);
    setAddressError(error);
  };

  // Function to validate address
  const validateAddress = (value) => {
    if (!value.trim()) {
      return "Address field should not be empty";
    }
    if (value.length > 100) {
      return "Maximum length of Address is 100 characters";
    }
    return "";
  };

  // Event handler for mobile number change
  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    setMobileNumber(value);
    const error = validateMobileNumber(value);
    setMobileNumberError(error);
  };

  // Function to validate mobile number
  const validateMobileNumber = (value) => {
    if (!value.trim()) {
      return "Mobilenumber field should not be empty";
    }
    // Regular expression to match only 10 digits
    const mobileNumberRegex = /^\d{10}$/;
    const mobileNumberRegex1 = /^(?!0{10})[1-9][0-9]{9}$/;

    // Check if the value matches the regex
    if (!mobileNumberRegex.test(value)) {
      return "Mobilenumber should contain only numbers and exactly 10 digits";
    }
    // Check if the value matches the regex for not starting with zero
    if (!mobileNumberRegex1.test(value)) {
      // Check if the value contains 10 consecutive zeros
      if (/0{10}/.test(value)) {
        return "Consecutive 10 Zeros are not allowed";
      }
      // If it's not 10 consecutive zeros, it must start with zero
      return "Mobilenumber cannot start with Zero";
    }
    return "";
  };

  // Event handler for email change
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    const error = validateEmail(value);
    setEmailError(error);
  };

  // Function to validate mobile number
  const validateEmail = (value) => {
    if (!value.trim()) {
      return "Email field should not be empty";
    }

    const emailRegex =
      /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\s)*$/;

    // Check if the value matches the email regex
    if (!emailRegex.test(value)) {
      return "Please enter a valid email address";
    }
    return "";
  };

  //submit handler
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate all fields
    const userNameError = validateUserName(username);
    const passwordError = validatePassword(password);
    const emailError = validateEmail(email);
    const locationError = validateLocation(location);
    const addressError = validateAddress(address);
    const mobilenumberError = validateMobileNumber(mobilenumber);
    const contactnameError = validateContactName(contactname);
    const parknameError = validateParkName(parkname);

    // Set errors for each field
    setUserNameError(userNameError);
    setPasswordError(passwordError);
    setContactNameError(contactnameError);
    setMobileNumberError(mobilenumberError);
    setAddressError(addressError);
    setEmailError(emailError);
    setLocationError(locationError);
    setParkNameError(parknameError);

    if (
      userNameError ||
      passwordError ||
      contactnameError ||
      mobilenumberError ||
      addressError ||
      emailError ||
      locationError ||
      parknameError
    ) {
      return;
    } else {
      try {
      } catch (error) {
        // Handle login error (e.g., display error message)
      }
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div class="mb-4">
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <i
              className="ri-arrow-left-line"
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "24px",
              }}
            ></i>
            <h4 className="main-title mb-0">
              <b>Create Park</b>
            </h4>
          </div>
        </div>
        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Create Park</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} md={4}>
                      <div className="mb-4">
                        <Form.Label>
                          Park / Phase Name{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your park name"
                          value={parkname}
                          onChange={handleParkNameChange}
                        />
                        {parknameError && (
                          <div className="text-danger">{parknameError}</div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="mb-4">
                        <Form.Label>
                          User Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your user name"
                          value={username}
                          onChange={handleUserNameChange}
                        />
                        {usernameError && (
                          <div className="text-danger">{usernameError}</div>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} md={4}>
                      <div className="mb-4">
                        <Form.Label>
                          Password <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter your password"
                          value={password}
                          onChange={handlePasswordChange}
                        />
                        {passwordError && (
                          <div className="text-danger">{passwordError}</div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      <div className="mb-4">
                        <Form.Label>
                          Contact Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your contact name"
                          value={contactname}
                          onChange={handleContactNameChange}
                        />
                        {contactnameError && (
                          <div className="text-danger">{contactnameError}</div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="mb-4">
                        <Form.Label>
                          Email ID <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your email address"
                          value={email}
                          onChange={handleEmailChange}
                        />
                        {emailError && (
                          <div className="text-danger">{emailError}</div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="mb-4">
                        <Form.Label>
                          Mobile Number <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your mobile number"
                          value={mobilenumber}
                          onChange={handleMobileNumberChange}
                        />
                        {mobilenumberError && (
                          <div className="text-danger">{mobilenumberError}</div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <div className="mb-4">
                        <Form.Label>
                          Location <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your location"
                          value={location}
                          onChange={handleLocationChange}
                        />
                        {locationError && (
                          <div className="text-danger">{locationError}</div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="mb-4">
                        <Form.Label>
                          Address <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          type="text"
                          placeholder="Enter your address"
                          value={address}
                          onChange={handleAddressChange}
                        />
                        {addressError && (
                          <div className="text-danger">{addressError}</div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Button
                    type="submit"
                    variant="primary"
                    className="btn-sign mt-4"
                  >
                    Create Park
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </React.Fragment>
  );
}
