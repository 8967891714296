import React, { useRef, useEffect, useState } from "react";
import { Card, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { RiFileList2Line, RiTeamLine } from "react-icons/ri";
import RandomColorGenerator from "../shared/RandomColorGenerator";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPhaseId } from "../../Redux/Actions";
import { setParkId } from "../../Redux/Actions";
import { setBuildingId } from "../../Redux/Actions";
import { FaTrash, FaPencilAlt, FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import ConfirmationModal from "../shared/ConfirmationModal";
import { deleteCompanyUser } from "../../services/Company";
import { deletePark } from "../../services/Park";
import { deleteGate, deletePhase } from "../../services/Phase";
import { showToast } from "../shared/Toaster";
import { deleteBuilding } from "../../services/Company";

const ParkCard = ({ cardKey, cardValue, onDeleteSuccess, currentPage }) => {
  const tooltipRef = useRef(null);
  const dispatch = useDispatch();
  const [userList, setUserList] = useState(false);

  const parkId = useSelector((state) => state.park.parkId);

  const phaseId = useSelector((state) => state.phase.phaseId);

  const buildingId = useSelector((state) => state.building.buildingId);

  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  const role_name = localStorage.getItem("role_name");

  useEffect(() => {
    const tooltip = tooltipRef.current;
    if (tooltip) {
      new window.bootstrap.Tooltip(tooltip, {
        delay: { show: 500, hide: 100 },
        container: "body",
      });
    }
    if (cardKey === "userlist") {
      setUserList(true);
    }
  }, [cardKey, cardValue]);

  //Delete functionality
  const handleDelete = async (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setShowModal(false);
    try {
      setLoading(true);
      let response;

      if (cardKey === "userlist") {
        response = await deleteCompanyUser(currentIndex);
      } else if (cardKey === "park") {
        response = await deletePark(currentIndex);
      } else if (cardKey === "phase") {
        response = await deletePhase(currentIndex);
      } else if (cardKey === "building") {
        response = await deleteBuilding(currentIndex);
      } else if (cardKey === "gate") {
        response = await deleteGate(currentIndex);
      }
      onDeleteSuccess(); // Notify parent component of successful deletion
      setLoading(false);
      showToast("success", response.data.message);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.message) {
          showToast("error", error.response.data.message);
        } else if (error.response.status === 500) {
          showToast("error", error.response.statusText);
        }
      } else {
        showToast("error", "Internal Server Error");
      }
    }
  };

  const keyMapping = {
    park: { firstKey: "parkName", secondKey: "Location" },
    phase: { firstKey: "PhaseName", secondKey: "Location" },
    building: { firstKey: "buildingName", secondKey: "Location" },
    gate: { firstKey: "gateName", secondKey: "contactName" },
    userlist: { firstKey: "name", secondKey: "Location" },

    // Add more mappings as needed
  };

  // Retrieve the keys based on the cardKey
  const { firstKey, secondKey } = keyMapping[cardKey] || {
    firstKey: "",
    secondKey: "",
  };

  const firstKeyValue = cardValue[firstKey] || "";
  const secondKeyValue = cardValue[secondKey] || "";

  const firstLetter =
    typeof firstKeyValue === "string"
      ? firstKeyValue.charAt(0).toUpperCase()
      : "";
  const secondLetter =
    typeof secondKeyValue === "string"
      ? secondKeyValue.charAt(0).toUpperCase()
      : "";

  const randomColor = RandomColorGenerator({
    name: typeof firstKeyValue === "string" ? firstKeyValue : "",
    location: typeof secondKeyValue === "string" ? secondKeyValue : "",
  });

  return (
    <Card className="park-card shadow-sm mb-3">
      <Row>
        <div className="d-flex gap-1">
          {cardKey && (
            <div
              className="circle"
              style={{ backgroundColor: randomColor, minWidth: "90px" }}
            >
              {firstLetter}
              {secondLetter}
            </div>
          )}
          <div
            className="flex-grow-1 p-3"
            style={{
              minWidth: userList ? "196px" : "none",
            }}
          >
            <div
              className="d-flex flex-column gap-3 border-bottom border-2"
              style={{ height: "250px", overflow: "scroll" }}
            >
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex flex-column gap-0 card-wrap"
                  style={{ overflowY: "scroll" }}
                >
                  {cardKey === "park" && <h5>{cardValue.parkName}</h5>}
                  {cardKey === "phase" && <h5>{cardValue.PhaseName}</h5>}
                  {cardKey === "building" && <h5>{cardValue.buildingName}</h5>}
                  {cardKey === "userlist" && <h5>{cardValue.first_name}</h5>}
                  {cardKey === "gate" && <h5>{cardValue.gateName}</h5>}
                  {cardKey !== "gate" && (
                    <p className="text-secondary text-uppercase pb-0">
                      {cardValue.Location}
                    </p>
                  )}
                  {cardKey === "gate" && (
                    <p className="text-secondary text-uppercase pb-0 fix-name-icon">
                      {cardValue.contactName}
                    </p>
                  )}
                  {cardKey === "userlist" && (
                    <p className="text-secondary text-uppercase pb-0 fix-name-icon">
                      {cardValue.name}{" "}
                      {cardValue?.role?.name === "Company Admin" &&
                        ` - ${cardValue?.role?.name}`}
                    </p>
                  )}
                </div>
                <div
                  className="text-primary d-flex gap-3"
                  style={{ position: "sticky", top: "0" }}
                >
                  {cardKey === "park" && cardValue.is_active === true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>View</Tooltip>}
                    >
                      <Link
                        to="/dashboard/park-details"
                        onClick={() => {
                          // Dispatch action to update park ID in Redux store
                          dispatch(setParkId(cardValue.id)); // assuming 'cardValue.id' is the park ID
                        }}
                        className="text-primary"
                      >
                        <FaEye style={{ cursor: "pointer" }} className="h6" />
                      </Link>
                    </OverlayTrigger>
                  )}
                  {cardKey === "phase" && cardValue.is_active === true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>View</Tooltip>}
                    >
                      <Link
                        to="/dashboard/phase-details/?list"
                        onClick={() => {
                          // Dispatch action to update phase ID in Redux store
                          dispatch(setPhaseId(cardValue.id)); // assuming 'cardValue.id' is the phase ID
                        }}
                        className="text-primary"
                      >
                        <FaEye style={{ cursor: "pointer" }} className="h6" />
                      </Link>
                    </OverlayTrigger>
                  )}
                  {cardKey === "building" && cardValue.is_active === true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>View</Tooltip>}
                    >
                      <Link
                        to="/dashboard/building-details/?list"
                        className="text-primary"
                        onClick={() => {
                          // Dispatch action to update phase ID in Redux store
                          dispatch(setBuildingId(cardValue.id));
                          dispatch(setParkId(parkId));
                          dispatch(setPhaseId(phaseId));
                        }}
                      >
                        <FaEye style={{ cursor: "pointer" }} className="h6" />
                      </Link>
                    </OverlayTrigger>
                  )}
                  {cardKey === "gate" && cardValue?.is_active === true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip></Tooltip>}
                    >
                      <Link to="" className="text-primary"></Link>
                    </OverlayTrigger>
                  )}
                  {cardKey === "userlist" && cardValue?.is_active === true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>View</Tooltip>}
                    >
                      <Link
                        to={{
                          pathname: `/dashboard/pass-list`,
                          className: "text-primary",
                        }}
                      >
                        <FaEye style={{ cursor: "pointer" }} className="h6" />
                      </Link>
                    </OverlayTrigger>
                  )}
                  {cardKey === "building" && cardValue.is_active === true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Edit</Tooltip>}
                    >
                      <Link
                        to={{
                          pathname: `/dashboard/create-building/`,
                          search: `?id=${cardValue.id}`,
                        }}
                        className="text-primary"
                      >
                        <FaPencilAlt
                          style={{ cursor: "pointer" }}
                          className="h6"
                        />
                      </Link>
                    </OverlayTrigger>
                  )}
                  {cardKey === "park" && cardValue.is_active === true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Edit</Tooltip>}
                    >
                      <Link
                        to={{
                          pathname: `/dashboard/create-park/`,
                          search: `?id=${cardValue.id}`,
                        }}
                        className="text-primary"
                      >
                        <FaPencilAlt
                          style={{ cursor: "pointer" }}
                          className="h6"
                        />
                      </Link>
                    </OverlayTrigger>
                  )}
                  {cardKey === "phase" && cardValue.is_active === true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Edit</Tooltip>}
                    >
                      <Link
                        to={{
                          pathname: `/dashboard/create-phase/`,
                          search: `?id=${cardValue.id}`,
                        }}
                        className="text-primary"
                      >
                        <FaPencilAlt
                          style={{ cursor: "pointer" }}
                          className="h6"
                        />
                      </Link>
                    </OverlayTrigger>
                  )}
                  {cardKey === "userlist" &&
                    role_name !== "Company User" &&
                    cardValue.active === 1 &&
                    cardValue?.role?.name !== "Company Admin" && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Edit</Tooltip>}
                      >
                        <Link
                          to={{
                            pathname: `/dashboard/create-company-user/`,
                            search: `?id=${cardValue.id}`,
                          }}
                          className="text-primary"
                        >
                          <FaPencilAlt
                            style={{ cursor: "pointer" }}
                            className="h6"
                          />
                        </Link>
                      </OverlayTrigger>
                    )}

                  {cardKey === "gate" && cardValue.is_active === true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Edit</Tooltip>}
                    >
                      <Link
                        to={{
                          pathname: `/dashboard/create-gate/`,
                          search: `?id=${cardValue.id}`,
                        }}
                        className="text-primary"
                      >
                        <FaPencilAlt
                          style={{ cursor: "pointer" }}
                          className="h6"
                        />
                      </Link>
                    </OverlayTrigger>
                  )}

                  {cardKey === "userlist" &&
                    role_name !== "Company User" &&
                    cardValue?.role?.name !== "Company Admin" &&
                    cardValue.active === 1 && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Delete</Tooltip>}
                      >
                        <Link
                          to="#"
                          onClick={() => handleDelete(cardValue.id)}
                          className="text-primary"
                        >
                          <FaTrash
                            style={{ cursor: "pointer" }}
                            className="h6"
                          />
                        </Link>
                      </OverlayTrigger>
                    )}
                  {cardKey !== "userlist" && cardValue.is_active === true && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Delete</Tooltip>}
                    >
                      <Link
                        to="#"
                        onClick={() => handleDelete(cardValue.id)}
                        className="text-primary"
                      >
                        <FaTrash style={{ cursor: "pointer" }} className="h6" />
                      </Link>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
              {cardKey === "userlist" && (
                <ConfirmationModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  onConfirm={handleConfirm}
                  message="Are you sure you want to delete the user?"
                />
              )}
              {cardKey === "park" && (
                <ConfirmationModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  onConfirm={handleConfirm}
                  message="Are you sure you want to delete the park?"
                />
              )}
              {cardKey === "phase" && (
                <ConfirmationModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  onConfirm={handleConfirm}
                  message="Are you sure you want to delete the phase?"
                />
              )}
              {cardKey === "gate" && (
                <ConfirmationModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  onConfirm={handleConfirm}
                  message="Are you sure you want to delete the gate?"
                />
              )}
              {cardKey === "building" && (
                <ConfirmationModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  onConfirm={handleConfirm}
                  message="Are you sure you want to delete the building?"
                />
              )}
              <div style={{ overflowY: "scroll" }}>
                {cardKey === "userlist" && (
                  <div className="card-wrap">
                    <strong style={{ fontSize: "0.75rem" }}>
                      Contact Details
                    </strong>
                    <div className="d-flex flex-column gap-1 mt-1">
                      <p
                        className="text-wrap mb-0"
                        style={{ color: "#808080" }}
                      >
                        {cardValue.email}
                      </p>
                      <p className="mb-0" style={{ color: "#808080" }}>
                        {cardValue.phonenumber}
                      </p>
                    </div>
                  </div>
                )}

                {cardKey !== "gate" && cardValue.address && (
                  <div className="pb-2 card-wrap">
                    <strong style={{ fontSize: "0.75rem" }}>Address</strong>
                    <div className="mt-1 d-flex flex-column gap-0">
                      {cardValue.address.split("+").map((line, index) => (
                        <p
                          key={index}
                          className="mb-0"
                          style={{ color: "#808080" }}
                        >
                          {line}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
                {cardKey === "gate" && (
                  <div className="pb-2 card-wrap">
                    <strong style={{ fontSize: "0.75rem" }}>Email</strong>
                    <div className="mt-1 d-flex flex-column gap-0">
                      <p className="mb-0" style={{ color: "#808080" }}>
                        {cardValue.email}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {cardKey !== "gate" && (
              <div className="d-flex justify-content-around flex-column flex-sm-row pt-3 gap-3">
                <div className="d-flex gap-1 align-items-center">
                  <div className="icon-box bg-secondary">
                    <RiFileList2Line style={{ color: "white" }} />
                  </div>
                  <div className="d-flex flex-column gap-0">
                    <div>
                      <small>Passes</small>
                    </div>
                    <div className="h4 mb-0">
                      <div>{cardValue.count?.pass_generated || 0}</div>
                    </div>
                  </div>
                </div>

                <div className="d-flex gap-1 align-items-center">
                  <div className="icon-box bg-secondary">
                    <RiTeamLine style={{ color: "white" }} />
                  </div>
                  <div className="d-flex flex-column gap-0">
                    <div>
                      <small>Visited</small>
                    </div>
                    <div className="h4 mb-0">
                      <span>{cardValue.count?.visited || 0}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Row>
    </Card>
  );
};

export default ParkCard;
