import React, { useState, useEffect } from "react";
import { Modal, Button, Image } from "react-bootstrap";
import { markEntry as markEntryApi } from "../../services/GateUser";
import { showToast } from "../shared/Toaster";
import Dummy from "../../assets/img/Dummy.png";
import MessageModal from "./messageModal";
import { passHistory } from "../../services/Pass";
import HistoryModal from '../Pass/HistoryModal';

function VisitorDetailsModal({ show, handleClose, visitor, validity, sourcePage }) {
  const role = localStorage.getItem("role_name");
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState();
  const [showHistory, setShowHistory] = useState(false);

  const handleHistoryClick = () => {
    setShowHistory(true);
    // You can perform additional actions here when history is clicked
  };

  const handleCloseHistory = () => {
    setShowHistory(false);
    // Perform actions here when history window/modal is closed
  };

  const [messageModal, setMessageModal] = useState({
    show: false,
    title: "",
    message: "",
    variant: "primary",
  });

  const markEntry = async () => {
    try {
      let data = {
        passId: visitor.id,
      };
      setLoading(true);
      const response = await markEntryApi(data);
      setLoading(false);
      showToast("success", response.data.message);
      handleClose({ success: true });
    } catch (error) {
      setLoading(false);
      handleClose({ success: false });
      let errorMessage = "Internal Server Error";
      if (error.response) {
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (error.response.status === 401) {
          errorMessage = "Unauthorized: Please check your credentials.";
        } else if (error.response.status === 500) {
          errorMessage = error.response.statusText;
        } else {
          errorMessage = `Error: ${error.response.status}`;
        }
      }
      setMessageModal({
        show: true,
        title: "Error",
        message: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (visitor) {
    }
  }, [visitor]);

  const closeMessageModal = () => {
    setMessageModal({ ...messageModal, show: false });
  };

  useEffect(() => {
    const fetchPassHistory = async () => {
      try {
        setLoading(true);
        const response = await passHistory(visitor.id); // Assuming getITParkList is an async function that fetches data
        setHistory(response.data.data); // Store the data in state
      } catch (error) {
        setLoading(false);
      }
    };
    fetchPassHistory();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose({ success: false })}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="w-100 text-center">
            {validity === "invalid" && (
              <span className="text-danger text-center fw-bold fs-4">
                {visitor.reason === "Expired"
                  ? `Visitor pass has ${visitor.reason}`
                  : visitor.reason === "Early Entry"
                  ? ` Invalid due to ${visitor.reason}`
                  : visitor.reason ===
                    "Entry restricted since company is not in this phase"
                  ? `Invalid as the company is not in this phase`
                  : `${visitor.reason}`}
              </span>
            )}
            <div className="text-center">Visitor Details</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "350px", overflowY: "auto" }}>
          <div
            className="d-flex flex-column gap-3"
            style={{ minWidth: "280px" }}
          >
            <div className="d-flex justify-content-center">
              {visitor?.files?.photo ? (
                <Image
                  src={`${process.env.REACT_APP_API_URL}/${visitor.files.photo}`}
                  alt="Visitor photo"
                  width="240"
                  height="240"
                  fluid={true}
                />
              ) : (
                <Image
                  src={Dummy}
                  alt="Visitor photo"
                  width="140"
                  height="140"
                  fluid={true}
                />
              )}
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-column gap-0">
                <div
                  style={{ wordBreak: "break-word" }}
                  className="h5 align-self-center"
                >
                  {visitor.name}
                </div>
                <div
                  className="text-muted align-self-center"
                  style={{ fontSize: "0.8rem" }}
                >
                  Delegate Pass  
                  {(role !== "Gate User" && sourcePage === "pass" )&&  (
                    <Button variant="link" onClick={handleHistoryClick} className="align-self-center">
                    View History
                  </Button>
                  )}
                </div>
                <hr />
              </div>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-column gap-1 flex-wrap">
                  <strong style={{ fontSize: "0.75rem" }}>Company</strong>
                  <div className="text-uppercase">{visitor.company}</div>
                  <div
                    className="text-wrap text-muted"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {visitor.company_address}
                  </div>
                </div>
                <div className="d-flex flex-column gap-1">
                  <strong style={{ fontSize: "0.75rem" }}>Purpose</strong>
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="text-wrap text-muted"
                  >
                    {visitor.purpose}
                  </div>
                </div>
                <div className="d-flex flex-column gap-1">
                  <strong style={{ fontSize: "0.75rem" }}>Validity</strong>
                  <div className="d-flex gap-2 align-items-end">
                    <div>{visitor.start_datetime}</div>
                    <div className="text-muted" style={{ fontSize: "0.75rem" }}>
                      -
                    </div>
                    <div>{visitor.end_datetime}</div>
                  </div>
                </div>
                <div className="d-flex flex-column gap-1">
                  <strong style={{ fontSize: "0.75rem" }}>ID Proof</strong>
                  {visitor?.files?.id_proof ? (
                    <Image
                      src={`${process.env.REACT_APP_API_URL}/${visitor?.files?.id_proof}`}
                      alt="ID Proof"
                      width="100%"
                      className="aspect-square"
                    />
                  ) : (
                    <p>No ID Proof Found</p>
                  )}
                </div>
                
              </div>
            </div>
          </div>
          {showHistory && (
        <HistoryModal history={history} onClose={handleCloseHistory} />
      )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          {role === "Gate User" && validity === "valid" && (
            <Button
              type="submit"
              variant="primary"
              onClick={markEntry}
              disabled={loading}
              style={{ width: "150px", textAlign: "center" }}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                "Mark Entry"
              )}
            </Button>
          )}
          <Button
            variant="outline-secondary"
            onClick={() => handleClose({ success: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <MessageModal
        show={messageModal.show}
        onHide={closeMessageModal}
        title={messageModal.title}
        message={messageModal.message}
        variant={messageModal.variant}
      />
    </>
  );
}


export default VisitorDetailsModal;
