import { createStore, applyMiddleware } from 'redux';
import {thunk }from 'redux-thunk';
import { rootReducer } from './Index';

// Load state from localStorage
const persistedState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')) : {};

const middleware = [thunk];
const store = createStore(rootReducer, persistedState, applyMiddleware(...middleware));

// Save state to localStorage on every store update
store.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()));
  });

export default store;