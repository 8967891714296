import React from "react";
import pearlLogo from "../assets/img/Logo_pearlsoft.png";


export default function Footer() {
  return (
    <div className="main-footer">
      <span></span>
      <div className="d-flex flex-column gap-0 ms-2 me-4">
      <div className="text-muted small">Powered by
        </div>
        <div>
        <img
            src={pearlLogo}
            alt="logo"
            height="32px"
          />
        </div>
      </div>
      
    </div>
  )
}