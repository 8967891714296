// ParkList.js
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import ParkCard from "./ParkCard"; // Import ParkCard component
import { getParkListApi } from "../../services/Park";
import { useParams } from "react-router-dom";

export default function ParkList() {
  const navigate = useNavigate();
  const { key } = useParams(); // Extract key from URL

  const handleGoBack = () => {
    navigate("/dashboard/generic"); // Navigate to the signin page
  };

  const handleDeleteSuccess = () => {
    // Update user list after successful deletion
    const parkId = localStorage.getItem("userPark");
    fetchParkList(parkId);
  };

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [parkList, setParkList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Page size of 6
  const [totalItems, setTotalItems] = useState(0);
  const [displayedPages, setDisplayedPages] = useState([]);
  const [active, setActive] = useState(1);

  useEffect(() => {
    document.title = "Park List";
    fetchParkList();
    switchSkin(skin);
  }, [skin, currentPage, pageSize, active]);

  const fetchParkList = async () => {
    setParkList([]);
    setTotalItems("");
    try {
      setLoading(true);
      const response = await getParkListApi(currentPage, pageSize, active); // Assuming getITParkList is an async function that fetches data
      setParkList(response.data.data.park_list.results); // Store the data in state
      setTotalItems(response.data.data.park_list.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setParkList([]);
      setTotalItems("");
    }
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setActive(newValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    updateDisplayedPages();
  }, [currentPage, totalItems]);

  // Function to handle page change
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updateDisplayedPages = () => {
    const totalPages = Math.ceil(totalItems / pageSize);
    const maxDisplayedPages = 3;
    const halfRange = Math.floor(maxDisplayedPages / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

    if (endPage - startPage < maxDisplayedPages - 1) {
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    setDisplayedPages(pages);
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line "
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/generic">
                    <span>Go back</span>
                  </Link>
                </li>
              </ol>
              <h4 className="main-title mb-0">Park List</h4>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <div
              className="form-check form-switch"
              style={{
                marginRight: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label
                htmlFor="flexSwitchCheckDefault"
                style={{ marginRight: "50px" }}
              >
                {active === 1 ? "Active" : "Inactive"}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                name="active"
                value={active}
                checked={active === 1}
                onChange={handleCheckboxChange}
                style={{ width: "50px", height: "25px" }} // Adjust size as needed
              />
            </div>
            <Button
              as={Link}
              to="/dashboard/create-park"
              variant="primary"
              className="d-flex align-items-center gap-2"
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
              <span>Create Park</span>
            </Button>
          </div>
        </div>

        <Row xs={1} md={2} lg={2} xl={3} className="g-4">
          {loading ? (
            <div style={{ textAlign: "center", width: "100%" }}>
              <span
                className="spinner-border d-flex justify-content-center spinner-border-lg text-primary"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : parkList?.length > 0 ? (
            parkList.map((park, index) => (
              <Col key={index}>
                <ParkCard
                  cardKey={`park`}
                  cardValue={park}
                  onDeleteSuccess={handleDeleteSuccess}
                />
              </Col>
            ))
          ) : (
            <p style={{ textAlign: "center" }}>No Data Found</p>
          )}
        </Row>

        <div>
          {!loading && parkList?.length > 0 && (
            <Pagination className="pagination-space justify-content-start pagination-filled  p-3 p-lg-4">
              <Pagination.Prev
                onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {displayedPages.map((number) => (
                <Pagination.Item
                  key={number}
                  active={number === currentPage}
                  onClick={() => onPageChange(number)}
                >
                  {number}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  currentPage < totalPages && onPageChange(currentPage + 1)
                }
                disabled={currentPage === totalPages}
              />
            </Pagination>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
