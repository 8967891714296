// ParkList.js
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Table,
  Pagination,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import RandomColorGenerator from "../shared/RandomColorGenerator";
import { useHistory, useParams } from "react-router-dom";
import {
  getCompanyList,
  getBuildingDetails,
  deleteCompany,
} from "../../services/Company";
import { useSelector } from "react-redux";
import { setBuildingId } from "../../Redux/Actions";
import { setCompanyId } from "../../Redux/Actions";
import { setPhaseId } from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { showToast } from "../shared/Toaster";
import ConfirmationModal from "../shared/ConfirmationModal";

export default function BuildingDetailsScreen() {
  const [role, setRole] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);

  // for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Page size of 6
  const [totalItems, setTotalItems] = useState(0);
  const [displayedPages, setDisplayedPages] = useState([]);

  const location = useLocation();
  const { state } = location; // Extract state object from location
  const data = state && state.data; // Access data property from state object

  const dispatch = useDispatch();

  const BuildingId = useSelector((state) => state.building.BuildingId);
  const PhaseId = useSelector((state) => state.phase.phaseId);
  const ParkId = useSelector((state) => state.park.parkId);


  const searchParams = new URLSearchParams(location.search);
  const gen = searchParams.get("gen");
  const list = searchParams.get("list");
  const [logTotalCount, setLogTotalCount] = useState("");

  const [buildingDetails, setBuildingDetails] = useState("");
  const [active, setActive] = useState(1);

  // const history = useHistory();
  const navigate = useNavigate();

  // Now you can use gen to conditionally navigate
  const handleGoBack = () => {
    if (role === "Phase Admin") {
      if (gen !== null) {
        navigate("/dashboard/generic");
      } else if (list !== null) {
        navigate("/dashboard/building-list");
      }
    } else {
      if (gen !== null) {
        navigate("/dashboard/phase-details/?gen");
      } else if (list !== null) {
        navigate("/dashboard/building-list");
      } else {
        navigate("/dashboard/generic");
      }
    }
  };

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const parkAddress = "Infopark Phase 1, Infopark Kochi P O, Kakkanad 682 042";

  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  //Delete functionality
  const handleDelete = async (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const fetchCompanyList = async () => {
    setCompanyList([]);
    setTotalItems("");
    setLogTotalCount("");
    try {
      setLoading(true);
      const response = await getCompanyList(
        BuildingId,
        currentPage,
        pageSize,
        active
      );
      setCompanyList(response.data.data.company_list.results);
      setTotalItems(response.data.data.company_list.count);
      setLogTotalCount(response.data.data.Total_count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setCompanyList([]);
      setTotalItems("");
      setLogTotalCount("");
    }
  };

  const handleConfirm = async () => {
    setShowModal(false);
    try {
      setLoading(true);
      const response = await deleteCompany(currentIndex);
      setLoading(false);
      showToast("success", response.data.message);
      fetchCompanyList(); // Refetch the list after deletion
    } catch (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.message) {
          showToast("error", error.response.data.message);
        } else if (error.response.status === 500) {
          showToast("error", error.response.statusText);
        }
      } else {
        showToast("error", "Internal Server Error");
      }
    }
  };

  useEffect(() => {
    fetchCompanyList();
    switchSkin(skin);
  }, [skin, currentPage, pageSize, active]);

  useEffect(() => {
    document.title = "Building Details";
    const role = localStorage.getItem("role_name");

    if (role == "Super Admin") {
      setRole("Super Admin");
    } else if (role === "Park Admin") {
      setRole("Park Admin");
    } else if (role === "Phase Admin") {
      setRole("Phase Admin");
    }
    switchSkin(skin);
  }, [skin]);

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  useEffect(() => {
    const fetchBuildingDetails = async () => {
      try {
        // setLoading(true);
        const response = await getBuildingDetails(BuildingId);
        setBuildingDetails(response.data.data);
      } catch (error) {
        setLoading(false);
      }
    };

    //Function to fetch the it building details

    fetchBuildingDetails();
  }, []);

  useEffect(() => {
    updateDisplayedPages();
  }, [currentPage, totalItems]);

  const goToCompanyDetails = (value) => {
    const today = new Date();
    const currentMonthStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    const nextMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1
    );
    const currentMonthEnd = new Date(nextMonthStart.getTime() - 1); // Last day of current month
    navigate("/dashboard/pass-list", {
      state: {
        start_date: currentMonthStart.toLocaleDateString("en-GB"),
        end_date: currentMonthEnd.toLocaleDateString("en-GB"),
        parkId: ParkId,
        phaseId: PhaseId,
        buildingId: BuildingId,
        companyId: value,
      },
    });
  };
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updateDisplayedPages = () => {
    const totalPages = Math.ceil(totalItems / pageSize);
    const maxDisplayedPages = 3;
    const halfRange = Math.floor(maxDisplayedPages / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

    if (endPage - startPage < maxDisplayedPages - 1) {
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    setDisplayedPages(pages);
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const convertToIST = (dateString) => {
    return moment
      .utc(dateString)
      .tz("Asia/Kolkata")
      .format("DD/MM/YYYY HH:mm:ss");
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setActive(newValue);
    setCurrentPage(1);
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 position-relative">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line"
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  {role === "Phase Admin" ? (
                    gen !== null ? (
                      <Link to="/dashboard/generic">
                        <span>Go back</span>
                      </Link>
                    ) : list !== null ? (
                      <Link to="/dashboard/building-list">
                        <span>Go back</span>
                      </Link>
                    ) : (
                      <Link to="/dashboard/building-list">
                        <span>Go back</span>
                      </Link>
                    )
                  ) : gen !== null ? (
                    <Link to="/dashboard/phase-details/?gen">
                      <span>Go back</span>
                    </Link>
                  ) : list !== null ? (
                    <Link to="/dashboard/building-list">
                      <span>Go back</span>
                    </Link>
                  ) : (
                    <Link to="/dashboard/generic">
                      <span>Go back</span>
                    </Link>
                  )}
                </li>
              </ol>
              <h4 className="main-title mb-0">{buildingDetails?.name}</h4>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <div
              className="form-check form-switch"
              style={{
                marginRight: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label
                htmlFor="flexSwitchCheckDefault"
                style={{ marginRight: "50px" }}
              >
                {active === 1 ? "Active" : "Inactive"}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                name="active"
                value={active}
                checked={active === 1}
                onChange={handleCheckboxChange}
                style={{ width: "50px", height: "25px" }} // Adjust size as needed
              />
            </div>
            <Button
              as={Link}
              to="/dashboard/create-company"
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => {
                dispatch(setBuildingId(BuildingId));
              }}
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
              <span>Create Company</span>
            </Button>
          </div>
        </div>

        <div className="d-flex flex-md-row flex-column gap-2">
          <div className="mr-2 flex-grow-1 d-flex flex-column gap-2">
            
            <Card className="card-one" style={{ minHeight: "430px" }}>
              <Card.Header>
                <Card.Title as="h6">Company Logs</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <div className="d-flex flex-wrap gap-5 mb-4">
                  <div className="earning-item d-flex flex-column">
                    <div className="earning-icon bg-primary">
                      <i className="ri-building-3-line"></i>
                    </div>
                    <h4>{totalItems || 0}</h4>
                    <label>Company</label>
                  </div>
                  <div className="earning-item d-flex flex-column">
                    <div className="earning-icon bg-twitter">
                      <i className="ri-file-list-2-line"></i>
                    </div>
                    <h4>{logTotalCount.pass_generated || 0}</h4>
                    <label>Pass Generated</label>
                  </div>
                  <div className="earning-item d-flex flex-column">
                    <div className="earning-icon bg-success">
                      <i className="ri-team-line"></i>
                    </div>
                    <h4>{logTotalCount.visited || 0}</h4>
                    <label>Visited</label>
                  </div>
                </div>

                <Table className="table-two mb-4" responsive>
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th style={{ textAlign: "left" }}>Contact Name</th>
                      <th style={{ textAlign: "left" }}>Email ID</th>
                      <th>Contact Number</th>
                      <th>Pass Generated</th>
                      <th>Pass Visited</th>
                      <th>Created Date</th>
                      {active === 1 && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {companyList?.length > 0 ? (
                      companyList?.map((item, index) => {
                        const randomColor = RandomColorGenerator({
                          name: item.name,
                          location: item.name,
                        });
                        const avatarStyle = {
                          backgroundColor: randomColor,
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#fff",
                          fontSize: "1rem",
                          textTransform: "uppercase",
                        };
                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <div style={avatarStyle}>
                                  {item.name.charAt(0)}
                                </div>
                                <div
                                  onClick={() => {
                                    dispatch(setPhaseId(PhaseId));
                                    dispatch(setCompanyId(item.id));
                                    dispatch(setBuildingId(BuildingId));
                                    goToCompanyDetails(item.id);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <h6 className="mb-0">{item.name}</h6>
                                  <span className="fs-xs">{item.email}</span>
                                </div>
                              </div>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {item?.company_admin?.contactName}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {item?.company_admin?.email}
                            </td>
                            <td>{item?.company_admin?.contactNumber}</td>
                            <td>{item?.count?.pass_generated || 0}</td>
                            <td>{item?.count?.visited || 0}</td>
                            <td>{convertToIST(item.created_on)}</td>
                            {active === 1 && (
                              <td className="text-end">
                                <div className="d-flex gap-3 align-items-end justify-content-end">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Edit</Tooltip>}
                                  >
                                    <Link
                                      to={{
                                        pathname: `/dashboard/create-company/`,
                                        search: `?id=${item.id}`,
                                      }}
                                      className="text-primary"
                                    >
                                      <FaPencilAlt
                                        style={{ cursor: "pointer" }}
                                        className="h6"
                                      />
                                    </Link>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Delete</Tooltip>}
                                  >
                                    <Link
                                      to="#"
                                      className="text-primary"
                                      onClick={() => handleDelete(item.id)}
                                    >
                                      <FaTrash
                                        style={{ cursor: "pointer" }}
                                        className="h6"
                                      />
                                    </Link>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <ConfirmationModal
              show={showModal}
              onHide={() => setShowModal(false)}
              onConfirm={handleConfirm}
              message="Are you sure you want to delete the company?"
            />
          </div>
        </div>
        <div>
          {!loading && companyList?.length > 0 && (
            <Pagination className="pagination-space justify-content-start pagination-filled  p-3 p-lg-4">
              <Pagination.Prev
                onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {displayedPages.map((number) => (
                <Pagination.Item
                  key={number}
                  active={number === currentPage}
                  onClick={() => onPageChange(number)}
                >
                  {number}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  currentPage < totalPages && onPageChange(currentPage + 1)
                }
                disabled={currentPage === totalPages}
              />
            </Pagination>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
