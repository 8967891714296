import React, { useEffect, useState, useRef } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Form } from "react-bootstrap";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { getITParkList } from "../../services/Park";
import {
  createParkAdmin,
  getParkAdminDetails,
  updateParkAdmin,
} from "../../services/Park";
import { showToast } from "../../components/shared/Toaster";
import Select from "react-select";
import { useSelector } from "react-redux";

export default function CreateParkAdmin() {
  // State variables
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [contactname, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [alternateemail, setAlternateEmail] = useState("");
  const [mobilenumber, setMobileNumber] = useState("");

  const [itparkList, setITParkList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(""); // State variable to manage password visibility
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const parkId = useSelector((state) => state.park.parkId);

  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const parkAdminId = query.get("id");

  const [itpark, setITPark] = useState(parkId);

  useEffect(() => {
    const fetchITParkList = async () => {
      try {
        const response = await getITParkList(); // Assuming getITParkList is an async function that fetches data
        const parks = response.data.data.park_list.results;
        setITParkList(parks); // Store the data in state
      } catch (error) {}
    };
    fetchITParkList();
  }, [parkId]);
  useEffect(() => {
    if (itparkList.length > 0 && parkId) {
      const particularPark = itparkList.find((park) => park.id === parkId);
      if (particularPark) {
        setITPark({ value: particularPark.id, label: particularPark.parkName });
      }
    }
  }, [itparkList, parkId]);

  //For getting the park admin details in the case of edit park admin
  useEffect(() => {
    const fetchParkAdminDetails = async () => {
      try {
        const response = await getParkAdminDetails(parkAdminId);
        if (response) {
          setContactName(response.data.data.first_name);
          setEmail(response.data.data.email);
          setAlternateEmail(response.data.data.alternate_email);
          setMobileNumber(response.data.data.phonenumber);
        }
      } catch (error) {}
    };
    fetchParkAdminDetails();
  }, [parkAdminId]);

  const handleGoBack = () => {
    navigate("/dashboard/park-details"); // Navigate to the signin page
  };

  // State variables for error messages
  const [emailError, setEmailError] = useState("");
  const [alternateemailError, setAlternateEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [contactnameError, setContactNameError] = useState("");
  const [mobilenumberError, setMobileNumberError] = useState("");
  const [usernameError, setUserNameError] = useState("");
  const [itparkError, setITParkError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    if (parkAdminId) {
      document.title = "Edit Park Admin";
    } else {
      document.title = "Create Park Admin";
    }
    switchSkin(skin);
  }, [skin]);

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  //function for toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleUserNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setUserName(value);
      const error = validateUserName(value);
      setUserNameError(error);
    }
  };

  // Function to validate user name

  const validateUserName = (value) => {
    if (!value.trim()) {
      return "Username is required";
    }
    if (!/^[a-zA-Z0-9]*$/.test(value)) {
      return "Username must contain only alphanumeric characters";
    }
    if (/^\d/.test(value)) {
      return "Username should not start with a number";
    }

    if (value.length > 100) {
      return "Username must not exceed 100 characters";
    }

    return "";
  };

  // Event handler for password change
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    if (value.length <= 20) {
      setPassword(value);
      const error = validatePassword(value);
      setPasswordError(error);
      if (confirmPassword && value !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  // Function to validate password
  const validatePassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@$&])/.test(value)) {
      return "Password must contain at least one uppercase letter, one lowercase letter, one numeric digit, and one special character (@, $, !, & etc.)";
    }

    if (value.length <= 8) {
      return "Password length must be greater than 8 characters";
    }
    if (value.length > 20) {
      return "Password must not exceed 20 characters";
    }

    return "";
  };
  const validateConfirmPassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    if (value !== password) {
      return "Passwords do not match";
    }

    return "";
  };

  const handleConfirmPassword = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    const error = validateConfirmPassword(value);
    setConfirmPasswordError(error);
  };

  // Event handler for contact name change
  const handleContactNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setContactName(value);
      const error = validateContactName(value);
      setContactNameError(error);
    }
  };

  // Function to validate contact name
  const validateContactName = (value) => {
    if (!value.trim()) {
      return "Contact name is required";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Contact name must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return "Contact name must not exceed 100 characters";
    }
    return "";
  };

  // Event handler for mobile number change
  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    if (value.length <= 14) {
      setMobileNumber(value);
      const error = validateMobileNumber(value);
      setMobileNumberError(error);
    }
  };

  const validateMobileNumber = (value) => {
    // Check if the value is empty
    if (!value.trim()) {
      return "Contact number is required";
    }
    const numericRegex = /^[0-9]+$/;

    const noConsecutiveSameDigitRegex = /(.)\1{9}/;

    if (!numericRegex.test(value)) {
      return "Contact number must contain only numeric characters";
    }
    if (noConsecutiveSameDigitRegex.test(value)) {
      return "Continuous sequence of 10 same numbers is not allowed";
    }

    if (value.length > 14) {
      return "Contact number must not exceed 14 characters";
    }
    if (value.length < 10) {
      return "Contact number must contain at least 10 numeric characters";
    }

    return "";
  };

  // Event handler for email change
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    const error = validateEmail(value);
    setEmailError(error);
  };

  // Function to validate email
  const validateEmail = (value) => {
    if (!value.trim()) {
      return "Email id is required";
    }
    // // Regular expression for email validation
    const emailRegex =
      /^(?!.*\.\.)(?!.*\.[0-9])[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;

    // Check if the value matches the email regex
    if (!emailRegex.test(value)) {
      return "Please enter a valid email id";
    }
    return "";
  };

  // Event handler for alternate email change
  const handleAlternateEmailChange = (event) => {
    const value = event.target.value;
    setAlternateEmail(value);
    const error = validateAlternateEmail(value);
    setAlternateEmailError(error);
  };

  // Function to validate alternate email
  const validateAlternateEmail = (value) => {
    // Check if the value is empty

    if (value == null || value.trim() === "") {
      return ""; // Return empty string if value is empty, null, or undefined
    }
    // // Regular expression for email validation
    const emailRegex =
      /^(?!.*\.\.)(?!.*\.[0-9])[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;

    // Check if the value matches the email regex
    if (!emailRegex.test(value)) {
      return "Please enter a valid alternate email id";
    }
    return "";
  };

  const handleITParkChange = (selectedOption) => {
    setITPark(selectedOption);
    const error = validateITPark(selectedOption);
    setITParkError(error);
  };

  const options = Array.isArray(itparkList)
    ? itparkList.map((item) => ({ value: item.id, label: item.parkName }))
    : [];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minWidth: "320px",
    }),
    menu: (provided) => ({
      ...provided,
      wordWrap: "break-word",
    }),
    option: (provided) => ({
      ...provided,
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    }),
  };

  // Function to validate IT Park
  const validateITPark = (selectedOption) => {
    if (!selectedOption || !selectedOption.value) {
      return "IT park is required";
    }

    // Check if selectedOption.value is a string, then trim it
    if (
      typeof selectedOption.value === "string" &&
      selectedOption.value.trim() === ""
    ) {
      return "IT park is required";
    }

    return "";
  };

  //submit handler
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!parkAdminId) {
      // Validate all fields
      const userNameError = validateUserName(username);
      const passwordError = validatePassword(password);
      const confirmPasswordError = validateConfirmPassword(confirmPassword);
      const emailError = validateEmail(email);
      const mobilenumberError = validateMobileNumber(mobilenumber);
      const contactnameError = validateContactName(contactname);
      const alternateemailError = validateAlternateEmail(alternateemail);
      const itparkError = validateITPark(itpark);

      // Set errors for each field
      setUserNameError(userNameError);
      setPasswordError(passwordError);
      setConfirmPasswordError(confirmPasswordError);
      setContactNameError(contactnameError);
      setMobileNumberError(mobilenumberError);
      setEmailError(emailError);
      setAlternateEmailError(alternateemailError);
      setITParkError(itparkError);

      if (
        userNameError ||
        passwordError ||
        confirmPasswordError ||
        contactnameError ||
        mobilenumberError ||
        emailError ||
        alternateemailError ||
        itparkError
      ) {
        setLoading(false);
        return;
      } else {
        try {
          setLoading(true);

          const formData = {
            userName: username,
            password: password,
            contactName: contactname,
            email: email,
            mobileNumber: mobilenumber,
            itPark: itpark.value,
          };

          // Conditionally add alternate email if it's not empty
          if (alternateemail.trim()) {
            formData.alternate_email = alternateemail;
          }
          const response = await createParkAdmin(formData);
          setLoading(false);
          showToast("success", response.data.message);
          navigate("/dashboard/park-details");
          // Reset form values
          setUserName("");
          setPassword("");
          setConfirmPassword("");
          setContactName("");
          setEmail("");
          setAlternateEmail("");
          setMobileNumber("");
          setITPark("");
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.data.message) {
              showToast("error", error.response.data.message);
            } else if (error.response.status == 500) {
              showToast("error", error.response.statusText);
            }
          } else {
            showToast("error", "Internal Server Error");
          }
        }
      }
    } else {
      // Validate all fields

      const emailError = validateEmail(email);
      const mobilenumberError = validateMobileNumber(mobilenumber);
      const contactnameError = validateContactName(contactname);
      const alternateemailError = validateAlternateEmail(alternateemail);
      const itparkError = validateITPark(itpark);

      setContactNameError(contactnameError);
      setMobileNumberError(mobilenumberError);
      setEmailError(emailError);
      setAlternateEmailError(alternateemailError);
      setITParkError(itparkError);

      if (
        contactnameError ||
        mobilenumberError ||
        emailError ||
        alternateemailError ||
        itparkError
      ) {
        setLoading(false);
        return;
      } else {
        try {
          setLoading(true);

          const formData = {
            contactName: contactname,
            email: email,
            mobileNumber: mobilenumber,
            itPark: itpark.value,
          };

          // Conditionally add alternate email if it's not empty and not null/undefined
          if (alternateemail && alternateemail.trim()) {
            formData.alternate_email = alternateemail.trim();
          }
          const response = await updateParkAdmin(parkAdminId, formData);
          setLoading(false);
          showToast("success", response.data.message);
          navigate("/dashboard/park-details");
          // Reset form values

          setContactName("");
          setEmail("");
          setAlternateEmail("");
          setMobileNumber("");
          setITPark("");
        } catch (error) {
          setLoading(false);
          if (error.response) {
            if (error.response.data.message) {
              showToast("error", error.response.data.message);
            } else if (error.response.status == 500) {
              showToast("error", error.response.statusText);
            }
          } else {
            showToast("error", "Internal Server Error");
          }
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app d-flex flex-column align-items-start p-3 p-lg-4">
        <div className="my-3 ps-md-3 align-self-start">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line "
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/park-details">
                    <span>Go back</span>
                  </Link>
                </li>
              </ol>
              {parkAdminId ? (
                <h4 className="main-title mb-0">Edit Park Admin</h4>
              ) : (
                <h4 className="main-title mb-0">Create Park Admin</h4>
              )}
            </div>
          </div>
        </div>

        <Col xs="12" md="12">
          <hr className="mb-5" />
          <Card.Body className="ps-md-3 " style={{ maxWidth: "960px" }}>
            <Form onSubmit={handleSubmit}>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-column flex-md-row gap-md-5 gap-4 ">
                  <div className="d-flex flex-column gap-2">
                    {!parkAdminId && (
                      <Card.Title as="h6">
                        <b>Park Admin Credentials</b>
                      </Card.Title>
                    )}
                    {!parkAdminId && (
                      <div style={{ maxWidth: "320px" }} className="text-muted">
                        Create credentials for park admin to login to their
                        platform. Kindly ensure the security of your chosen
                        username and password.
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                    {!parkAdminId && (
                      <div style={{ minWidth: "320px" }}>
                        <Form.Group>
                          <Form.Label>
                            IT Park<span className="text-danger">*</span>
                          </Form.Label>
                          <Select
                            value={itpark}
                            onChange={handleITParkChange}
                            options={options}
                            styles={customStyles}
                            placeholder="Select IT Park"
                            isLoading={loading}
                            noOptionsMessage={() => "No Data Found"}
                          />
                          {itparkError && (
                            <div className="text-danger">{itparkError}</div>
                          )}
                        </Form.Group>
                      </div>
                    )}
                    {!parkAdminId && (
                      <div style={{ minWidth: "320px" }}>
                        <Form.Label>
                          Username <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter username for park admin login"
                          value={username}
                          onChange={handleUserNameChange}
                        />
                        {usernameError && (
                          <div className="text-danger">{usernameError}</div>
                        )}
                      </div>
                    )}
                    {!parkAdminId && (
                      <div
                        style={{ minWidth: "320px" }}
                        className="d-flex flex-column"
                      >
                        <Form.Label>
                          Password <span className="text-danger">*</span>
                        </Form.Label>
                        <div>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter password for park admin login"
                            value={password}
                            onChange={handlePasswordChange}
                            ref={passwordRef}
                          />
                          {showPassword ? (
                            <RiEyeOffFill
                              className="eye-icon-gate"
                              onClick={togglePasswordVisibility}
                            />
                          ) : (
                            <RiEyeFill
                              className="eye-icon-gate"
                              onClick={togglePasswordVisibility}
                            />
                          )}
                        </div>

                        {passwordError && (
                          <div
                            className="text-danger mb-3"
                            style={{ marginTop: "-20px", maxWidth: "480px" }}
                          >
                            {passwordError}
                          </div>
                        )}
                      </div>
                    )}
                    {!parkAdminId && (
                      <div
                        style={{ minWidth: "320px", marginTop: "-18px" }}
                        className="d-flex flex-column "
                      >
                        <Form.Label>
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <div>
                          <Form.Control
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Re-enter password for park admin login"
                            value={confirmPassword}
                            ref={confirmPasswordRef}
                            onChange={handleConfirmPassword}
                          />
                          {showConfirmPassword ? (
                            <RiEyeOffFill
                              className="eye-icon-gate"
                              onClick={toggleConfirmPasswordVisibility}
                            />
                          ) : (
                            <RiEyeFill
                              className="eye-icon-gate"
                              onClick={toggleConfirmPasswordVisibility}
                            />
                          )}
                        </div>

                        {confirmPasswordError && (
                          <div
                            className="text-danger mb-3"
                            style={{ marginTop: "-20px", maxWidth: "480px" }}
                          >
                            {confirmPasswordError}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                  <div className="d-flex flex-column gap-2">
                    <Card.Title as="h6">
                      <b>Park Admin Contact Details</b>
                    </Card.Title>
                    <div style={{ maxWidth: "320px" }} className="text-muted">
                      Update park admin contact details for future
                      communications.
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                    {parkAdminId && (
                      <div style={{ minWidth: "320px" }}>
                        <Form.Group>
                          <Form.Label>
                            IT Park<span className="text-danger">*</span>
                          </Form.Label>
                          <Select
                            value={itpark}
                            onChange={handleITParkChange}
                            options={options}
                            styles={customStyles}
                            placeholder="Select IT Park"
                            isLoading={loading}
                            noOptionsMessage={() => "No Data Found"}
                          />
                          {itparkError && (
                            <div className="text-danger">{itparkError}</div>
                          )}
                        </Form.Group>
                      </div>
                    )}
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Contact Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter contact name for park admin"
                        value={contactname}
                        onChange={handleContactNameChange}
                      />
                      {contactnameError && (
                        <div className="text-danger">{contactnameError}</div>
                      )}
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Email ID <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter email id for park admin contact"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {emailError && (
                        <div className="text-danger">{emailError}</div>
                      )}
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>Alternate Email ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter alternate email id for park admin contact"
                        value={alternateemail}
                        onChange={handleAlternateEmailChange}
                      />
                      {alternateemailError && (
                        <div className="text-danger">{alternateemailError}</div>
                      )}
                    </div>

                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Contact Number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter contact number for park admin"
                        value={mobilenumber}
                        onChange={handleMobileNumberChange}
                      />
                      {mobilenumberError && (
                        <div className="text-danger">{mobilenumberError}</div>
                      )}
                    </div>

                    <div style={{ minWidth: "320px" }}>
                      {parkAdminId ? (
                        <Button
                          type="submit"
                          variant="primary"
                          className="btn-sign mt-4"
                          disabled={loading}
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Save"
                          )}
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="primary"
                          className="btn-sign mt-4"
                          disabled={loading}
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Create Park Admin"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Col>
      </div>
      <Footer />
    </React.Fragment>
  );
}
