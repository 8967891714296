//Create Gate.js
import React, { useEffect, useState, useRef } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Form, Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getITParkList } from "../../services/Park";
import {
  getITPhaseList,
  createGate,
  getGateDetails,
  updateGate,
} from "../../services/Phase";

import { showToast } from "../shared/Toaster";
import { useSelector } from "react-redux";

export default function CreateGate() {
  const role_name = localStorage.getItem("role_name");

  // State variables for fields
  const [gatenumber, setGateNumber] = useState("");
  const [gateusername, setGateUserName] = useState("");
  const [password, setPassword] = useState("");
  const [contactname, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobilenumber, setMobileNumber] = useState("");
  const phaseId = useSelector((state) => state.phase.phaseId);
  const parkId = useSelector((state) => state.park.parkId);

  const defaultITPark =
    role_name === "Super Admin" ? parkId : localStorage.getItem("userPark");
  const defaultPhase =
    role_name === "Super Admin" || role_name === "Park Admin"
      ? phaseId
      : localStorage.getItem("userPhase");

  const [itpark, setITPark] = useState(defaultITPark);
  const [phase, setPhase] = useState(defaultPhase);

  //state for storing the role
  const [roleName, setRoleName] = useState("");

  //for fetching the it park list and phase list :
  const [itparkList, setITParkList] = useState("");
  const [itphaseList, setITPhaseList] = useState("");
  const [loading, setLoading] = useState(false);

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  // State variables for error messages
  const [gatenumberError, setGateNumberError] = useState("");
  const [phaseError, setPhaseError] = useState("");
  const [itparkError, setITParkError] = useState("");
  const [gateusernameError, setGateUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [contactnameError, setContactNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [mobilenumberError, setMobileNumberError] = useState("");

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const gateId = query.get("id");

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (role_name === "Phase Admin") {
      navigate("/dashboard/gate-list"); // Navigate to the storage page
    } else {
      navigate("/dashboard/gate-list/?gen"); // Navigate to the storage page
    }
  };

  useEffect(() => {
    if (!gateId) {
      document.title = "Create Gate";
    } else {
      document.title = "Edit Gate";
    }
    const role_name = localStorage.getItem("role_name");

    if (role_name == "Super Admin") {
      setRoleName("Super Admin");
    } else if (role_name == "Park Admin") {
      setRoleName("Park Admin");
    } else if (role_name == "Phase Admin") {
      setRoleName("Phase Admin");
    }
    const fetchITParkList = async () => {
      if (role_name == "Super Admin") {
        try {
          const response = await getITParkList(); // Assuming getITParkList is an async function that fetches data
          setITParkList(response.data.data.park_list.results); // Store the data in state
        } catch (error) {
        }
      } else {
        const data = [
          {
            id: localStorage.getItem("userPark"),
            parkName: localStorage.getItem("userParkName"),
          },
        ];
        setITParkList(data);
      }
    };

    const fetchITPhaseList = async (park_id) => {
      if (role_name == "Phase Admin") {
        const data = [
          {
            id: localStorage.getItem("userPhase"),
            PhaseName: localStorage.getItem("userPhaseName"),
            IT_PARK: localStorage.getItem("userParkName"),
            IT_PARK_Id: localStorage.getItem("userPark"),
          },
        ]; //Mock data for park
        setITPhaseList(data);
      } else {
        try {
          const response = await getITPhaseList(park_id); // Assuming getITParkList is an async function that fetches data
          setITPhaseList(response.data.data.phase_list.results); // Store the data in state
        } catch (error) {
        }
      }
    };

    fetchITParkList();
    fetchITPhaseList(itpark);
    switchSkin(skin);
  }, [skin, itpark]);

  //For getting the gate details in the case of edit gate
  useEffect(() => {
    const fetchGateDetails = async () => {
      try {
        const response = await getGateDetails(gateId);
        if (response) {
          setContactName(response.data.data.first_name);
          setEmail(response.data.data.email);
          setMobileNumber(response.data.data.phonenumber);
          setGateNumber(response.data.data.gate_name);
        }
      } catch (error) {
      }
    };
    fetchGateDetails();
  }, [gateId]);

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  // Event handler for gate number change
  const handleGateNumberChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setGateNumber(value);
      const error = validateGateNumber(value);
      setGateNumberError(error);
    }
  };

  const handleITParkChange = async (event) => {
    const value = event.target.value;
    setITPark(value);
    const error = validateITPark(value);
    setITParkError(error);

    if (!error) {
      try {
        const response = await getITPhaseList(value); // Assuming getITPhaseList fetches phase data for the selected park
        if (response.data.data.phase_list.results.length > 0) {
          setITPhaseList(response.data.data.phase_list.results);
        } else {
          setITPhaseList(undefined);
        }
        setPhase("");
      } catch (error) {
        setITPhaseList(undefined);

        setPhase("");
      }
    }
  };

  // Event handler for phase change
  const handlePhaseChange = (event) => {
    const value = event.target.value;
    setPhase(value);
    const error = validatePhase(value);
    setPhaseError(error);
  };

  // Event handler for Gate username
  const handleGateUserNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setGateUserName(value);
      const error = validateGateUserName(value);
      setGateUserNameError(error);
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    if (value.length <= 20) {
      setPassword(value);
      const error = validatePassword(value);
      setPasswordError(error);
      if (confirmPassword && value !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handleConfirmPassword = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    const error = validateConfirmPassword(value);
    setConfirmPasswordError(error);
  };

  // Event handler for contact name change
  const handleContactNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setContactName(value);
      const error = validateContactName(value);
      setContactNameError(error);
    }
  };

  // Event handler for email change
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    const error = validateEmail(value);
    setEmailError(error);
  };

  // Function to validate gate number
  const validateGateNumber = (value) => {
    if (!value.trim()) {
      return "Gate name/number is required";
    }
    if (!/^[^\W_](?:[\w\s]*[\W_]?[\w\s]*)*$/.test(value)) {
      return "Gate name/number should not start with a special character";
    }

    return "";
  };

  // Function to validate IT Park
  const validateITPark = (value) => {
    if (!value || value === "undefined") {
      return "IT Park is required";
    }
    return "";
  };

  // Function to validate Phase
  const validatePhase = (value) => {
    if (!value || value === "undefined") {
      return "Phase is required";
    }
    return "";
  };
  // Function to validate gate user name

  const validateGateUserName = (value) => {
    if (!value.trim()) {
      return "Gate username is required";
    }
    if (!/^[a-zA-Z0-9]*$/.test(value)) {
      return "Gate username must contain only alphanumeric characters";
    }
    if (/^\d/.test(value)) {
      return "Gate username should not start with a number";
    }
    if (value.length > 100) {
      return "Gate username must not exceed 100 characters";
    }

    return "";
  };

  // Function to validate password
  const validatePassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@$&])/.test(value)) {
      return "Password must contain at least one uppercase letter, one lowercase letter, one numeric digit, and one special character (@, $, !, & etc.)";
    }

    if (value.length <= 8) {
      return "Password length must be greater than 8 characters";
    }
    if (value.length > 20) {
      return "Password  must not exceed 20 characters";
    }

    return "";
  };
  const validateConfirmPassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    if (value !== password) {
      return "Passwords do not match";
    }

    return "";
  };

  // Function to validate contact name
  const validateContactName = (value) => {
    if (!value.trim()) {
      return "Contact name is required";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Contact name must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return " Contact name must not exceed 100 characters";
    }
    return "";
  };

  // Function to validate mobile number
  const validateEmail = (value) => {
    if (!value.trim()) {
      return "Email id is required";
    }
    // const emailRegex = /^(?!.*\.\.)(?!.*\.\.@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailRegex =
      /^(?!.*\.\.)(?!.*\.[0-9])[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;

    // Check if the value matches the email regex
    if (!emailRegex.test(value)) {
      return "Please enter a valid email id";
    }
    return "";
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    if (value.length <= 14) {
      setMobileNumber(value);
      const error = validateMobileNumber(value);
      setMobileNumberError(error);
    }
  };

  // Function to validate mobile number
  const validateMobileNumber = (value) => {
    // Check if the value is empty
    if (!value.trim()) {
      return "Contact number is required";
    }
    const numericRegex = /^[0-9]+$/;
    const noConsecutiveSameDigitRegex = /(.)\1{9}/;

    if (!numericRegex.test(value)) {
      return "Contact number must contain only numeric characters";
    }
    if (noConsecutiveSameDigitRegex.test(value)) {
      return "Continuous sequence of 10 same numbers is not allowed";
    }

    if (value.length > 14) {
      return "Contact number must not exceed 14 characters";
    }
    if (value.length < 10) {
      return "Contact number must contain at least 10 numeric characters";
    }

    return "";
  };
  // Submit handler
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!gateId) {
      // Validate all fields
      const gatenumberError = validateGateNumber(gatenumber);
      const itparkError = validateITPark(itpark);
      const phaseError = validatePhase(phase);
      const gateusernameError = validateGateUserName(gateusername);
      const contactnameError = validateContactName(contactname);
      const emailError = validateEmail(email);
      const passwordError = validatePassword(password);
      const confirmPasswordError = validateConfirmPassword(confirmPassword);
      const mobilenumberError = validateMobileNumber(mobilenumber);

      // Set errors for each field
      setGateNumberError(gatenumberError);
      setITParkError(itparkError);
      setPhaseError(phaseError);
      setGateUserNameError(gateusernameError);
      setContactNameError(contactnameError);
      setEmailError(emailError);
      setPasswordError(passwordError);
      setConfirmPasswordError(confirmPasswordError);
      setMobileNumberError(mobilenumberError);

      if (
        itparkError ||
        phaseError ||
        gatenumberError ||
        gateusernameError ||
        contactnameError ||
        emailError ||
        passwordError ||
        mobilenumberError ||
        confirmPasswordError
      ) {
        return;
      } else {
        setLoading(true);
        try {
          const formData = {
            gateName: gatenumber,
            phase: phase,
            username: gateusername,
            password: password,
            contactName: contactname,
            email: email,
            mobileNumber: mobilenumber,
          };

          const response = await createGate(formData);
          setLoading(false);
          setGateNumber("");
          setPhase("");
          setITPark("");
          setGateUserName("");
          setPassword("");
          setConfirmPassword("");
          setContactName("");
          setEmail("");
          setMobileNumber("");

          if (roleName === "Super Admin") {
            setITPark("");
          }

          showToast("success", response.data.message);
          if (role_name === "Phase Admin") {
            navigate("/dashboard/gate-list"); // Navigate to the storage page
          } else {
            navigate("/dashboard/gate-list/?gen"); // Navigate to the storage page
          }
        } catch (error) {
          // Handle submission error
          console.log(error);
          setLoading(false);
          if (error.response) {
            // if(error.reponse.data.message) {
            showToast("error", error.response.data.message);
            // }
          } else {
            showToast("error", "Internal server Error");
          }
        }
      }
    } else {
      // Validate all fields
      const gatenumberError = validateGateNumber(gatenumber);
      const itparkError = validateITPark(itpark);
      const phaseError = validatePhase(phase);
      const contactnameError = validateContactName(contactname);
      const emailError = validateEmail(email);
      const mobilenumberError = validateMobileNumber(mobilenumber);

      // Set errors for each field
      setGateNumberError(gatenumberError);
      setITParkError(itparkError);
      setPhaseError(phaseError);
      setContactNameError(contactnameError);
      setEmailError(emailError);
      setMobileNumberError(mobilenumberError);

      if (
        itparkError ||
        phaseError ||
        gatenumberError ||
        contactnameError ||
        emailError ||
        mobilenumberError
      ) {
        return;
      } else {
        setLoading(true);
        try {
          const formData = {
            gateName: gatenumber,
            contactName: contactname,
            email: email,
            mobileNumber: mobilenumber,
          };

          const response = await updateGate(gateId, formData);
          setLoading(false);
          setGateNumber("");
          setPhase("");
          setITPark("");
          setContactName("");
          setEmail("");
          setMobileNumber("");

          if (roleName === "Super Admin") {
            setITPark("");
          }

          showToast("success", response.data.message);
          if (role_name === "Phase Admin") {
            navigate("/dashboard/gate-list"); // Navigate to the storage page
          } else {
            navigate("/dashboard/gate-list/?gen"); // Navigate to the storage page
          }
        } catch (error) {
          // Handle submission error
          console.log(error);
          setLoading(false);
          if (error.response) {
            showToast("error", error.response.data.message);
            // }
          } else {
            showToast("error", "Internal server Error");
          }
        }
      }
    }
  };

  return (
    <div>
      <Header onSkin={setSkin} />
      <div className=" main main-app d-flex flex-column align-items-start p-3 p-lg-4">
        <div className="my-3 ps-md-3 align-self-start">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line "
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  {role_name === "Phase Admin" ? (
                    <Link to="/dashboard/gate-list">
                      <span>Go back</span>
                    </Link>
                  ) : (
                    <Link to="/dashboard/gate-list/?gen">
                      <span>Go back</span>
                    </Link>
                  )}
                </li>
              </ol>
              {gateId ? (
                <h4 className="main-title mb-0">Edit Gate</h4>
              ) : (
                <h4 className="main-title mb-0">Create Gate</h4>
              )}
            </div>
          </div>
        </div>

        <Col xs="12" md="12">
          <hr className="mb-5" />

          <Card.Body className="ps-md-3 " style={{ maxWidth: "960px" }}>
            <Form onSubmit={handleSubmit}>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                  {!gateId && (
                    <div className="d-flex flex-column gap-2">
                      <Card.Title as="h6">
                        <b>Gate Credentials</b>
                      </Card.Title>
                      <div style={{ maxWidth: "320px" }} className="text-muted">
                        Create credentials for gate security to log into their
                        platform. Kindly ensure the security of your chosen
                        username and password.
                      </div>
                    </div>
                  )}
                  {!gateId && (
                    <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                      <div style={{ minWidth: "320px" }}>
                        <Form.Label>
                          Gate Name/Number{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter reference id for the gate"
                          value={gatenumber}
                          onChange={handleGateNumberChange}
                        />
                        {gatenumberError && (
                          <div className="text-danger">{gatenumberError}</div>
                        )}
                      </div>
                      <div style={{ minWidth: "320px" }}>
                        <Form.Group>
                          <Form.Label>
                            IT Park<span className="text-danger"></span>
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            as="select"
                            value={itpark}
                            onChange={handleITParkChange}
                            disabled={
                              roleName === "Phase Admin" ||
                              roleName === "Park Admin"
                            }
                          >
                            <option value="">Select IT Park</option>
                            {itparkList === undefined && (
                              <option value="" disabled>
                                No Data Found
                              </option>
                            )}
                            {Array.isArray(itparkList) &&
                              itparkList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.parkName}
                                </option>
                              ))}
                          </Form.Select>

                          {itparkError && (
                            <div className="text-danger">{itparkError}</div>
                          )}
                        </Form.Group>
                      </div>
                      <div style={{ minWidth: "320px" }}>
                        <Form.Group>
                          <Form.Label>
                            Phase<span className="text-danger"></span>
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            as="select"
                            value={phase}
                            onChange={handlePhaseChange}
                            disabled={roleName === "Phase Admin"}
                          >
                            <option value="">Select Phase</option>
                            {itphaseList === undefined && (
                              <option value="" disabled>
                                No Data Found
                              </option>
                            )}
                            {Array.isArray(itphaseList) &&
                              itphaseList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.PhaseName}
                                </option>
                              ))}
                          </Form.Select>

                          {phaseError && (
                            <div className="text-danger">{phaseError}</div>
                          )}
                        </Form.Group>
                      </div>
                      <div style={{ minWidth: "320px" }}>
                        <Form.Label>
                          Gate Username <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter username for gate login"
                          value={gateusername}
                          onChange={handleGateUserNameChange}
                        />
                        {gateusernameError && (
                          <div className="text-danger">{gateusernameError}</div>
                        )}
                      </div>
                      <div style={{ minWidth: "320px" }}>
                        <Form.Label>
                          Password <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter password for gate login"
                          value={password}
                          onChange={handlePasswordChange}
                          ref={passwordRef}
                        />
                        {showPassword ? (
                          <RiEyeOffFill
                            className="eye-icon-gate"
                            onClick={togglePasswordVisibility}
                          />
                        ) : (
                          <RiEyeFill
                            className="eye-icon-gate"
                            onClick={togglePasswordVisibility}
                          />
                        )}

                        {passwordError && (
                          <div
                            className="text-danger mb-3"
                            style={{ marginTop: "-20px", maxWidth: "480px" }}
                          >
                            {passwordError}
                          </div>
                        )}
                      </div>
                      <div style={{ minWidth: "320px", marginTop: "-18px" }}>
                        <Form.Label>
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Re-enter the password for gate login"
                          value={confirmPassword}
                          onChange={handleConfirmPassword}
                          ref={confirmPasswordRef}
                        />
                        {showConfirmPassword ? (
                          <RiEyeOffFill
                            className="eye-icon-gate"
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        ) : (
                          <RiEyeFill
                            className="eye-icon-gate"
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        )}

                        {confirmPasswordError && (
                          <div
                            className="text-danger mb-3"
                            style={{ marginTop: "-20px", maxWidth: "480px" }}
                          >
                            {confirmPasswordError}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                  <div className="d-flex flex-column gap-2">
                    <Card.Title as="h6">
                      <b>Contact Details</b>
                    </Card.Title>
                    <div style={{ maxWidth: "320px" }} className="text-muted">
                      Update gate security contact details for future
                      communication.
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                    {gateId && (
                      <div style={{ minWidth: "320px" }}>
                        <Form.Label>
                          Gate Name/Number{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter reference id for the gate"
                          value={gatenumber}
                          onChange={handleGateNumberChange}
                        />
                        {gatenumberError && (
                          <div className="text-danger">{gatenumberError}</div>
                        )}
                      </div>
                    )}
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Contact Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter contact name for the gate"
                        value={contactname}
                        onChange={handleContactNameChange}
                      />
                      {contactnameError && (
                        <div className="text-danger">{contactnameError}</div>
                      )}
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Email ID <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter email id of the gate contact"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {emailError && (
                        <div className="text-danger">{emailError}</div>
                      )}
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Label>
                        Contact Number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter contact number for park admin"
                        value={mobilenumber}
                        onChange={handleMobileNumberChange}
                      />
                      {mobilenumberError && (
                        <div className="text-danger">{mobilenumberError}</div>
                      )}
                    </div>

                    <div style={{ minWidth: "320px" }}>
                      {gateId ? (
                        <Button
                          type="submit"
                          variant="primary"
                          className="btn-sign mt-4"
                          disabled={loading}
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Save"
                          )}
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="primary"
                          className="btn-sign mt-4"
                          disabled={loading}
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Create Gate"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Col>
      </div>
      <Footer />
    </div>
  );
}
