import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const HistoryModal = ({ history, onClose }) => {
  return (
    <div className="overlay">
      <div className="history-modal">
        <Modal show={true} onHide={onClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-primary">Visitor History</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '400px', minHeight: '380px', overflowY: 'auto' }}>
            <div className="d-flex flex-column gap-1">
              {history && history.length > 0 ? (
                <div style={{ fontSize: "0.8rem" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Entry Date & Time</th>
                        <th>Entry Gate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {history.map((entry, index) => (
                        <tr key={index}>
                          <td>{entry.entry_datetime}</td>
                          <td>{entry.entry_gate}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-muted">No history available</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default HistoryModal;
