// UserList.js
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import ParkCard from "../super-admin/ParkCard";
import { getCompanyUserList } from "../../services/Company";
import { useParams } from "react-router-dom";
import { getCompanyAddress } from "../../services/Company";
import { useLocation } from "react-router-dom";


export default function UserList() {
  const navigate = useNavigate();
  const role_name = localStorage.getItem("role_name");
  const companyId = localStorage.getItem("userCompany");

  const [role, setRole] = useState("");

  const handleGoBack = () => {
    navigate("/dashboard/generic"); // Navigate to the dashboard page
  };

  const location = useLocation();

  
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [userlist, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Page size of 6
  const [totalItems, setTotalItems] = useState(0);
  const [displayedPages, setDisplayedPages] = useState([]);
  const [active, setActive] = useState(1);


  const fetchCompanyList = async (companyId) => {
    try {
      setLoading(true);
      const response = await getCompanyUserList(
        companyId,
        currentPage,
        pageSize,
        active
      );
      const userListData = response.data.data.users_list.results.map(
        (userItem) => ({
          id: userItem.id,
          name: `${userItem.user.first_name} ${userItem.user.last_name}`,
          email: userItem.user.email,
          phonenumber: userItem.user.phonenumber,
          Location: userItem.company.name,
          count: {
            pass_generated: userItem.count.pass_generated,
            visited: userItem.count.visited,
          },
          address: userItem.company.building.address,
          role: userItem.role,
		      active:active
        })
      );

      setUserList(userListData);
      setTotalItems(response.data.data.users_list.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setUserList("");
    }
  };

  useEffect(() => {
    const role = localStorage.getItem("role_name");
    setRole(role);

    if (role === "Company Admin" || role === "Company User") {
      const companyId = localStorage.getItem("userCompany");
      fetchCompanyList(companyId);
    } else {
      const companyId = localStorage.getItem("userCompany");
      if (companyId) {
        fetchCompanyList(companyId);
      }
    }

    document.title = "Company Users List";
    switchSkin(skin);
  }, [skin, currentPage, pageSize, active]);

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setActive(newValue);
    setCurrentPage(1);
  };

  const handleDeleteSuccess = () => {
    // Update user list after successful deletion
		const companyId = localStorage.getItem("userCompany");
		fetchCompanyList(companyId);
  };

  useEffect(() => {
    updateDisplayedPages();
  }, [currentPage, totalItems, active]);

  // Function to handle page change
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updateDisplayedPages = () => {
    const totalPages = Math.ceil(totalItems / pageSize);
    const maxDisplayedPages = 3;
    const halfRange = Math.floor(maxDisplayedPages / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

    if (endPage - startPage < maxDisplayedPages - 1) {
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    setDisplayedPages(pages);
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line "
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/generic">
                    <span>Go back</span>
                  </Link>
                </li>
              </ol>
              <h4 className="main-title mb-0">Company Users List</h4>
            </div>
          </div>
          <div className="d-flex  gap-2 mt-3 mt-md-0 flex-wrap">
            <div
              className="form-check form-switch"
              style={{
                marginRight: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label
                htmlFor="flexSwitchCheckDefault"
                style={{ marginRight: "50px" }}
              >
                {active === 1 ? "Active" : "Inactive"}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                name="active"
                value={active}
                checked={active === 1}
                onChange={handleCheckboxChange}
                style={{ width: "50px", height: "25px" }} // Adjust size as needed
              />
            </div>

            {role_name !== "Company User" && (
              <Button
                as={Link}
                to="/dashboard/create-company-user"
                variant="primary"
                className="d-flex align-items-center gap-md-2 gap-1"
                style={{ minWidth: "fit-content" }}
              >
                <i className="ri-bar-chart-2-line fs-md-18 lh-1"></i>
                <span>Create Company User</span>
              </Button>
            )}
          </div>
        </div>
        {/* Render UserCard components for each user */}
        <Row xs={1} md={2} lg={2} xl={3} className="g-4">
          {loading ? (
            <div style={{ textAlign: "center", width: "100%" }}>
              <span
                className="spinner-border d-flex justify-content-center spinner-border-lg text-primary"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : userlist?.length > 0 ? (
            userlist?.map((user, index) => (
              <Col key={index}>
                <ParkCard cardKey={`userlist`} cardValue={user} onDeleteSuccess={ handleDeleteSuccess } />
              </Col>
            ))
          ) : (
            <p style={{ textAlign: "center" }}>No Data Found</p>
          )}
        </Row>
        <div>
          {!loading && userlist?.length > 0 && (
            <Pagination className="pagination-space justify-content-start pagination-filled  p-3 p-lg-4">
              <Pagination.Prev
                onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {displayedPages.map((number) => (
                <Pagination.Item
                  key={number}
                  active={number === currentPage}
                  onClick={() => onPageChange(number)}
                >
                  {number}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  currentPage < totalPages && onPageChange(currentPage + 1)
                }
                disabled={currentPage === totalPages}
              />
            </Pagination>
          )}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
