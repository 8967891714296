import React, { useState, useRef, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/AuthContext";
import { showToast } from "../components/shared/Toaster";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";

export default function Signin() {
  useEffect(() => {
    document.title = "Entry Pass-Login";
  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false); // State variable to manage password visibility
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const { login } = useAuth();

  // Event handler for Username
  const handleUsernameChange = (event) => {
    const value = event.target.value;
    setApiError("");
    if (value.length <= 100) {
      setUsername(value);
      const error = validateUsername(value);
      setUsernameError(error);
    }
  };

  // Event handler for password change
  const handlePasswordChange = (event) => {
    setApiError("");
    const value = event.target.value;
    if (value.length <= 20) {
      setPassword(value);
      const error = validatePassword(value);
      setPasswordError(error);
    }
  };

  const validateUsername = (value) => {
    if (!value.trim()) {
      return "Username is required";
    }

    return "";
  };

  const validatePassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    return "";
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const usernameError = validateUsername(username);
    const passwordError = validatePassword(password);
    setUsernameError(usernameError);
    setPasswordError(passwordError);

    if (usernameError || passwordError) {
      setLoading(false);
      return;
    } else {
      try {
        const user = await login({ username, password });
        if(user.data.data.user_type != 2) {
          localStorage.setItem("role_name", user.data.data.roles.role_name);
        }else if (user.data.data.roles.reset_password === true && user.data.data.user_type === 2) {
          localStorage.setItem("role_name", "Gate User");
        }
        localStorage.setItem("username", user.data.data.username);
        localStorage.setItem("token", user.data.data.access_token);
        localStorage.setItem("userPark", user.data.data.park_id);
        localStorage.setItem("userParkName", user.data.data.park_name);
        localStorage.setItem("userPhase", user.data.data.phase_id);
        localStorage.setItem("userCompany", user.data.data.company_id);
        localStorage.setItem("userPhaseName", user.data.data.phase_name);
        localStorage.setItem("companyName", user.data.data.company_name);
        localStorage.setItem("companyId", user.data.data.company_id);
        localStorage.setItem("name", user.data.data.name);

        localStorage.setItem(
          "reset_password",
          user.data.data.roles.reset_password
        );
        setLoading(false);
      
        if (user.data.data.roles.reset_password === true && user.data.data.user_type != 2) {
          navigate("/dashboard/generic");
          showToast("success", user.data.message);
        }else if (user.data.data.roles.reset_password === true && user.data.data.user_type === 2) {
			    navigate("/dashboard/gate-user");
          showToast("success", user.data.message);
		}
		else {
          navigate("/pages/reset");
        }
      } catch (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.data.message) {
            setApiError(error.response.data.message);
          } else if (error.response.status == 500) {
            showToast("error", error.response.statusText);
          }
        } else {
          showToast("error", "Internal Server Error");
        }
      }
    }
  };

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">
            Entry Pass
          </Link>
          <Card.Title>Login</Card.Title>
          <Card.Text>Welcome ! Please login to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label>
                Username<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={username}
                onChange={handleUsernameChange}
                ref={usernameRef}
              />
              {usernameError && (
                <div className="text-danger">{usernameError}</div>
              )}
            </div>
            <div className="mb-2">
              <Form.Label>
                Password
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={password}
                onChange={handlePasswordChange}
                ref={passwordRef}
              />
              {/* Eye icon to toggle password visibility */}
              {showPassword ? (
                <RiEyeOffFill
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <RiEyeFill
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              )}
              {passwordError && (
                <div className="text-danger password-error">
                  {passwordError}
                </div>
              )}
            </div>
            {apiError && (
              <div className="text-danger api-error">{apiError}</div>
            )}
            <div className="mb-4">
              <Link to="/pages/forgot">Forgot password?</Link>
            </div>
            {loading && (
              <Button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Login</span>
              </Button>
            )}
            {!loading && (
              <Button type="submit" variant="primary" className="btn-sign">
                Login
              </Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
