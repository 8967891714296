// BuildingList.js
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ParkCard from "../super-admin/ParkCard";
import { useSelector } from "react-redux";
import { getGateList } from "../../services/Phase";

export default function GateList() {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [gateList, setGateList] = useState([]);
  const [loading, setLoading] = useState(false);
  const phaseIdFromRedux = useSelector((state) => state.phase.phaseId);
  const [totalItems, setTotalItems] = useState(0);
  const [active, setActive] = useState(1);

  useEffect(() => {
    const role = localStorage.getItem("role_name");

    if (role == "Super Admin") {
      setRole("Super Admin");
    } else if (role == "Park Admin") {
      setRole("Park Admin");
    } else if (role == "Phase Admin") {
      setRole("Phase Admin");
    }
  });

  const handleGoBack = () => {
    if (role == "Super Admin") {
      navigate("/dashboard/phase-details/?gen"); // Navigate to the dashboard page
    } else if (role === "Park Admin") {
      navigate("/dashboard/phase-details/?gen");
    } else {
      navigate("/dashboard/generic"); // Navigate to the dashboard page
    }
  };

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    document.title = "Gate List";

    fetchGateList();
    switchSkin(skin);
  }, [skin, phaseIdFromRedux, role, active]);

  const fetchGateList = async () => {
	try {
	  setLoading(true);
	  let PhaseId = phaseIdFromRedux;
	  if (role === "Phase Admin") {
		PhaseId = localStorage.getItem("userPhase");
	  }
	  const response = await getGateList(PhaseId, active);
	  setGateList(response.data.data);
	  setTotalItems(response.data.data.count);
	  setLoading(false);
	} catch (error) {
	  setLoading(false);
	}
  };

  const handleDeleteSuccess = () => {
    // Update user list after successful deletion
    const gateUserId = localStorage.getItem("userGate");
    fetchGateList(gateUserId);
  };

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setActive(newValue);
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line "
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  {role === "Super Admin" && (
                    <Link to="/dashboard/phase-details/?gen">
                      <span>Go back</span>
                    </Link>
                  )}
                  {role === "Park Admin" && (
                    <Link to="/dashboard/phase-details/?gen">
                      <span>Go back</span>
                    </Link>
                  )}
                  {role === "Phase Admin" && (
                    <Link to="/dashboard/generic">
                      <span>Go back</span>
                    </Link>
                  )}
                </li>
              </ol>
              <h4 className="main-title mb-0">Gate List</h4>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
		  <div
              className="form-check form-switch"
              style={{
                marginRight: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label
                htmlFor="flexSwitchCheckDefault"
                style={{ marginRight: "50px" }}
              >
                {active === 1 ? "Active" : "Inactive"}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                name="active"
                value={active}
                checked={active === 1}
                onChange={handleCheckboxChange}
                style={{ width: "50px", height: "25px" }} // Adjust size as needed
              />
            </div>
            <Button
              as={Link}
              to="/dashboard/create-gate"
              variant="primary"
              className="d-flex align-items-center gap-2"
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
              <span>Create Gate</span>
            </Button>
          </div>
        </div>

        {/* Render GateCard components for each phase */}
        <Row xs={1} md={2} lg={2} xl={3} className="g-4">
          {loading ? (
            <div style={{ textAlign: "center", width: "100%" }}>
              <span
                className="spinner-border d-flex justify-content-center spinner-border-lg text-primary"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : gateList?.length > 0 ? (
            gateList.map((gate, index) => (
              <Col key={index}>
                <ParkCard
                  cardKey={`gate`}
                  cardValue={gate}
                  onDeleteSuccess={handleDeleteSuccess}
                />
              </Col>
            ))
          ) : (
            <p style={{ textAlign: "center" }}>No Data Found</p>
          )}
        </Row>
      </div>
      <Footer />
    </React.Fragment>
  );
}
