import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Bar } from "react-chartjs-2";
import { Button, Card, Col, Row, Table, Form } from "react-bootstrap";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getITPhaseList } from "../services/Phase";
import { useDispatch } from "react-redux";
import { setPhaseId } from "../Redux/Actions";
import { setParkId } from "../Redux/Actions";
import { setCompanyId } from "../Redux/Actions";
import { setBuildingId } from "../Redux/Actions";
import { getBuildingList } from "../services/Phase";
import { setUserId } from "../Redux/Actions";
import {
  dashboardGraph,
  dashboardParkGraph,
  dashboardPhaseGraph,
  dashboardCompanyGraph,
  dashboardGraphWithYear,
} from "../services/dashboard";
import {
  getParkAdminList,
  getParkList,
  getParkListDropdown,
} from "../services/Park";
import { getPhaseDropdown, getBuildingDropdown } from "../services/Phase";
import { getCompanyUserActiveDropDown } from "../services/Company";
import { getCompanyUserListActive } from "../services/Company";
import { useSelector } from "react-redux";

export default function GenericDashboard() {
  const [roleName, setRoleName] = useState("");
  const [name, setName] = useState("");
  const [parkList, setParkList] = useState("");
  const [phaseList, setPhaseList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [logTotalCount, setLogTotalCount] = useState("");
  const [dropDown, setDropDown] = useState("");
  const [visitedCount, setVisitedCount] = useState("");
  const [notVisitedCount, setNotVisitedCount] = useState("");
  const [usersCount, setUsersCount] = useState("");

  //   const [itPark, setITPark] = useState("");
  const [itPark, setITPark] = useState("all");
  const [itPhase, setITPhase] = useState("all");
  const [building, setBuilding] = useState("all");
  const [user, setUser] = useState("all");

  const [shouldFetch, setShouldFetch] = useState(false);
  const [userlist, setUserList] = useState([]);
  const [role, setRole] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Page size of 6
  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [formattedEndDate, setFormattedEndDate] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Dashboard";
    const storedRoleName = localStorage.getItem("role_name");
    setName(localStorage.getItem("name"));
    if (storedRoleName) {
      // If role_name is found in local storage, set it in state
      setRoleName(storedRoleName);
    }
    if (storedRoleName === "Super Admin") {
      const fetchParkList = async () => {
        try {
          setLoading(true);
          const response = await getParkList(); // Assuming getITParkList is an async function that fetches data
          setParkList(response.data.data.park_list.results); // Store the data in state
          setLoading(false);
          setLogTotalCount(response.data.data.Total_count);
        } catch (error) {
          setLoading(false);
        }
      };
      // setITPark("123");
      const fetchParkListDropdown = async () => {
        try {
          setLoading(true);
          const response = await getParkListDropdown(); // Assuming getITParkList is an async function that fetches data
          setDropDown(response.data.data); // Store the data in state
        } catch (error) {
          setLoading(false);
        }
      };
      fetchParkListDropdown();
      fetchParkList();
    }
    const ParkId = localStorage.getItem("userPark");
    const PhaseId = localStorage.getItem("userPhase");

    if (storedRoleName === "Phase Admin") {
      const fetchBuildingList = async () => {
        try {
          setLoading(true);
          const response = await getBuildingList(
            PhaseId,
            currentPage,
            pageSize,
            1
          ); // Assuming getITParkList is an async function that fetches data
          setBuildingList(response.data.data.Building_list.results); // Store the data in state
          setLogTotalCount(response.data.data.Total_count);
          // setDropDown(response.data.data.Building_list.results); // Store the data in state

          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };
      const fetchBuildingListDropdown = async () => {
        try {
          setLoading(true);
          const response = await getBuildingDropdown(PhaseId); // Assuming getITParkList is an async function that fetches data
          setDropDown(response.data.data); // Store the data in state
        } catch (error) {
          setLoading(false);
        }
      };
      fetchBuildingListDropdown();
      fetchBuildingList();
    }
    if (storedRoleName === "Park Admin") {
      const fetchPhaseList = async () => {
        try {
          const response = await getITPhaseList(ParkId); // Assuming getITParkList is an async function that fetches data
          setPhaseList(response.data.data.phase_list.results); // Store the data in state
          setLogTotalCount(response.data.data.Total_count);
          // setDropDown(response.data.data.phase_list.results);
        } catch (error) {
        }
      };
      const fetchPhaseListDropdown = async () => {
        try {
          setLoading(true);
          const response = await getPhaseDropdown(ParkId); // Assuming getITParkList is an async function that fetches data
          setDropDown(response.data.data); // Store the data in state
        } catch (error) {
          setLoading(false);
        }
      };
      fetchPhaseListDropdown();
      fetchPhaseList();
    }
    if (
      storedRoleName === "Company Admin" ||
      storedRoleName === "Company User"
    ) {
      const companyId = localStorage.getItem("userCompany");
      const fetchCompanyList = async () => {
        try {
          setLoading(true);
          const response = await getCompanyUserListActive(
            companyId,
            currentPage,
            pageSize
          );
          //   const response = await getCompanyUserListWIthoutPagination(companyId);

          setUserList(response.data.data.users_list.results);
          setLogTotalCount(response.data.data.Total_count);
          setUsersCount(response.data.data.users_list.count);

          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };
      const role = localStorage.getItem("role_name");
      setRole(role);

      const fetchUserListDropdown = async () => {
        try {
          setLoading(true);
          const response = await getCompanyUserActiveDropDown(companyId); // Assuming getITParkList is an async function that fetches data
          setDropDown(response.data.data); // Store the data in state
        } catch (error) {
          setLoading(false);
        }
      };
      fetchUserListDropdown();
      fetchCompanyList();
    }
  }, []); // empty dependency array to run the effect only once when the component mounts

  const states = {
    hover: {
      filter: {
        type: "none",
      },
    },
    active: {
      filter: {
        type: "none",
      },
    },
  };

  const selectOptions = [
    { value: "infoParkochi", label: "InfoPark Kochi" },
    { value: "infoParkKoratty", label: "InfoPark Koratty" },
    { value: "infoParkCherthala", label: "InfoPark Cherthala" },
  ];

  const selectPhaseOptions = [
    { value: "infoPark Phase-1", label: "InfoPark Phase-1" },
    { value: "infoPark Phase-2", label: "InfoPark Phase-2" },
  ];
  const selectBuildingOptions = [
    { value: "Athulya", label: "Athulya" },
    { value: "Vismaya", label: "Vismaya" },
    { value: "TCS", label: "TCS" },
    { value: "Thapasaya", label: "Thapasaya" },
    { value: "World Trade Center", label: "World Trade Center" },
  ];
  const selectUserOptions = [
    { value: "Shalini", label: "Shalini" },
    { value: "Rekha", label: "Rekha" },
    { value: "Renjini", label: "Renjini" },
  ];

  const [selectedOption, setSelectedOption] = useState(selectOptions[0]);
  const [selectedPhase, setSelectedPhase] = useState(selectPhaseOptions[0]);
  const [selectedBuilding, setSelectedBuilding] = useState(
    selectBuildingOptions[0]
  );
  const [selectedUser, setSelectedUser] = useState(selectUserOptions[0]);

  const ctxLabel = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const ctxData1 = [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30];
  const ctxData2 = [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20];
  const ctxColor1 = "#506fd9";
  const ctxColor2 = "#DBDFFD";

  const dataBar3 = {
    labels: ctxLabel,
    datasets: [
      {
        data: ctxData1,
        backgroundColor: ctxColor1,
        barPercentage: 0.5,
      },
      {
        data: ctxData2,
        backgroundColor: ctxColor2,
        barPercentage: 0.5,
      },
    ],
  };

  const dataBar = {
    labels: ctxLabel,
    datasets: [
      {
        data: visitedCount,
        backgroundColor: ctxColor1,
        barPercentage: 0.5,
      },
      {
        data: notVisitedCount,
        backgroundColor: ctxColor2,
        barPercentage: 0.5,
      },
    ],
  };

  const handleClick = (event, elements) => {
    if (elements.length > 0) {
      const chartElement = elements[0];
      const index = chartElement.index;
      const month = index + 1; // Since months are zero-indexed in JavaScript Date objects
      // const year = new Date().getFullYear();
      const year = getSelectedYear(startDate4);

      let startDate, endDate;

      // Determine the start and end dates based on the selected month
      if (month === 2) {
        // February: Handle leap year if necessary
        startDate = new Date(year, month - 1, 1);
        endDate = new Date(year, month - 1, 28); // Assuming non-leap year by default
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
          endDate.setDate(29); // Leap year adjustment
        }
      } else if ([4, 6, 9, 11].includes(month)) {
        // April, June, September, November
        startDate = new Date(year, month - 1, 1);
        endDate = new Date(year, month - 1, 30);
      } else {
        // Other months (January, March, May, July, August, October, December)
        startDate = new Date(year, month - 1, 1);
        endDate = new Date(year, month - 1, 31);
      }

      // Format startDate and endDate as required (e.g., "dd/MM/yyyy")
      const formattedStartDate = `${startDate.getDate()}/${
        startDate.getMonth() + 1
      }/${startDate.getFullYear()}`;
      const formattedEndDate = `${endDate.getDate()}/${
        endDate.getMonth() + 1
      }/${endDate.getFullYear()}`;

      console.log(formattedEndDate, formattedStartDate);
      // navigate(`/dashboard/pass-list?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
      setFormattedStartDate(formattedStartDate);
      setFormattedEndDate(formattedEndDate);

      if (roleName === "Company Admin" || roleName === "Company User") {
        navigate("/dashboard/pass-list", {
          state: {
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            ...(user !== "all" && { createdBy: user }),
          },
        });
      } else if (roleName === "Super Admin") {
        navigate("/dashboard/pass-list", {
          state: {
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            parkId: itPark,
          },
        });
      } else if (roleName === "Park Admin") {
        navigate("/dashboard/pass-list", {
          state: {
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            parkId: localStorage.getItem("userPark"),
            phaseId: itPhase,
          },
        });
      } else if (roleName === "Phase Admin") {
        navigate("/dashboard/pass-list", {
          state: {
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            phaseId: localStorage.getItem("userPhase"),
            buildingId: building,
          },
        });
      }

    }
  };

  const baseOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        title: {
          display: true,
          text: "Total pass generated",
        },
      },
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Month",
        },
      },
    },
  };

  let optionBar3;

  optionBar3 = {
    ...baseOptions,
    onClick: handleClick, // Add the onClick event conditionally
  };

  const [startDate, setStartDate] = useState(new Date());
  const [startDate4, setStartDate4] = useState(new Date());

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const navigate = useNavigate();
  const { setData, setSelectedData } = useState(null);
  const onParkNameClick = (data) => {
    navigate.push({
      pathname: "/dashboard/park-details",
      state: { data: "some data" },
    });
  };

  // Event handler for IT Park change
  const handleITParkChange = async (event) => {
    const value = event.target.value;
    setITPark(value);
  };

  //Event handler for IT phase change
  const handleITPhaseChange = async (event) => {
    const value = event.target.value;
    setITPhase(value);
  };

  //Event handler for Building change
  const handleBuildingChange = async (event) => {
    const value = event.target.value;
    setBuilding(value);
  };

  //Event handler for company user change
  const handleUserChange = async (event) => {
    const value = event.target.value;
    setUser(value);
  };

  // Update startDate4 when a new date is selected
  const handleDateChange = (date) => {
    setStartDate4(date);
  };

  // Function to get the selected year
  const getSelectedYear = (date) => {
    return date.getFullYear();
  };

  const fetchData = async () => {
    if (roleName === "Super Admin") {
      if (itPark && startDate4) {
        const year = getSelectedYear(startDate4);
        
        try {
          setLoading(true);
          let response;
          if (itPark === "all") {
            response = await dashboardGraphWithYear(year);
          } else {
            response = await dashboardGraph(itPark, year);
          }
          // Assuming getITParkList is an async function that fetches data
          setVisitedCount(response.data.data.visited_counts);
          setNotVisitedCount(response.data.data.not_visited_counts);
        } catch (error) {
          setLoading(false);
        }
      }
    } else if (roleName === "Park Admin") {
      if (itPhase && startDate4) {
        const year = getSelectedYear(startDate4);
        // Replace the following line with your actual API call
        // Example: await apiFunction(itPark, year);
        try {
          setLoading(true);
          let response;
          if (itPhase === "all") {
            response = await dashboardGraphWithYear(year);
          } else {
            response = await dashboardParkGraph(itPhase, year);
          }
          setVisitedCount(response.data.data.visited_counts);
          setNotVisitedCount(response.data.data.not_visited_counts);
        } catch (error) {
          setLoading(false);
        }
      }
    } else if (roleName === "Phase Admin") {
      if (building && startDate4) {
        const year = getSelectedYear(startDate4);
        // Replace the following line with your actual API call
        // Example: await apiFunction(itPark, year);
        try {
          setLoading(true);
          // const response = await dashboardPhaseGraph(building, year); // Assuming getITParkList is an async function that fetches data
          let response;
          if (building === "all") {
            response = await dashboardGraphWithYear(year);
          } else {
            response = await dashboardPhaseGraph(building, year);
          }
          setVisitedCount(response.data.data.visited_counts);
          setNotVisitedCount(response.data.data.not_visited_counts);
        } catch (error) {
          setLoading(false);
        }
      }
    } else if (roleName === "Company Admin" || roleName === "Company User") {
      if (user && startDate4) {
        const year = getSelectedYear(startDate4);
        // Replace the following line with your actual API call
        // Example: await apiFunction(itPark, year);
        try {
          setLoading(true);
          // const response = await dashboardCompanyGraph(user, year); // Assuming getITParkList is an async function that fetches data
          let response;
          if (user === "all") {
            response = await dashboardGraphWithYear(year);
          } else {
            response = await dashboardCompanyGraph(user, year);
          }
          setVisitedCount(response.data.data.visited_counts);
          setNotVisitedCount(response.data.data.not_visited_counts);
        } catch (error) {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    // if (shouldFetch) {
    fetchData();
    // }
  }, [itPark, itPhase, building, user, startDate4, shouldFetch]);

  // This effect sets the default value to the first item in the dropDown array
  useEffect(() => {
    if (Array.isArray(dropDown) && dropDown.length > 0) {
    }
  }, [dropDown]);

  useEffect(() => {
    setShouldFetch(true);
  }, []);

  const getCurrentMonthDates = ({ key }) => {
    const today = new Date();
    const currentMonthStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    const nextMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1
    );
    const currentMonthEnd = new Date(nextMonthStart.getTime() - 1); // Last day of current month
    if (key === "generated") {
      // Navigate to pass-list with state
      navigate("/dashboard/pass-list", {
        state: {
          start_date: currentMonthStart.toLocaleDateString("en-GB"),
          end_date: currentMonthEnd.toLocaleDateString("en-GB"),
        },
      });
    } else if (key === "visited") {
      // Navigate to pass-list with state
      navigate("/dashboard/pass-list", {
        state: {
          start_date: currentMonthStart.toLocaleDateString("en-GB"),
          end_date: currentMonthEnd.toLocaleDateString("en-GB"),
          visited: "1"
        },
      });
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
            </ol>
            <h4 className="main-title mb-0">Welcome {name}</h4>
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            {roleName === "Super Admin" ? (
              <Button
                as={Link}
                to="/dashboard/park-list"
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
                <span> Go to parks</span>
              </Button>
            ) : roleName === "Park Admin" ? (
              <Button
                as={Link}
                to="/dashboard/phase-list"
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
                <span> Go to phases</span>
              </Button>
            ) : roleName === "Phase Admin" ? (
              <div className="d-flex align-items-center gap-2">
                <Button
                  as={Link}
                  to="/dashboard/gate-list"
                  variant="primary"
                  className="d-flex align-items-center gap-2"
                >
                  <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
                  <span>Go to gates</span>
                </Button>

                <Button
                  as={Link}
                  to="/dashboard/building-list"
                  variant="primary"
                  className="d-flex align-items-center gap-2"
                >
                  <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
                  <span> Go to buildings</span>
                </Button>
              </div>
            ) : (
              <div className="d-flex align-items-center gap-2">
                <Button
                  as={Link}
                  to="/dashboard/user-list"
                  variant="primary"
                  className="d-flex align-items-center gap-2"
                >
                  <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
                  <span>Go To Company Users</span>
                </Button>
                <Button
                  as={Link}
                  to="/dashboard/create-new-pass"
                  variant="primary"
                  className="d-flex align-items-center gap-2"
                >
                  <span>Create New Pass</span>
                </Button>
              </div>
            )}
          </div>
        </div>

        <Row className="g-3">
          <Col xl="8">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Visitor Pass Logs</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <Row className="g-3 align-items-center">
                  {/* Visited/Not Visited */}
                  <Col sm="4" md="3">
                    <ul className="legend mb-3">
                      <li>Visited</li>
                      <li>Not Visited</li>
                    </ul>
                  </Col>

                  {roleName === "Super Admin" ? (
                    <Col sm="8" md="6">
                      <div className="d-flex flex-md-row flex-column align-baseline justify-content-between align-items-baseline gap-2">
                        <div>
                          <label>Select Parks</label>
                        </div>
                        <div className="flex-grow-1">
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              as="select"
                              value={itPark}
                              onChange={handleITParkChange}
                              className="fixed-width-select"
                            >
                              <option value="all">All</option>
                              {dropDown === undefined && (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                              {Array.isArray(dropDown) &&
                                dropDown.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.parkName}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                  ) : roleName === "Park Admin" ? (
                    <Col sm="8" md="6">
                      <div className="d-flex flex-md-row flex-column align-baseline justify-content-between align-items-baseline gap-2">
                        <div>
                          <label>Select Phases</label>
                        </div>
                        <div className="flex-grow-1">
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              as="select"
                              value={itPhase}
                              onChange={handleITPhaseChange}
                              className="fixed-width-select"
                            >
                              <option value="all">All</option>
                              {dropDown === undefined && (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                              {Array.isArray(dropDown) &&
                                dropDown.map((item, index) => (
                                  <option key={index} value={item.phaseId}>
                                    {item.phaseName}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                  ) : roleName === "Phase Admin" ? (
                    <Col sm="8" md="6">
                      <div className="d-flex flex-md-row flex-column align-baseline justify-content-between align-items-baseline gap-2">
                        <div>
                          <label>Select Buildings</label>
                        </div>
                        <div className="flex-grow-1">
                          
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              as="select"
                              value={building}
                              onChange={handleBuildingChange}
                              className="fixed-width-select"
                            >
                              <option value="all">All</option>
                              {dropDown === undefined && (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                              {Array.isArray(dropDown) &&
                                dropDown.map((item, index) => (
                                  <option key={index} value={item.buildingId}>
                                    {item.buildingName}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <Col sm="8" md="6">
                      <div className="d-flex flex-md-row flex-column align-baseline justify-content-between align-items-baseline gap-2">
                        <div>
                          <label>Select Users</label>
                        </div>
                        <div className="flex-grow-1">
                         
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              as="select"
                              value={user}
                              onChange={handleUserChange}
                              className="fixed-width-select"
                            >
                              <option value="all">All</option>
                              {dropDown === undefined && (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                              {Array.isArray(dropDown) &&
                                dropDown.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item?.user__id}
                                    className={
                                      item.is_active ? "" : "text-muted-italic"
                                    }
                                  >
                                    {item?.user__first_name}
                                    {!item.is_active && ` (In Active)`}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                  )}

                  {/* Choose Year */}
                  <Col sm="12" md="3">
                    <DatePicker
                      dateFormat="yyyy"
                      selected={startDate4}
                      onChange={handleDateChange}
                      showYearPicker
                      className="form-control"
                      minDate={new Date(new Date().getFullYear(), 0, 1)} // Disable past years
                    />
                  </Col>
                </Row>

                <Row className="g-3 mt-3">
                  {roleName === "Super Admin" ? (
                    <small className="mb-3">
                      Note: This is park wise visitor pass logs for the year
                      {getSelectedYear(startDate4)}
                    </small>
                  ) : roleName === "Park Admin" ? (
                    <small className="mb-3">
                      Note: This is phase wise visitor pass logs for the year
                      {getSelectedYear(startDate4)}
                    </small>
                  ) : roleName === "Phase Admin" ? (
                    <small className="mb-3">
                      Note: This is building wise visitor pass logs for the year
                      {getSelectedYear(startDate4)}
                    </small>
                  ) : (
                    <small className="mb-3">
                      Note: This is user wise visitor pass logs for the year
                      {getSelectedYear(startDate4)}
                    </small>
                  )}
                </Row>
                <Row className="g-3">
                  <Col xs="12" className="pe-4">
                    <Bar
                      data={dataBar}
                      options={optionBar3}
                      className="ht-300"
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col xl="4">
            <Card className="card-one">
              <Card.Header>
                {roleName === "Super Admin" ? (
                  <Card.Title as="h6">Park Logs (Current Month)</Card.Title>
                ) : roleName === "Park Admin" ? (
                  <Card.Title as="h6">Phase Logs (Current Month)</Card.Title>
                ) : roleName === "Phase Admin" ? (
                  <Card.Title as="h6">Building Logs (Current Month)</Card.Title>
                ) : (
                  <Card.Title as="h6">
                    Company User Logs (Current Month)
                  </Card.Title>
                )}
              </Card.Header>
              <Card.Body className="p-3">
               
                <Row className="g-3 mb-4">
                  {roleName === "Super Admin" ? (
                    <Col>
                      <div className="earning-item">
                        <div className="earning-icon bg-primary">
                          <i className="ri-building-3-line"></i>
                        </div>
                        <h4>{logTotalCount.park || 0}</h4>
                        <label>Parks</label>
                      </div>
                    </Col>
                  ) : roleName === "Park Admin" ? (
                    <Col>
                      <div className="earning-item">
                        <div className="earning-icon bg-primary">
                          <i className="ri-building-3-line"></i>
                        </div>
                        <h4>{logTotalCount.phase || 0}</h4>
                        <label>Phases</label>
                      </div>
                    </Col>
                  ) : roleName === "Phase Admin" ? (
                    <Col>
                      <div className="earning-item">
                        <div className="earning-icon bg-primary">
                          <i className="ri-building-3-line"></i>
                        </div>
                        <h4>{logTotalCount.building || 0}</h4>
                        <label>Buildings</label>
                      </div>
                    </Col>
                  ) : (
                    <Col>
                      <div
                        style={{ cursor: "pointer" }}
                        className="earning-item"
                        onClick={() => {
                          navigate("/dashboard/user-list");
                        }}
                      >
                        <div className="earning-icon bg-primary">
                          <i className="ri-building-3-line"></i>
                        </div>
                        <h4>{usersCount || 0}</h4>
                        <label>Company Users</label>
                      </div>
                    </Col>
                  )}

                  <Col>
                  
                      <div
                        style={{ cursor: "pointer" }}
                        className="earning-item"
                        onClick={() => getCurrentMonthDates({ key: "generated" })}
                      >
                        <div className="earning-icon bg-twitter">
                          <i className="ri-file-list-2-line"></i>
                        </div>
                        <h4>{logTotalCount.pass_generated || 0}</h4>
                        <label>Pass Generated</label>
                      </div>
                   
                  </Col>
                  <Col>
                      <div
                        style={{ cursor: "pointer" }}
                        className="earning-item"
                        onClick={() => getCurrentMonthDates({ key: "visited" })}
                      >
                        <div className="earning-icon bg-success">
                          <i className="ri-team-line"></i>
                        </div>
                        <h4>{logTotalCount.visited || 0}</h4>
                        <label>Pass Visited</label>
                      </div>
                   
                  </Col>
                </Row>

                <Table className="table-two mb-4" responsive>
                  {roleName === "Super Admin" ? (
                    <thead>
                      <tr>
                        <th>Park Name</th>
                        <th>Pass Generated</th>
                        <th>Visited</th>
                      </tr>
                    </thead>
                  ) : roleName === "Park Admin" ? (
                    <thead>
                      <tr>
                        <th>Phase Name</th>
                        <th>Pass Generated</th>
                        <th>Visited</th>
                      </tr>
                    </thead>
                  ) : roleName === "Phase Admin" ? (
                    <thead>
                      <tr>
                        <th>Building Name</th>
                        <th>Pass Generated</th>
                        <th>Visited</th>
                      </tr>
                    </thead>
                  ) : (
                    <thead>
                      <tr>
                        <th>User Name</th>
                        <th>Pass Generated</th>
                        <th>Visited</th>
                      </tr>
                    </thead>
                  )}

                  {roleName === "Super Admin" ? (
                    <tbody>
                      {Array.isArray(parkList) ? (
                        parkList?.slice(0, 5).map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{ cursor: "pointer" }}
                              className="text-primary gate-table"
                              onClick={() => {
                                // Dispatch action to update phase ID in Redux store
                                dispatch(setParkId(item.id)); // assuming 'item.id' is the phase ID
                              }}
                            >
                              <Link
                                to={{
                                  pathname: "/dashboard/park-details",
                                  search: "?gen",
                                }}
                              >
                                {item.parkName}
                              </Link>
                            </td>

                            <td>{item.count?.pass_generated}</td>
                            <td className="text-success">
                              {item.count?.visited}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div style={{ textAlign: "center" }}>No Data Found</div>
                      )}
                    </tbody>
                  ) : roleName === "Park Admin" ? (
                    <tbody>
                      {phaseList?.slice(0, 5).map((item, index) => (
                        <tr key={index}>
                          <td
                            style={{ cursor: "pointer" }}
                            className="text-primary"
                            onClick={() => {
                              // Dispatch action to update phase ID in Redux store
                              dispatch(setPhaseId(item.id)); // assuming 'item.id' is the phase ID
                            }}
                          >
                            <Link
                              to={{
                                pathname: `/dashboard/phase-details`,
                                search: "?gen",
                              }}
                            >
                              {item.PhaseName}
                            </Link>
                          </td>
                          <td>{item.count?.pass_generated}</td>
                          <td className="text-success">
                            {item.count?.visited}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : roleName === "Phase Admin" ? (
                    <tbody>
                      {buildingList?.length > 0 ? (
                        buildingList?.slice(0, 5).map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{ cursor: "pointer" }}
                              className="text-primary"
                              onClick={() => {
                                dispatch(setBuildingId(item.id));
                              }}
                            >
                              <Link
                                to={{
                                  pathname: `/dashboard/building-details`,
                                  search: "?gen",
                                }}
                              >
                                {item.buildingName}
                              </Link>
                            </td>
                            <td>{item.count?.pass_generated}</td>
                            <td className="text-success">
                              {item.count?.visited}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" style={{ textAlign: "center" }}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : roleName === "Company Admin" ||
                    role === "Company User" ? (
                    <tbody>
                      {userlist?.length > 0 ? (
                        userlist?.slice(0, 5).map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{ cursor: "pointer" }}
                              className="text-primary"
                              onClick={() => {
                                dispatch(setUserId(item.user.id));
                              }}
                            >
                              <Link
                                to={{
                                  pathname: `/dashboard/visitor-logs`,
                                  search: "?gen",
                                }}
                              >
                                {item.user.first_name}
                                {item.user.last_name}
                              </Link>
                            </td>
                            <td>{item.count?.pass_generated || 0}</td>
                            <td className="text-success">
                              {item.count?.visited || 0}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" style={{ textAlign: "center" }}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : null}
                </Table>
              </Card.Body>

              <Card.Footer>
                {roleName === "Super Admin" ? (
                  <h6 className="fw-semibold mb-1 text-left">
                    <Link to="/dashboard/park-list">View all parks</Link>
                  </h6>
                ) : roleName === "Park Admin" ? (
                  <h6 className="fw-semibold mb-1 text-left">
                    <Link to="/dashboard/phase-list">View all phases</Link>
                  </h6>
                ) : roleName === "Phase Admin" ? (
                  <h6 className="fw-semibold mb-1 text-left">
                    <Link to="/dashboard/building-list">
                      View all buildings
                    </Link>
                  </h6>
                ) : (
                  <h6 className="fw-semibold mb-1 text-left">
                    <Link to="/dashboard/user-list">View all users</Link>
                  </h6>
                )}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
