import { Modal, Button } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa"; // Import a danger icon

function MessageModal({ show, onHide, title, message }) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header className="justify-content-center">
        <FaExclamationTriangle className="me-2 text-danger" size={24} />
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center text-center">
        <div className="text-danger" style={{ fontSize: '1.25rem', wordBreak: 'break-word' }}>
          {message}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MessageModal;