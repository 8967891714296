import { axiosInstance } from "./axiosInstance";

export const createCompanyUser = (data) => {
  return axiosInstance.post("/api/park/company-user", data);
};

// API endpoint for create pass
export const createPass = (data) => {
  return axiosInstance.post("/api/pass/pass", data);
};

// API endpoint for bulk pass
export const createBulkPass = (data) => {
  return axiosInstance.post("/api/pass/bulk-upload/", data);
};

export const getCompanyList = (BuildingId, currentPage, pageSize, active) => {
  return axiosInstance.get(
    `/api/park/company-list?active=${active}&BuildingId=${BuildingId}&page=${currentPage}&page_size=${pageSize}`
  );
};

export const getCompanyUserList = (companyId, currentPage, pageSize, active) => {
  return axiosInstance.get(
    `/api/park/company-user?is_active=${active}&companyId=${companyId}&page=${currentPage}&page_size=${pageSize}`,
    {}
  );
};

export const getCompanyUserListActive = (companyId, currentPage, pageSize, active) => {
  return axiosInstance.get(
    `/api/park/company-user?is_active=1&companyId=${companyId}&page=${currentPage}&page_size=${pageSize}`,
    {}
  );
};

export const getBuildingDetails = (phaseId) => {
  return axiosInstance.get(`/api/park/buildingdetail/?BuildingId=` + phaseId);
};

export const getCompanyUserDropDown = (companyId) => {
  return axiosInstance.get(
    `/api/park/companyuser-dropdown/?companyId=` + companyId
  );
};

export const getCompanyUserActiveDropDown = (companyId) => {
  return axiosInstance.get(
    `/api/park/companyuser-dropdown/?is_active=1&companyId=` + companyId
  );
};

export const getCompanyDetails = (companyId) => {
  return axiosInstance.get(`/api/park/company-details/?companyId=` + companyId);
};

export const getCompanyInfo = (companyId) => {
  return axiosInstance.get(`/api/user/company?companyId=` + companyId);
};

export const deleteCompanyUser = (user_id) => {
  return axiosInstance.delete(`/api/park/company-user-details/` + user_id);
}

export const getCompanyUserDetails = (user_id) => {
  return axiosInstance.get(`/api/park/company-user-details/` + user_id);
}

export const updateCompanyUserDetails = (userId,data) => {
  return axiosInstance.put(`/api/park/company-user-details/` + userId , data);
}

export const deleteCompany = (company_id) => {
  return axiosInstance.delete(`/api/park/company-info/` + company_id);
}

export const updateCompany = (data, company_id) => {
  return axiosInstance.put(`/api/park/company-info/` + company_id, data);
}

export const getCompanyDropdown = (building_id) => {
  return axiosInstance.get(`/api/park/company-dropdown/?buildingId=` + building_id);
}
export const updateBuildingDetails = (data, building_id) => {
  return axiosInstance.put(`/api/park/building-details/` + building_id, data);
};

export const deleteBuilding = (building_id) => {
  return axiosInstance.delete(`/api/park/building-details/` + building_id);
};
