import moment from "moment";

const curYear = moment().format('YYYY');
const curMonth = moment().format('MM');

// Calendar Event Source
const calendarEvents = {
  id: 1,
  backgroundColor: '#d9e8ff',
  borderColor: '#0168fa',
  events: [
    {
      id: '1',
      start: curYear+'-'+curMonth+'-08T08:30:00',
      end: curYear+'-'+curMonth+'-08T13:00:00',
      title: 'ThemeForest Meetup',
      description: 'In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...'
    },{
      id: '2',
      start: curYear+'-'+curMonth+'-10T09:00:00',
      end: curYear+'-'+curMonth+'-10T17:00:00',
      title: 'Design Review',
      description: 'In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...'
    },{
      id: '3',
      start: curYear+'-'+curMonth+'-13T12:00:00',
      end: curYear+'-'+curMonth+'-13T18:00:00',
      title: 'Lifestyle Conference',
      description: 'Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi...'
    },{
      id: '4',
      start: curYear+'-'+curMonth+'-15T07:30:00',
      end: curYear+'-'+curMonth+'-15T15:30:00',
      title: 'Team Weekly Brownbag',
      description: 'In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...'
    },{
      id: '5',
      start: curYear+'-'+curMonth+'-17T10:00:00',
      end: curYear+'-'+curMonth+'-19T15:00:00',
      title: 'Music Festival',
      description: 'In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...'
    },{
      id: '6',
      start: curYear+'-'+curMonth+'-08T13:00:00',
      end: curYear+'-'+curMonth+'-08T18:30:00',
      title: 'Attend Lea\'s Wedding',
      description: 'In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...'
    }
  ]
};

// Birthday Events Source
const birthdayEvents = {
  id: 2,
  backgroundColor: '#c3edd5',
  borderColor: '#10b759',
  events: [
    {
      id: '7',
      start: curYear+'-'+curMonth+'-01T18:00:00',
      end: curYear+'-'+curMonth+'-01T23:30:00',
      title: 'Socrates Birthday',
      description: 'In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...'
    },
    {
      id: '8',
      start: curYear+'-'+curMonth+'-21T15:00:00',
      end: curYear+'-'+curMonth+'-21T21:00:00',
      title: 'Reynante\'s Birthday',
      description: 'In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...'
    },
    {
      id: '9',
      start: curYear+'-'+curMonth+'-23T15:00:00',
      end: curYear+'-'+curMonth+'-23T21:00:00',
      title: 'Pauline\'s Birthday',
      description: 'In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...'
    }
  ]
};

const holidayEvents = {
  id: 3,
  backgroundColor: '#fcbfdc',
  borderColor: '#f10075',
  events: [
    {
      id: '10',
      start: curYear+'-'+curMonth+'-04',
      end: curYear+'-'+curMonth+'-06',
      title: 'Feast Day'
    },
    {
      id: '11',
      start: curYear+'-'+curMonth+'-26',
      end: curYear+'-'+curMonth+'-27',
      title: 'Memorial Day'
    },
    {
      id: '12',
      start: curYear+'-'+curMonth+'-28',
      end: curYear+'-'+curMonth+'-29',
      title: 'Veteran\'s Day'
    }
  ]
};

const discoveredEvents = {
  id: 4,
  backgroundColor: '#bff2f2',
  borderColor: '#00cccc',
  events: [
    {
      id: '13',
      start: curYear+'-'+curMonth+'-17T08:00:00',
      end: curYear+'-'+curMonth+'-18T11:00:00',
      title: 'Web Design Workshop Seminar'
    }
  ]
};

const meetupEvents = {
  id: 5,
  backgroundColor: '#dedafe',
  borderColor: '#5b47fb',
  events: [
    {
      id: '14',
      start: curYear+'-'+curMonth+'-03',
      end: curYear+'-'+curMonth+'-05',
      title: 'UI/UX Meetup Conference'
    },
    {
      id: '15',
      start: curYear+'-'+curMonth+'-18',
      end: curYear+'-'+curMonth+'-20',
      title: 'Angular Conference Meetup'
    }
  ]
};


const otherEvents = {
  id: 6,
  backgroundColor: '#ffdec4',
  borderColor: '#fd7e14',
  events: [
    {
      id: '16',
      start: curYear+'-'+curMonth+'-06',
      end: curYear+'-'+curMonth+'-08',
      title: 'My Rest Day'
    },
    {
      id: '17',
      start: curYear+'-'+curMonth+'-29',
      end: curYear+'-'+curMonth+'-31',
      title: 'My Rest Day'
    }
  ]
};
const admin_log=[
  {
    "admin_name": "Renju",
    "park": "InfoPark Kochi",
    "email": "abc@gmail.com",
    "mobile": "987456123",
    "created_date": "24-04-2024",
    "created_time": "12 pm",
    "modified_date": "25-04-2024",
    "modified_time":"2 pm"
    
  }, {
    "admin_name": "Raghav",
    "park": "InfoPark Thrissur",
    "email": "abc@gmail.com",
    "mobile": "987456123",
    "created_date": "24-04-2024",
    "created_time": "12 pm",
    "modified_date": "25-04-2024",
    "modified_time":"2 pm"
    
  }, {
    "admin_name": "Aadhithya",
    "park": "InfoPark Thrissur",
    "email": "abc@gmail.com",
    "mobile": "987456123",
    "created_date": "24-04-2024",
    "created_time": "12 pm",
    "modified_date": "25-04-2024",
    "modified_time":"2 pm"
    
  } 
]
const park_log=[
  {
    "Parkname": "InfoPark",
    "location": "Kochi",
    "address": "Infopark Park Centre, Near Thapasya Building, Infopark Phase 1, Infopark Kochi P O, Kakkanad 682 042",
    "createddate": "24-04-2024",
    "createdtime": "12pm",
    "modifieddate": "25-04-2024",
    "modifiedtime": "1pm",
    
  },{
    "Parkname": "InfoPark",
    "location": "Cherthala",
    "address": "P9J3+WFJ, Pallippuram Thycattussery Road, Kelamangalam - Pallipuram, Alappuzha 688541,Kerala",
    "createddate": "24-04-2024",
    "createdtime": "12pm",
    "modifieddate": "25-04-2024",
    "modifiedtime": "1pm",
    
  }, {
    "Parkname": "InfoPark",
    "location": "Thrissur",
    "address": "Chembakam Block,Infopark Thrissur, Nalukettu Road,Koratty - 680 308,Kerala",
    "createddate": "24-04-2024",
    "createdtime": "12pm",
    "modifieddate": "25-04-2024",
    "modifiedtime": "1pm",
    
  }, {
    "Parkname": "Carnival InfoPark",
    "location": "Kochi",
    "address": "Carnival Phase Iv Exit Road, Infopark Campus, Kakkanad, Ernakulam - 682030,Kerala",
    "createddate": "24-04-2024",
    "createdtime": "12pm",
    "modifieddate": "25-04-2024",
    "modifiedtime": "1pm",
    
  }
]
const visitor_logs=[
  {
    "visitorname": "Remya",
    "contactnumber": "9526481235",
    "visitingtime": "27/05/2024 11 AM",
    "enteredthrough": "South gate",
    "created_by": "Rekha",
    "email":"remya@gmail.com"
  },{
    "visitorname": "Sneha",
    "contactnumber": "7894512505",
    "visitingtime": "28/05/2024 10.30 AM",
    "enteredthrough": "Main gate",
    "created_by": "Preethi",
    "email":"sneha@gmail.com"

  }, {
    "visitorname": "Anu",
    "contactnumber": "8547392221",
    "visitingtime": "29/05/2024 2 PM",
    "enteredthrough": "North gate",
    "created_by": "Rekha",
    "email":"anu@gmail.com"
  }, {
    "visitorname": "Akhila",
    "contactnumber": "9997581496",
    "visitingtime": "30/05/2024 3 PM",
    "enteredthrough": "South gate",
    "created_by": "Preethi",
    "email":"akhila@gmail.com"
  },
  {
    "visitorname": "Shyam",
    "contactnumber": "8596741255",
    "visitingtime": "30/05/2024 3.30 PM",
    "enteredthrough": "Main gate",
    "created_by": "Melbin",
    "email":"Shyam@gmail.com"

  },
  {
    "visitorname": "Aswin",
    "contactnumber": "7894514245",
    "visitingtime": "31/05/2024 10 AM",
    "enteredthrough": "South gate",
    "created_by": "Preethi",
    "email":"aswin@gmail.com"

  },
  {
    "visitorname": "Preethi",
    "contactnumber": "7894514245",
    "visitingtime": "01/06/2024 10 AM",
    "enteredthrough": "North gate",
    "created_by": "Rekha",
    "email":"preethi@gmail.com"

  },
  {
    "visitorname": "Kumar",
    "contactnumber": "9585514245",
    "visitingtime": "02/06/2024 11 AM",
    "enteredthrough": "South gate",
    "created_by": "Preethi",
    "email":"kumar@gmail.com"

  }
]

export { park_log, admin_log,calendarEvents, birthdayEvents, holidayEvents, discoveredEvents, meetupEvents, otherEvents,visitor_logs };