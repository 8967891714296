import { axiosInstance } from "./axiosInstance";
// API endpoint for login
export const login = (data) => {
    return axiosInstance.post('/api/user/login/', data);
};
export const send_email = (data) => {
    return axiosInstance.post('/api/user/send-password-reset-email/', data);
};
export const password_reset_with_token = (data) => {
    return axiosInstance.post('/api/user/change-password', data);
};
export const password_reset_without_token = (data) => {
    return axiosInstance.post('/api/user/reset-password', data);
};
export const check_token_validity = (token) => {
    return axiosInstance.get('/api/user/verify-email-token?token=' + token);
};
