
const dashboardMenu = [
 
  {
    "label": "Dashboard",
    "link": "/dashboard/storage",
    "icon": "ri-hard-drive-2-line"
  },
  
];


const pagesMenu = [
  {
    "label": "User Pages",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "User Profile",
        "link": "/pages/profile"
      }
    ]
  },

];

export { dashboardMenu, pagesMenu };