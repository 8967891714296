import React, { useEffect, useState, useCallback } from "react";
import Header from "../../layouts/Header";
import Table from "react-bootstrap/Table";
import { Button, Card, Col, Row, Pagination, Form } from "react-bootstrap";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import VisitorDetailsModal from "../Company/VisitorDetailsModal";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { filterSearch, passSearch, cancelPass } from "../../services/Pass";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { format, parse } from "date-fns";
import "rsuite/dist/rsuite.min.css";
import { getCompanyUserDropDown } from "../../services/Company";
import { showToast } from "../shared/Toaster";
import ConfirmationModal from "../shared/ConfirmationModal";
import { getParkListDropdown } from "../../services/Park";
import { getBuildingList, getPhaseDropdown } from "../../services/Phase";
import { getBuildingDropdown } from "../../services/Phase";
import { getCompanyDropdown } from "../../services/Company";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default function GeneratedPassList() {
  const [passList, setPassList] = useState([]);
  const { key } = useParams();
  const [urlKey, setUrlKey] = useState("");
  const role_name = localStorage.getItem("role_name");
  const [show, setShow] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Get today's date
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [displayedPages, setDisplayedPages] = useState([]);
  const [searchUrlNew, setSearchUrlNew] = useState("");

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [isActive, setIsActive] = useState(false);
  const [isActiveContact, setIsActiveContact] = useState(false);
  const [isActiveEmail, setIsActiveEmail] = useState(false);
  const [isActiveVisiting, setIsActiveVisiting] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const companyId = useSelector((state) => state.company.CompanyId);
  // const parkId = useSelector((state) => state.park.ParkId);
  const PhaseId = useSelector((state) => state.phase.PhaseId);
  const BuildingId = useSelector((state) => state.building.BuildingId);

  const companyUser = localStorage.getItem("userCompany");
  const [resCount, setResCount] = useState("");

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [userDropDown, setUserDropDown] = useState("");
  const [itparkList, setITParkList] = useState([]);
  const [itphaseList, setITPhaseList] = useState([]);
  const [itbuildingList, setITBuildingList] = useState([]);
  const [itcompanyList, setITCompanyList] = useState([]);

  const allOption = { value: "all", label: "All" };
  const [itpark, setITPark] = useState("");
  const [phase, setPhase] = useState("");
  const [building, setBuilding] = useState("");
  const [company, setCompany] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [searchTerms, setSearchTerms] = useState(() => {
    // Determine initial values based on user role
    let initialParkId = "all";
    let initialPhaseId = "all";
    let initialBuildingId = "all";
    let initialCompanyId = "all";
    let initialCreatedBy = "";

    if (role_name === "Park Admin") {
      initialParkId = localStorage.getItem("userPark") || "all";
    } else if (role_name === "Phase Admin") {
      initialParkId = localStorage.getItem("userPark") || "all";
      initialPhaseId = localStorage.getItem("userPhase") || "all";
    }

    return {
      parkId: location?.state?.parkId || initialParkId,
      phaseId: location?.state?.phaseId || initialPhaseId,
      buildingId: location?.state?.buildingId || initialBuildingId,
      companyId: location?.state?.companyId || initialCompanyId,
      name: "",
      email: "",
      phone: "",
      startDate: location?.state?.start_date || "",
      endDate: location?.state?.end_date || "",
      visited: location?.state?.visited || "",
      createdBy: location?.state?.createdBy || initialCreatedBy,
    };
  });

  useEffect(() => {
    const fetchUserListDropdown = async () => {
      try {
        setLoading(true);
        let response;
        if (role_name === "Company Admin" || role_name === "Company User") {
          response = await getCompanyUserDropDown(companyUser);
          setUserDropDown(response.data.data); // Store the data in state
        } else {
          response = await getCompanyUserDropDown(companyId);
          setUserDropDown(response.data.data); // Store the data in state
        }
      } catch (error) {
        setLoading(false);
      }
    };
    fetchUserListDropdown();
  }, []);

  useEffect(() => {
    if (location.state) {
      let startDate, endDate, searchParams;
      const { start_date, end_date, createdBy, parkId, phaseId, buildingId } =
        location.state;

      const parsedStartDate = start_date
        ? parse(start_date, "dd/MM/yyyy", new Date())
        : getToday();
      const parsedEndDate = end_date
        ? parse(end_date, "dd/MM/yyyy", new Date())
        : getToday();

      setStartDate(parsedStartDate);
      setEndDate(parsedEndDate);

      // Initialize searchParams with common date range
      searchParams = {
        ...searchTerms, // Include previous search terms
        startDate: parsedStartDate,
        endDate: parsedEndDate,
      };

      if (role_name === "Company Admin" || role_name === "Company User") {
        searchParams.createdBy = createdBy;
      } else if (role_name === "Super Admin") {
        searchParams.parkId = parkId;
      } else if (role_name === "Park Admin") {
        searchParams.parkId = localStorage.getItem("userPark");
        searchParams.phaseId = phaseId;
      } else if (role_name === "Phase Admin") {
        searchParams.phaseId = localStorage.getItem("userPhase");
        searchParams.buildingId = buildingId;
      }

      setSearchTerms((prevTerms) => ({
        ...prevTerms,
        startDate: format(parsedStartDate, "dd/MM/yyyy"),
        endDate: format(parsedEndDate, "dd/MM/yyyy"),
        createdBy: createdBy,
        parkId: parkId,
        phaseId: phaseId,
        buildingId: buildingId,
      }));

      setDateRange({
        startDate: parsedStartDate,
        endDate: parsedEndDate,
      });

      debouncedSearch(searchParams);
    }
  }, [location.state, role_name]);

  useEffect(() => {
    const fetchITParkList = async () => {
      try {
        const response = await getParkListDropdown(); // Assuming getITParkList is an async function that fetches data
        const parks = response.data.data;
        setITParkList(parks); // Store the data in state
      } catch (error) {
      }
    };
    fetchITParkList();
  }, [searchTerms.parkId]);

  // Fetch IT Phase List based on selected park
  useEffect(() => {
    const fetchITPhaseList = async () => {
      if (searchTerms.parkId !== "all") {
        try {
          const response = await getPhaseDropdown(searchTerms.parkId);
          setITPhaseList(response.data.data);
        } catch (error) {
          setITPhaseList([]);
        }
      } else {
        setITPhaseList([]);
      }
    };
    fetchITPhaseList();
  }, [searchTerms.parkId]);

  // Fetch IT Building List based on selected phase
  useEffect(() => {
    const fetchITBuildingList = async () => {
      if (searchTerms.phaseId !== "all") {
        try {
          const response = await getBuildingDropdown(searchTerms.phaseId);
          setITBuildingList(response.data.data);
        } catch (error) {
          setITBuildingList([]);
        }
      } else {
        setITBuildingList([]);
      }
    };
    fetchITBuildingList();
  }, [searchTerms.phaseId]);

  // Fetch IT Company List based on selected building
  useEffect(() => {
    const fetchITCompanyList = async () => {
      if (searchTerms.buildingId !== "all") {
        try {
          const response = await getCompanyDropdown(searchTerms.buildingId);
          setITCompanyList(response.data.data);
        } catch (error) {
        }
      } else {
        setITCompanyList([]);
      }
    };
    fetchITCompanyList();
  }, [searchTerms.buildingId]);

  const options = Array.isArray(itparkList)
    ? itparkList.map((item) => ({ value: item.id, label: item.parkName }))
    : [];
  const updatedParkOptions = [allOption, ...options];

  const phaseOptions = Array.isArray(itphaseList)
    ? itphaseList.map((item) => ({
        value: item.phaseId,
        label: item.phaseName,
      }))
    : [];
  const updatedPhaseOptions = [allOption, ...phaseOptions];

  const buildingOptions = Array.isArray(itbuildingList)
    ? itbuildingList.map((item) => ({
        value: item.buildingId,
        label: item.buildingName,
      }))
    : [];
  const updatedBuildingOptions = [allOption, ...buildingOptions];

  const companyOptions = Array.isArray(itcompanyList)
    ? itcompanyList.map((item) => ({ value: item.id, label: item.name }))
    : [];
  const updatedCompanyOptions = [allOption, ...companyOptions];

  useEffect(() => {
    fetchData(searchTerms);
  }, [currentPage]);

  const fetchData = async (searchParams) => {
    setPassList([]); // Clear previous results
    setResCount("");
    setTotalItems("");
    try {
      setLoading(true);
      const searchUrl = constructSearchUrl(searchParams);
      setSearchUrlNew(searchUrl);
      const response = await passSearch(currentPage, pageSize, searchUrl);

      setPassList(response?.data?.data?.paginated_data?.results || []);
      setTotalItems(response?.data?.data?.paginated_data?.count || 0);
      setResCount(response?.data?.data?.counts || {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    
  };

  const constructSearchUrl = (searchParams) => {
    const queryParams = [];

    if (searchParams.parkId && searchParams.parkId !== "all") {
      queryParams.push(`parkId=${searchParams.parkId}`);
    }
    if (searchParams.phaseId && searchParams.phaseId !== "all") {
      queryParams.push(`phaseId=${searchParams.phaseId}`);
    }
    if (searchParams.buildingId && searchParams.buildingId !== "all") {
      queryParams.push(`buildingId=${searchParams.buildingId}`);
    }
    if (searchParams.companyId && searchParams.companyId !== "all") {
      queryParams.push(`companyId=${searchParams.companyId}`);
    }
    if (searchParams.name) {
      queryParams.push(`name=${searchParams.name}`);
    }
    if (searchParams.email) {
      queryParams.push(`email=${searchParams.email}`);
    }
    if (searchParams.phone) {
      queryParams.push(`phone=${searchParams.phone}`);
    }
    if (searchParams.startDate) {
      queryParams.push(`startDate=${searchParams.startDate}`);
    }
    if (searchParams.endDate) {
      queryParams.push(`endDate=${searchParams.endDate}`);
    }
    if (searchParams.visited) {
      queryParams.push(`visited=${searchParams.visited}`);
    }
    if (searchParams.createdBy) {
      queryParams.push(`createdBy=${searchParams.createdBy}`);
    }

    return queryParams.join("&");
  };

  const clearSearchTerm = (name) => {
    const updatedSearchTerms = {
      ...searchTerms,
      [name]: "",
    };
    setSearchTerms(updatedSearchTerms);
    debouncedSearch(updatedSearchTerms); // Trigger search after clearing the term
  };

  const handleFocus = () => setIsActive(true);
  const handleBlur = () => setIsActive(false);
  const handleFocusContact = () => setIsActiveContact(true);
  const handleBlurContact = () => setIsActiveContact(false);
  const handleFocusEmail = () => setIsActiveEmail(true);
  const handleBlurEmail = () => setIsActiveEmail(false);
  const handleFocusVisiting = () => setIsActiveVisiting(true);
  const handleBlurVisiting = () => setIsActiveVisiting(false);

  useEffect(() => {
    document.title = "Generated Pass List";
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    setUrlKey(key);
  }, [key]);

  useEffect(() => {
    updateDisplayedPages();
  }, [currentPage, totalItems]);

  const switchSkin = (skin) => {
    const btnWhite = document.getElementsByClassName("btn-white");
    const btnOutlinePrimary = document.getElementsByClassName(
      "btn-outline-primary"
    );
    if (skin === "dark") {
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const gen = searchParams.get("gen");
  const list = searchParams.get("list");

  const handleGoBack = () => {
    navigate("/dashboard/generic");
  };

  const goToCreatePass = (company_name) => {
    if (role_name === "Company Admin" || role_name === "Company User") {
      navigate("/dashboard/create-new-pass");
    }
  };

  const handleShow = (visitor) => {
    setSelectedVisitor(visitor);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedVisitor(null);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 150,
      outline: "none",
    }),
    menu: (provided) => ({
      ...provided,
      width: 150,
    }),
  };

  const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #ced4da",
    padding: "7px 13px",
    fontSize: "0.875rem",
    outline: "none",
    paddingRight: "2rem", // Ensure there is enough space for the clear button
    boxSizing: "border-box",
  };

  const crossIconStyle = {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    zIndex: "1",
    color: "#adb5bd",
    fontSize: "23px",
  };
  const selectStyle = {
    borderRadius: "4px",
    border: "1px solid #ced4da",
    padding: "7px 13px",
    fontSize: "0.875rem",
    outline: "none",
    paddingRight: "2rem", // Ensure there is enough space for the clear button
    boxSizing: "border-box",
    appearance: "none", // Hide the default dropdown arrow
    background: `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNzA2NTYgNkw4IDExLjU4NjRMMTMuMjkzNCA2IiBzdHJva2U9IiM5QjlCOUIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=') no-repeat right 0.75rem center`, // Custom arrow icon
    backgroundSize: "10px",
  };

  const crossIconSelectStyle = {
    position: "absolute",
    right: "2rem", // Position the clear button to the left of the custom dropdown arrow
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    zIndex: "1",
    color: "#adb5bd",
    fontSize: "23px",
  };

  // Function to handle page change
  const onPageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updateDisplayedPages = () => {
    const totalPages = Math.ceil(totalItems / pageSize);
    const maxDisplayedPages = 3;
    const halfRange = Math.floor(maxDisplayedPages / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

    if (endPage - startPage < maxDisplayedPages - 1) {
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    setDisplayedPages(pages);
  };

  const totalPages = Math.ceil(totalItems / pageSize);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;

    // setSearchTerms((prevTerms) => ({
    //   ...prevTerms,
    //   [name]: value,
    // }));
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [name]: value,
      ...(name === "parkId" && {
        phaseId: "all",
        buildingId: "all",
        companyId: "all",
      }),
      ...(name === "phaseId" && { buildingId: "all", companyId: "all" }),
      ...(name === "buildingId" && { companyId: "all" }),
    }));

    // Reset the current page to 1
    setCurrentPage(1);

    // Trigger the search immediately with the updated state
    debouncedSearch({ ...searchTerms, [name]: value, currentPage: 1 });
  };

  const getToday = () => new Date();
  const handleDateChange = (range) => {
    if (range && range[0] && range[1]) {
      // If range is valid
      const formattedStartDate = format(range[0], "dd/MM/yyyy");
      const formattedEndDate = format(range[1], "dd/MM/yyyy");

      setSearchTerms((prevTerms) => ({
        ...prevTerms,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }));

      setDateRange({
        startDate: range[0],
        endDate: range[1],
      });

      // Reset the current page to 1
      setCurrentPage(1);

      debouncedSearch({
        ...searchTerms,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        currentPage: 1,
      });
    } else {
      // If range is invalid (e.g., cleared), set to today's date
      const today = getToday();
      const formattedToday = format(today, "dd/MM/yyyy");

      setSearchTerms((prevTerms) => ({
        ...prevTerms,
        startDate: formattedDate,
        endDate: formattedDate,
      }));

      setDateRange({
        startDate: today,
        endDate: today,
      });

      // Reset the current page to 1
      setCurrentPage(1);
      

      debouncedSearch({
        ...searchTerms,
        startDate: formattedToday,
        endDate: formattedToday,
        currentPage: 1,
      });
    }
  };

  const debouncedSearch = useCallback(
    debounce((updatedSearchTerms) => {
      fetchData(updatedSearchTerms);
    }, 300),
    []
  );

  // Trigger debounced search whenever searchTerms or currentPage changes
  useEffect(() => {
    debouncedSearch({ ...searchTerms });
  }, [searchTerms, debouncedSearch]);

  const customStyleWrap = {
    whiteSpace: "normal",
    width: "50px",
    wordBreak: "break-word",
    // cursor: "pointer",
  };

  const handleCancel = async (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setShowModal(false);
    try {
      setLoading(true);
      const response = await cancelPass(currentIndex);
      fetchData(searchTerms);
      setLoading(false);
      showToast("success", response.data.message);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.message) {
          showToast("error", error.response.data.message);
        } else if (error.response.status === 500) {
          showToast("error", error.response.statusText);
        }
      } else {
        showToast("error", "Internal Server Error");
      }
    }
  };
  const handleITParkChange = (selectedOption) => {
    setITPark(selectedOption);
    setPhase(allOption);
    setBuilding(allOption);
    setCompany(allOption);
  };

  const handlePhaseChange = (selectedOption) => {
    setPhase(selectedOption);
    setBuilding(allOption);
    setCompany(allOption);
  };

  const handleBuildingChange = (selectedOption) => {
    setBuilding(selectedOption);
    setCompany(allOption);
  };

  const handleCompanyChange = (selectedOption) => {
    setCompany(selectedOption);
  };

  const customStyleSelect = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minWidth: "320px",
    }),
    menu: (provided) => ({
      ...provided,
      wordWrap: "break-word",
    }),
    option: (provided) => ({
      ...provided,
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    }),
  };

  const headers = [
    { label: "Visitor Name", key: "name" },
    { label: "Contact Number", key: "phonenumber" },
    { label: "Email", key: "email" },
    { label: "Created Date", key: "created_on" },
    { label: "Status", key: "is_visited" },
    ...(role_name === "Company Admin" || role_name === "Company User"
      ? [{ label: "Created By", key: "created_by" }]
      : []),
  ];

  const exportToCSV = async () => {
    setFilteredData([]); // Clear data to avoid stale data issues
    try {
      const response = await passSearch(1, totalItems, searchUrlNew);
      const data = response?.data?.data?.paginated_data?.results || [];

      // Update the state with the fetched data
      setFilteredData(data);

      // Process the data after the state has been updated
      processFilteredData(data);
    } catch (error) {
    }
  };

  const processFilteredData = async (data) => {
    // Filter the data to include only the columns defined in headers
    const filteredExportData = data.map((item) => {
      const newItem = {};
      headers.forEach((header) => {
        if (header.key === "is_visited") {
          newItem[header.key] =
            item[header.key] === true ? "Visited" : "Not Visited";
        } else {
          newItem[header.key] = item[header.key];
        }
      });
      return newItem;
    });


    // Construct additional filter details
    const filterDetails = [];

    // Add filter details based on selected IDs
    if (searchTerms.parkId) {
      const park = itparkList.find((item) => item.id == searchTerms.parkId);
      if (park) filterDetails.push({ label: "Park", value: park.parkName });
    }
    if (searchTerms.phaseId) {
      const phase = itphaseList.find(
        (item) => item.phaseId == searchTerms.phaseId
      );
      if (phase) filterDetails.push({ label: "Phase", value: phase.phaseName });
    }
    if (searchTerms.buildingId) {
      const building = itbuildingList.find(
        (item) => item.buildingId == searchTerms.buildingId
      );
      if (building)
        filterDetails.push({ label: "Building", value: building.buildingName });
    }
    if (searchTerms.companyId) {
      const company = itcompanyList.find(
        (item) => item.id == searchTerms.companyId
      );
      if (company)
        filterDetails.push({ label: "Company", value: company.name });
    }

    filterDetails.push({
      label: "Pass Generated",
      value: resCount?.visited_count + resCount?.not_visited_count || 0,
    });
    filterDetails.push({
      label: "Pass Visited",
      value: resCount?.visited_count || 0,
    });


    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Generated Passes");

    // Add report date and range sentence
    const startDateFormat = new Date(searchTerms.startDate).toLocaleDateString(
      "en-GB"
    );
    const endDateFormat = new Date(searchTerms.endDate).toLocaleDateString(
      "en-GB"
    );

    const reportSentence = `Pass Report (${searchTerms.startDate} to ${searchTerms.endDate})`;

    // Add the main heading with background color
    const titleRow = worksheet.addRow([reportSentence]);
    titleRow.font = { size: 14, bold: true };
    titleRow.alignment = { horizontal: "center" };

    // Merge the cells for the main heading and apply background color
    worksheet.mergeCells(`A1:${String.fromCharCode(65 + headers.length - 1)}1`);
    const mergedCell = worksheet.getCell("A1");
    mergedCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ADD8E6" }, // Light blue background
    };
    mergedCell.alignment = { horizontal: "center" };

    // Add empty rows with merge
    worksheet.mergeCells("A2:F2");

    // Add filter details with label in bold and value in the second column
    filterDetails.forEach((detail) => {
      const detailRow = worksheet.addRow([detail.label, detail.value]);
      detailRow.getCell(1).font = { bold: true };
      detailRow.alignment = { horizontal: "left" };
    });
    worksheet.addRow([]);

    // Add column headings
    const headerRow = worksheet.addRow(headers.map((header) => header.label));
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "90EE90" },
      };
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    // Add a blank row between column headings and data rows
    worksheet.addRow([]);

    // Add data rows
    filteredExportData.forEach((item) => {
      const row = worksheet.addRow(headers.map((header) => item[header.key]));
    });

    // Set column widths
    headers.forEach((header, index) => {
      worksheet.getColumn(index + 1).width = 30;
    });

    // Generate Excel file and download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, "report.xlsx");
  };

  return (
    <React.Fragment>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex flex-md-row align-items-center justify-content-between">
          <div className="align-self-start w-100">
            <div className="d-flex gap-2">
              <i
                className="ri-arrow-left-line"
                onClick={handleGoBack}
                style={{
                  marginRight: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              ></i>
              <div className="py-1 w-100">
                <div className="d-flex flex-column flex-md-row justify-content-between mb-1">
                  <div className="mb-3 mb-md-0">
                    <ol className="breadcrumb fs-sm mb-1">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard/generic">
                          <span>Go back</span>
                        </Link>
                      </li>
                    </ol>
                    <h4 className="main-title mb-0">Generated Passes</h4>
                  </div>
                  <div className="d-flex flex-column flex-md-row align-items-start gap-3">
                    <div className="earning-item mb-3 mb-md-0 d-flex gap-2">
                      <div className="earning-icon bg-twitter">
                        <i className="ri-file-list-2-line"></i>
                      </div>
                      <div className="d-flex flex-column">
                        <h4>
                          {resCount?.visited_count +
                            resCount?.not_visited_count || 0}
                        </h4>
                        <label>Pass Generated</label>
                      </div>
                    </div>

                    <div className="earning-item d-flex gap-2">
                      <div className="earning-icon bg-success">
                        <i className="ri-team-line"></i>
                      </div>
                      <div className="d-flex flex-column">
                        <h4>{resCount?.visited_count || 0}</h4>
                        <label>Pass Visited</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0 flex-wrap"></div>
        </div>

        <Row className="g-3">
          <Col xs="12">
            <Card
              className="card-one"
              style={{
                overflow: "hidden",
                position: "relative",
                minHeight: "700px",
              }}
            >
              <Card.Body>
                <Row className="g-3 mb-4 align-items-end">
                  <Col
                    xs={12}
                    className="d-flex justify-content-between flex-wrap"
                  >
                    <div className="d-flex flex-grow-1 flex-wrap">
                      {/* IT Park */}
                      {role_name !== "Park Admin" &&
                        role_name !== "Phase Admin" &&
                        role_name !== "Company Admin" &&
                        role_name !== "Company User" && (
                          <div
                            className="me-2 mb-2"
                            style={{ minWidth: "200px" }}
                          >
                            <Form.Group className="d-flex flex-column align-items-start">
                              <Form.Label className="w-100 text-start">
                                IT Park
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                as="select"
                                name="parkId"
                                value={searchTerms.parkId}
                                onChange={handleSearchChange}
                                styles={customStyleSelect}
                              >
                                <option value="all">All</option>
                                {itparkList === undefined && (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                                {Array.isArray(itparkList) &&
                                  itparkList?.map((item, index) => (
                                    <option key={index} value={item?.id}>
                                      {item?.parkName}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Form.Group>
                          </div>
                        )}

                      {/* Phase */}
                      {role_name !== "Phase Admin" &&
                        role_name !== "Company Admin" &&
                        role_name !== "Company User" && (
                          <div
                            className="me-2 mb-2"
                            style={{ minWidth: "200px" }}
                          >
                            <Form.Group className="d-flex flex-column align-items-start">
                              <Form.Label className="w-100 text-start">
                                Phase
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                as="select"
                                name="phaseId"
                                value={searchTerms.phaseId}
                                onChange={handleSearchChange}
                                styles={customStyleSelect}
                              >
                                <option value="all">All</option>
                                {itphaseList === undefined && (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                                {Array.isArray(itphaseList) &&
                                  itphaseList?.map((item, index) => (
                                    <option key={index} value={item?.phaseId}>
                                      {item?.phaseName}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Form.Group>
                          </div>
                        )}
                      {/* Building */}
                      {role_name !== "Company Admin" &&
                        role_name !== "Company User" && (
                          <div
                            className="me-2 mb-2"
                            style={{ minWidth: "200px" }}
                          >
                            <Form.Group className="d-flex flex-column align-items-start">
                              <Form.Label className="w-100 text-start">
                                Building
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                as="select"
                                name="buildingId"
                                value={searchTerms.buildingId}
                                onChange={handleSearchChange}
                                styles={customStyleSelect}
                              >
                                <option value="all">All</option>
                                {itbuildingList === undefined && (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                                {Array.isArray(itbuildingList) &&
                                  itbuildingList?.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item?.buildingId}
                                    >
                                      {item?.buildingName}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Form.Group>
                          </div>
                        )}
                      {/* Company */}
                      {role_name !== "Company Admin" &&
                        role_name !== "Company User" && (
                          <div
                            className="me-2 mb-2"
                            style={{ minWidth: "200px" }}
                          >
                            <Form.Group className="d-flex flex-column align-items-start">
                              <Form.Label className="w-100 text-start">
                                Company
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                as="select"
                                name="companyId"
                                value={searchTerms.companyId}
                                onChange={handleSearchChange}
                                styles={customStyleSelect}
                              >
                                <option value="all">All</option>
                                {itcompanyList === undefined && (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                                {Array.isArray(itcompanyList) &&
                                  itcompanyList?.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item?.id}
                                      className={
                                        item.is_active
                                          ? ""
                                          : "text-muted-italic"
                                      }
                                    >
                                      {item?.name}
                                      {!item.is_active && ` (In Active)`}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Form.Group>
                          </div>
                        )}
                    </div>

                    {/* Export Button */}
                    <div
                      className="d-flex justify-content-end"
                      style={{ paddingTop: "24px" }}
                    >
                      <Button
                        onClick={exportToCSV}
                        className="btn btn-primary"
                        style={{ height: "40px" }}
                      >
                        Export
                      </Button>
                    </div>
                  </Col>
                </Row>
                {/* )} */}

                <Table
                  responsive
                  hover
                  style={{ overflow: "hidden", position: "relative" }}
                >
                  <thead>
                    <tr>
                      <th className="fixed-width-last">Visitor Name</th>
                      <th className="fixed-width-last">Contact Number</th>
                      <th className="fixed-width-last">Email</th>
                      {(role_name === "Company Admin" ||
                        role_name === "Company User") && (
                        <th className="fixed-width">Created By</th>
                      )}
                      <th className="daterangepicker-width">Created Date</th>
                      <th className="fixed-width-last">Status</th>
                      {(role_name === "Company Admin" ||
                        role_name === "Company User") && (
                        <th className="fixed-width-last"></th>
                      )}
                    </tr>
                    <tr>
                      <th>
                        <div className="table-input-container">
                          <input
                            type="text"
                            name="name"
                            value={searchTerms.name}
                            onChange={handleSearchChange}
                            style={{
                              ...inputStyle,
                              border: isActive
                                ? "0.15rem solid #2684ff"
                                : "1px solid #ced4da",
                            }}
                          />
                          {searchTerms.name && (
                            <button
                              type="button"
                              className="clear-button"
                              onClick={() => clearSearchTerm("name")}
                              style={crossIconStyle}
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="table-input-container">
                          <input
                            type="text"
                            name="phone"
                            value={searchTerms.phone}
                            onChange={handleSearchChange}
                            onFocus={handleFocusContact}
                            onBlur={handleBlurContact}
                            style={{
                              ...inputStyle,
                              border: isActiveContact
                                ? "0.15rem solid #2684ff"
                                : "1px solid #ced4da",
                            }}
                          />
                          {searchTerms.phone && ( // Render clear button only if there's a search term
                            <button
                              type="button"
                              className="clear-button"
                              onClick={() => clearSearchTerm("phone")}
                              style={crossIconStyle}
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="table-input-container">
                          <input
                            type="text"
                            name="email"
                            value={searchTerms.email}
                            onChange={handleSearchChange}
                            onFocus={handleFocusEmail}
                            onBlur={handleBlurEmail}
                            style={{
                              ...inputStyle,
                              border: isActiveEmail
                                ? "0.15rem solid #2684ff"
                                : "1px solid #ced4da",
                            }}
                          />
                          {searchTerms.email && ( // Render clear button only if there's a search term
                            <button
                              className="clear-button"
                              onClick={() => clearSearchTerm("email")}
                              style={crossIconStyle}
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </th>
                      {(role_name === "Company Admin" ||
                        role_name === "Company User") && (
                        <th className="createSelect">
                          <div className="table-input-container">
                            <Form.Group>
                              <Form.Select
                                aria-label="Default select example"
                                as="select"
                                name="createdBy"
                                value={searchTerms.createdBy}
                                onChange={handleSearchChange}
                                style={selectStyle}
                              >
                                <option value="">Select Users</option>
                                {userDropDown === undefined && (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                                {Array.isArray(userDropDown) &&
                                  userDropDown?.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item?.user__id}
                                      className={
                                        item.is_active
                                          ? ""
                                          : "text-muted-italic"
                                      }
                                    >
                                      {item?.user__first_name}
                                      {!item.is_active && ` (In Active)`}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Form.Group>
                            {searchTerms.createdBy && ( // Render clear button only if there's a search term
                              <button
                                className="clear-button"
                                onClick={() => clearSearchTerm("createdBy")}
                                style={crossIconSelectStyle}
                              >
                                &times;
                              </button>
                            )}
                          </div>
                        </th>
                      )}
                      <th>
                        <div style={{ position: "relative", maxWidth: "100%" }}>
                          <DateRangePicker
                            className="w-150"
                            style={{ minWidth: "235px", position: "relative" }}
                            ranges={[dateRange]}
                            value={[dateRange.startDate, dateRange.endDate]}
                            onChange={handleDateChange}
                            format="dd/MM/yyyy"
                            placeholder="Select Date Range"
                            showOneCalendar
                          />
                        </div>
                      </th>
                      <th className="createSelect">
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            width: "100%",
                          }}
                        >
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              as="select"
                              name="visited"
                              value={searchTerms.visited}
                              onChange={handleSearchChange}
                              style={selectStyle}
                            >
                              <option value="">All</option>
                              <option value="1">Visited</option>
                              <option value="0">Not Visited</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100px" }}
                          >
                            <span
                              className="spinner-border spinner-border-lg text-primary"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    )}
                    {Array.isArray(passList) && passList.length > 0 && !loading
                      ? passList.map((item, index) => (
                          <tr key={index}>
                            <td
                              onClick={() => handleShow(item)}
                              className="text-primary fixed-width-last"
                              style={{
                                ...customStyleWrap,
                                cursor: "pointer",
                              }}
                            >
                              {item?.name}
                            </td>
                            <td
                              className="fixed-width-last"
                              style={customStyleWrap}
                            >
                              {item?.phonenumber}
                            </td>
                            <td
                              className="fixed-width-last"
                              style={customStyleWrap}
                            >
                              {item?.email}
                            </td>
                            {(role_name === "Company Admin" ||
                              role_name === "Company User") && (
                              <td
                                className="fixed-width"
                                style={customStyleWrap}
                              >
                                {item?.created_by}
                              </td>
                            )}
                            <td className="fixed-width" style={customStyleWrap}>
                              {item.created_on}
                            </td>
                            {item.is_visited === true ? (
                              <td
                                className="fixed-width-last"
                                style={customStyleWrap}
                              >
                                Visited{" "}
                              </td>
                            ) : (
                              <td
                                className="fixed-width-last"
                                style={customStyleWrap}
                              >
                                Not Visited{" "}
                              </td>
                            )}
                            {(role_name === "Company Admin" ||
                              role_name === "Company User") && (
                              <td
                                className="fixed-width-last"
                                style={customStyleWrap}
                              >
                                {item.show_cancel_btn === true ? (
                                  <Button
                                    variant="primary"
                                    onClick={() => handleCancel(item.id)}
                                    style={{ minWidth: "100px" }}
                                  >
                                    Cancel
                                  </Button>
                                ) : item.status === 2 ? (
                                  <span
                                    style={{
                                      textWrap: "nowrap",
                                      cursor: "default",
                                    }}
                                  >
                                    <strong>Cancelled</strong>
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "red",
                                      textWrap: "nowrap",
                                      cursor: "default",
                                    }}
                                  >
                                    Expired
                                  </span>
                                )}
                              </td>
                            )}
                          </tr>
                        ))
                      : !loading && (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
                {selectedVisitor && (
                  <VisitorDetailsModal
                    show={show}
                    handleClose={handleClose}
                    visitor={selectedVisitor}
                    sourcePage="pass" // Pass the source page here
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ConfirmationModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onConfirm={handleConfirm}
          message="Are you sure you want to cancel the pass?"
        />
      </div>
      <div>
        {!loading && passList?.length > 0 && (
          <Pagination className="pagination-space justify-content-start pagination-filled  p-3 p-lg-4">
            <Pagination.Prev
              onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {displayedPages.map((number) => (
              <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => onPageChange(number)}
              >
                {number}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                currentPage < totalPages && onPageChange(currentPage + 1)
              }
              disabled={currentPage === totalPages}
            />
          </Pagination>
        )}
      </div>
    </React.Fragment>
  );
}
