import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Table from 'react-bootstrap/Table';
import { Button, Card, Col, Row, Nav } from "react-bootstrap";

import { park_log,admin_log } from "../data/CalendarEvents";
import { useNavigate } from "react-router-dom";

import { Link } from 'react-router-dom';



export default function StorageManagement(){
  const [parkLogs, setParkLogs] = useState(park_log);
  const [adminLogs, setAdminLogs] = useState(admin_log);

  
  	// Skin switch logic
	const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : '';
	const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    document.title='Entry Pass'
    switchSkin(skin);
  }, [skin]);

  // Function to switch skin mode
	const switchSkin = (skin) => {
		if (skin === 'dark') {
			const btnWhite = document.getElementsByClassName('btn-white');
			for (const btn of btnWhite) {
				btn.classList.add('btn-outline-primary');
				btn.classList.remove('btn-white');
			}
		} else {
			const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');
			for (const btn of btnOutlinePrimary) {
				btn.classList.remove('btn-outline-primary');
				btn.classList.add('btn-white');
			}
		}
	};


  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/dashboard/addpark");
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0"><b>IT Park-Super Admin</b></h4>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0 flex-wrap">
         
            <Button as={Link} to="/dashboard/create-building" className="d-flex align-items-center gap-2 bg-primary text-white hover-translate">
  Create Building
</Button>
<Button as={Link} to="/dashboard/generic" className="d-flex align-items-center gap-2 bg-primary text-white hover-translate">
              Dashboard
            </Button>
            <Button as={Link} to="/dashboard/gate-user" className="d-flex align-items-center gap-2 bg-primary text-white hover-translate">
               Gate User Dashboard
            </Button>
<Button as={Link} to="/dashboard/create-company" className="d-flex align-items-center gap-2 bg-primary text-white hover-translate">
  Create Company
</Button>
          
            <Button as={Link} to="/dashboard/create-phase" className="d-flex align-items-center gap-2 bg-primary text-white hover-translate">

              Create Phase
            </Button>
         
             <Button as={Link} to="/dashboard/create-phase-admin" className="d-flex align-items-center gap-2 bg-primary text-white hover-translate">
              Create Phase Admin
            </Button>


          </div>
        </div>
        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Park Logs</Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>Park Name</th>
                      <th>Location</th>
                      <th>Address</th>
                      <th>Created Date</th>
                      <th>Created Time</th>
                      <th>Modified Date</th>
                      <th>Modified Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parkLogs.map((item, index) => (
                      <tr key={index}>
                        <td>{item.Parkname}</td>
                        <td>{item.location}</td>
                        <td>{item.address}</td>
                        <td>{item.createddate}</td>
                        <td>{item.createdtime}</td>
                        <td>{item.modifieddate}</td>
                        <td>{item.modifiedtime}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Park Admin Logs</Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>Admin Name</th>
                      <th>Park</th>
                      <th>Email ID</th>
                      <th>Mobile Number</th>
                      <th>Created Date</th>
                      <th>Created Time</th>
                      <th>Modified Date</th>
                      <th>Modified Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminLogs.map((item, index) => (
                      <tr key={index}>
                        <td>{item.admin_name}</td>
                        <td>{item.park}</td>
                        <td>{item.email}</td>
                        <td>{item.mobile}</td>
                        <td>{item.created_date}</td>
                        <td>{item.created_time}</td>
                        <td>{item.modified_date}</td>
                        <td>{item.modified_time}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </React.Fragment>
  );
};

