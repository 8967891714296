import { axiosInstance } from "./axiosInstance";

//Super admin dashboard
export const dashboardGraph = (park_id, year) => {
    return axiosInstance.get('/api/pass/graph?park_id=' + park_id +'&year=' + year);
    };

//Park Admin dashboard
export const dashboardParkGraph = (phase_id, year) => {
    return axiosInstance.get('/api/pass/graph?phase_id=' + phase_id +'&year=' + year);
    };

//Phase Admin dashboard
export const dashboardPhaseGraph = (building_id, year) => {
    return axiosInstance.get('/api/pass/graph?building_id=' + building_id +'&year=' + year);
    };

//Company dashboard
export const dashboardCompanyGraph = (comapany_user_id, year) => {
    return axiosInstance.get('/api/pass/graph?company_user_id=' + comapany_user_id +'&year=' + year);
    };

//Graph dashboard passing only year
export const dashboardGraphWithYear = (year) => {
    return axiosInstance.get('/api/pass/graph?year=' + year);
    };