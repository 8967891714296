import React, { useEffect, useState, useRef } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Form, Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getITParkList } from "../../services/Park";
import {
  getITPhaseList,
  getBuildingListSelect,
  createCompany
} from "../../services/Phase";
import { showToast } from "../shared/Toaster";
import { useSelector } from "react-redux";
import { 
	updateCompany,
	getCompanyInfo
 } from "../../services/Company";

export default function CreateCompany() {
  //   const ParkAdminLocation = { location: "infopark kochi", status: true };
  const BuildingList = [
    { id: 1, name: "Athulya ", owns: false, floor: 6 },
    { id: 2, name: "Thapasya", owns: false, floor: 5 },
    { id: 3, name: "TCS", owns: true, floor: 10 },
    { id: 4, name: "Vismaya", owns: false, floor: 12 },
    { id: 5, name: "Lulu Tower 1", owns: true, floor: 7 },
    { id: 6, name: "Lulu Tower 2", owns: true, floor: 15 },
  ];

  const phaseId = useSelector((state) => state.phase.phaseId);
  const parkId = useSelector((state) => state.park.parkId);
  const BuildingId = useSelector((state) => state.building.BuildingId);

  const [companyname, setCompanyName] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [contactname, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [altemail, setAltEmail] = useState("");
  const [mobnumber, setMobNumber] = useState("");
  const [selectbuilding, setSelectBuilding] = useState("");
  const [selectFloor, setSelectFloor] = useState("");
  const [showFloorField, setShowFloorField] = useState(false);
  const [companylocation, setLocation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State variable to manage password visibility
  const [confirmPassword, setConfirmPassword] = useState("");
  const [availableFloors, setAvailableFloors] = useState([]);

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  //  for error messages
  const [companynameError, setCompanyNameError] = useState("");
  const [usernameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [contactnameError, setContactNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [altemailError, setAltEmailError] = useState("");
  const [mobnumberError, setMobNumberError] = useState("");
  const [companybuildingError, setCompanyBuildingError] = useState("");
  const [selectfloorError, setSelectFloorError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [itparkError, setITParkError] = useState("");
  const [phaseError, setPhaseError] = useState("");

  const [building, setBuilding] = useState(BuildingId);

  const role_name = localStorage.getItem("role_name");

  const defaultITPark =
    role_name === "Super Admin" ? parkId : localStorage.getItem("userPark");
  const defaultPhase =
    role_name === "Super Admin" || role_name === "Park Admin"
      ? phaseId
      : localStorage.getItem("userPhase");

  const [itpark, setITPark] = useState(defaultITPark);
  const [phase, setPhase] = useState(defaultPhase);

  //For IT Park List
  const [itparkList, setITParkList] = useState("");
  //For Phase List
  const [itphaseList, setITPhaseList] = useState("");
  const [itbuildingList, setITBuildingList] = useState("");
  const [loading, setLoading] = useState(false);
  const [roleName, setRoleName] = useState("");

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const companyId = query.get("id");

  //For getting the company details in the case of edit company
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await getCompanyInfo(companyId);
        const company_details = response.data.data.results[0];
        if (response) {
          setCompanyName(company_details?.company_name);
          setContactName(company_details?.contactName);
          setEmail(company_details?.email);
          setAltEmail(company_details?.alternateEmail);
          setMobNumber(company_details?.contactNumber);
          setSelectFloor(company_details?.floor);
          setLocation(company_details?.location)
        }
      } catch (error) {
      }
    };
    fetchCompanyDetails();
  }, [companyId]);

  const handleGoBack = () => {
    navigate("/dashboard/building-details");
  };

  useEffect(() => {
    if (!companyId) {
      document.title = "Create Company";
    } else {
      document.title = "Edit Company";
    }
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    const role_name = localStorage.getItem("role_name");
    if (role_name == "Super Admin") {
      setRoleName("Super Admin");
    } else if (role_name == "Park Admin") {
      setRoleName("Park Admin");
    } else if (role_name == "Phase Admin") {
      setRoleName("Phase Admin");
    }
    const fetchITParkList = async () => {
      if (role_name == "Super Admin") {
        try {
          const response = await getITParkList(); // Assuming getITParkList is an async function that fetches data
          setITParkList(response.data.data.park_list.results); // Store the data in state
        } catch (error) {
        }
      } else {
        //Here we have to fetch the park id from user information if any other role is logging
        //Now we have no park information so I am giving infopark kochis id as default
        const data = [
          {
            id: localStorage.getItem("userPark"),
            parkName: localStorage.getItem("userParkName"),
          },
        ];
        setITParkList(data);
      }
    };

    const fetchITPhaseList = async (park_id) => {
      if (role_name == "Phase Admin") {
        const data = [
          {
            id: localStorage.getItem("userPhase"),
            PhaseName: localStorage.getItem("userPhaseName"),
            IT_PARK: localStorage.getItem("userParkName"),
            IT_PARK_Id: localStorage.getItem("userPark"),
          },
        ]; //Mock data for park
        setITPhaseList(data);
      } else {
        try {
          const response = await getITPhaseList(park_id); // Assuming getITParkList is an async function that fetches data
          setITPhaseList(response.data.data.phase_list.results); // Store the data in state
        } catch (error) {
        }
      }
    };

    const fetchITBuildingList = async (phase_id) => {
      try {
        const response = await getBuildingListSelect(phase_id); // Assuming getITParkList is an async function that fetches data
        setITBuildingList(response.data.data.Building_list.results); // Store the data in state
        const selectedBuildingId = parseInt(BuildingId, 10); // Convert value to number
        setBuilding(selectedBuildingId);

        const selectedBuilding = response.data.data.Building_list.results?.find(
          (item) => item.id === selectedBuildingId
        );
        if (selectedBuilding) {
          const floors = [...Array(selectedBuilding.numberOfFloors + 1).keys()];
          setAvailableFloors(floors);
        } else {
          setAvailableFloors([]);
        }
      } catch (error) {
      }
    };

    fetchITParkList();
    fetchITPhaseList(itpark);
    fetchITBuildingList(phase);
    switchSkin(skin);
  }, [skin, itpark, phase]);

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const handleCompanyNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setCompanyName(value);
      const error = validateCompanyName(value);
      setCompanyNameError(error);
      const modifiedCompanyName = value.trim().replace(/[^a-zA-Z0-9]/g, "");
      const suggestion = modifiedCompanyName.slice(0, 8);
      setUserName(suggestion);
      const usernameError = validateUserName(suggestion);
      setUserNameError(usernameError);
    }
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    setLocation(value);

    const error = validateLocation(value);
    setLocationError(error);
  };

  const handleUserNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setUserName(value);
      const error = validateUserName(value);
      setUserNameError(error);
    }
  };

  // Event handler for password change
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    if (value.length <= 20) {
      setPassword(value);
      const error = validatePassword(value);
      setPasswordError(error);
      if (confirmPassword && value !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handleConfirmPassword = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    const error = validateConfirmPassword(value);
    setConfirmPasswordError(error);
  };

  const handleContactName = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setContactName(value);
      const error = validateContactName(value);
      setContactNameError(error);
    }
  };
  const handleEmail = (event) => {
    const value = event.target.value;
    setEmail(value);
    const error = validateEmail(value);
    setEmailError(error);
  };
  const handleAltEmail = (event) => {
    const value = event.target.value;
    setAltEmail(value);
    if (value.trim()) {
      const error = validateAltEmail(value);
      setAltEmailError(error);
    } else {
      setAltEmailError("");
    }
  };
  const handleMobNumber = (event) => {
    const value = event.target.value;
    if (value.length <= 14) {
      setMobNumber(value);
      const error = validateMobileNumber(value);
      setMobNumberError(error);
    }
  };

  const handleFloorChange = (event) => {
    const floor = event.target.value;
    setSelectFloor(floor);
    const error = validateSelectFloor(floor);
    setSelectFloorError(error);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Event handler for IT Park change
  const handleITParkChange = async (event) => {
    const value = event.target.value;
    setITPark(value);
    const error = validateITPark(value);
    setITParkError(error);
    setITPhaseList([]);
    setITBuildingList([]);
    setPhase("");

    if (!error) {
      try {
        const response = await getITPhaseList(value); // Assuming getITPhaseList fetches phase data for the selected park
        if (response.data.data.phase_list.results.length > 0) {
          setITPhaseList(response.data.data.phase_list.results);
        }
      } catch (error) {
        setITBuildingList([]);
        setITPhaseList([]);

        setBuilding("");
        setPhase("");
      }
    }
  };

  // Event handler for phase change
  const handlePhaseChange = async (event) => {
    const value = event.target.value;
    setPhase(value);
    const error = validatePhase(value);
    setPhaseError(error);
    try {
      const response = await getBuildingListSelect(value); // Assuming this function fetches building data
      if (response.data.data.Building_list.results.length > 0) {
        setITBuildingList(response.data.data.Building_list.results);
      } else {
        setITBuildingList([]); // or setITBuildingList(undefined);
      }
    } catch (error) {
      setITBuildingList([]);
      setBuilding(""); // Handle error case by setting an appropriate value
    }
  };

  // Event handler for building change
  const handleBuildingChange = (event) => {
    const value = event.target.value;
    setBuilding(value);
    const error = validateCompanyBuilding(value);
    setCompanyBuildingError(error);

    const selectedBuildingId = parseInt(event.target.value, 10); // Convert value to number
    setBuilding(selectedBuildingId);

    const selectedBuilding = itbuildingList.find(
      (item) => item.id === selectedBuildingId
    );
    if (selectedBuilding) {
      const floors = [...Array(selectedBuilding.numberOfFloors + 1).keys()];
      setAvailableFloors(floors);
      setSelectFloor(""); // Reset floor selection
    } else {
      setAvailableFloors([]);
    }
  };

  // Function to validate building name
  const validateCompanyName = (value) => {
    if (!value.trim()) {
      return "Company name is required";
    }
    if (value.length > 100) {
      return "Company name must not exceed 100 characters";
    }
    return "";
  };

  const validateUserName = (value) => {
    if (!value.trim()) {
      return "Username is required";
    }
    if (!/^[a-zA-Z0-9]+$/.test(value)) {
      return "Username can only contain alphanumeric characters";
    }
    if (/^\d/.test(value)) {
      return "Username should not start with a number";
    }
    if (value.length > 100) {
      return "Username must not exceed 100 characters";
    }
  };

  const validatePassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@$&])/.test(value)) {
      return "Password must contain at least one uppercase letter, one lowercase letter, one numeric digit, and one special character (@, $, !, & etc.)";
    }

    if (value.length <= 8) {
      return "Password length must be greater than 8 characters";
    }

    if (value.length > 20) {
      return "Password must not exceed 20 characters";
    }

    return "";
  };
  const validateConfirmPassword = (value) => {
    if (!value.trim()) {
      return "Password is required";
    }

    if (value !== password) {
      return "Passwords do not match";
    }

    return "";
  };

  const validateContactName = (value) => {
    if (!value.trim()) {
      return "Contact name is required";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Contact name must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return "Contact name must not exceed 100 characters";
    }
  };
  const validateEmail = (value) => {
    if (!value.trim()) {
      return "HR email id is required";
    }

    const emailRegex =
      /^(?!.*\.\.)(?!.*\.[0-9])[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(value)) {
      return "Please enter a valid email id";
    }
  };
  const validateAltEmail = (value) => {
   // Check if the value is empty
   if (value == null || value.trim() === "") {
    return ""; // Return empty string if value is empty
  }
  // // Regular expression for email validation
  const emailRegex =
    /^(?!.*\.\.)(?!.*\.[0-9])[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;

  // Check if the value matches the email regex
  if (!emailRegex.test(value)) {
    return "Please enter a valid alternate email id";
  }
  return "";
  };

  // Function to validate mobile number
  const validateMobileNumber = (value) => {
    // Check if the value is empty
    if (!value.trim()) {
      return "Contact number is required";
    }
    const numericRegex = /^[0-9]+$/;
    const noConsecutiveSameDigitRegex = /(.)\1{9}/;

    if (!numericRegex.test(value)) {
      return "Contact number must contain only numeric characters";
    }
    if (noConsecutiveSameDigitRegex.test(value)) {
      return "Continuous sequence of 10 same numbers is not allowed";
    }

    if (value.length > 14) {
      return "Contact number must not exceed 14 characters";
    }
    if (value.length < 10) {
      return "Contact number must contain at least 10 numeric characters";
    }

    return "";
  };

  const validateCompanyBuilding = (value) => {
    if (!value || value === "undefined") {
      return "Building is required";
    }
    return "";
  };
  const validateSelectFloor = (value) => {
    if (!value || value === "undefined") {
      return "Floor is required";
    }
  };
  const validateLocation = (value) => {
    if (!value || value === "undefined") {
      return "Location is required";
    }
    return "";
  };

  const validateITPark = (value) => {
    if (!value || value === "undefined") {
      return "IT Park is required";
    }
    return "";
  };

  const validatePhase = (value) => {
    if (!value || value === "undefined") {
      return "Phase is required";
    }
    return "";
  };

  // Submit handler
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!companyId) {
		// Validate all fields
		const CompanyNameError = validateCompanyName(companyname);
		const UserNameError = validateUserName(username);
		const PasswordError = validatePassword(password);
		const confirmPasswordError = validateConfirmPassword(confirmPassword);
		const ContactNameError = validateContactName(contactname);
		const EmailError = validateEmail(email);
		const AltEmailError = validateAltEmail(altemail);
		const MobileNumberError = validateMobileNumber(mobnumber);
		const CompanyBuildingError = validateCompanyBuilding(building);
		const SelectFloorError = validateSelectFloor(selectFloor);
		const locationError = validateLocation(companylocation);
		const itparkError = validateITPark(itpark);
		const phaseError = validatePhase(phase);
	
	
		// Set errors for each field
		setCompanyNameError(CompanyNameError);
		setUserNameError(UserNameError);
		setPasswordError(PasswordError);
		setConfirmPasswordError(confirmPasswordError);
		setContactNameError(ContactNameError);
		setEmailError(EmailError);
		setAltEmailError(AltEmailError);
		setMobNumberError(MobileNumberError);
		setCompanyBuildingError(CompanyBuildingError);
		setSelectFloorError(SelectFloorError);
		setLocationError(locationError);
		setITParkError(itparkError);
		setPhaseError(phaseError);
	
		if (
		  CompanyNameError ||
		  UserNameError ||
		  passwordError ||
		  confirmPasswordError ||
		  contactnameError ||
		  EmailError ||
		  AltEmailError ||
		  MobileNumberError ||
		  companybuildingError ||
		  selectfloorError ||
		  locationError ||
		  phaseError
		) {
		  return;
		} else {
		  try {
			// Proceed with form submission
			setLoading(true);
	
			const formData = {
			  companyName: companyname,
			  companyLocation: companylocation,
			  companyBuilding: building,
			  companyFloor: selectFloor,
			  username: username,
			  hrEmail: email,
			  contactName: contactname,
			  password: password,
			  mobileNumber: mobnumber,
			};
	
			// Conditionally add alternate email if it's not empty
			if (altemail.trim()) {
			  formData.alternateEmail = altemail;
			}
	
			// Proceed with form submission
			const response = await createCompany(formData);
			setLoading(false);
			setCompanyName("");
			setLocation("");
			setBuilding("");
			setSelectFloor("");
			setUserName("");
			setEmail("");
			setAltEmail("");
			setContactName("");
			setPassword("");
			setMobNumber("");
			setConfirmPassword("");
	
			if (roleName === "Super Admin") {
			  setPhase("");
			  setITParkList("");
			  setITPark("");
			} else if (roleName === "Park Admin") {
			  setPhase("");
			}
	
	
			showToast("success", response.data.message);
			navigate("/dashboard/building-details");
		  } catch (error) {
			// Handle submission error
			setLoading(false);
			if (error.response) {
			  showToast("error", error.response.data.message);
			} else {
			  showToast("error", "Internal server Error");
			}
		  }
		}
    //starting the case of edit
    }else {

		// Validate all fields
		const CompanyNameError = validateCompanyName(companyname);
		const ContactNameError = validateContactName(contactname);
		const EmailError = validateEmail(email);
		const AltEmailError = validateAltEmail(altemail);
		const MobileNumberError = validateMobileNumber(mobnumber);
		const CompanyBuildingError = validateCompanyBuilding(building);
		const SelectFloorError = validateSelectFloor(selectFloor);
		const locationError = validateLocation(companylocation);
		const itparkError = validateITPark(itpark);
		const phaseError = validatePhase(phase);
	
		// Set errors for each field
		setCompanyNameError(CompanyNameError);
		setContactNameError(ContactNameError);
		setEmailError(EmailError);
		setAltEmailError(AltEmailError);
		setMobNumberError(MobileNumberError);
		setCompanyBuildingError(CompanyBuildingError);
		setSelectFloorError(SelectFloorError);
		setLocationError(locationError);
		setITParkError(itparkError);
		setPhaseError(phaseError);
	
		if (
		  CompanyNameError ||
		  contactnameError ||
		  EmailError ||
		  AltEmailError ||
		  MobileNumberError ||
		  companybuildingError ||
		  selectfloorError ||
		  locationError ||
		  phaseError
		) {
		  return;
		} else {
		  try {
			// Proceed with form submission
			setLoading(true);
	
			const formData = {
			  companyName: companyname,
			  companyLocation: companylocation,
			  companyBuilding: building,
			  companyFloor: selectFloor,
			  hrEmail: email,
			  contactName: contactname,
			  mobileNumber: mobnumber,
			};
	
			// Conditionally add alternate email if it's not empty
			if (altemail && altemail.trim()) {
			  formData.alternateEmail = altemail;
			}
	
			// Proceed with form submission
			const response = await updateCompany(formData, companyId);
			setLoading(false);
			setCompanyName("");
			setLocation("");
			setBuilding("");
			setSelectFloor("");
			setEmail("");
			setAltEmail("");
			setContactName("");
			setMobNumber("");
	
			if (roleName === "Super Admin") {
			  setPhase("");
			  setITParkList("");
			  setITPark("");
			} else if (roleName === "Park Admin") {
			  setPhase("");
			}
	
	
			showToast("success", response.data.message);
			navigate("/dashboard/building-details");
		  } catch (error) {
			// Handle submission error
			setLoading(false);
			if (error.response) {
			  // if(error.reponse.data.message) {
			  showToast("error", error.response.data.message);
			  // }
			} else {
			  showToast("error", "Internal server Error");
			}
		  }
		}

	}

  };

  return (
    <div>
      <Header onSkin={setSkin} />
      <div className=" main main-app d-flex flex-column align-items-start p-3 p-lg-4">
        <div className="my-3 ps-md-3 align-self-start">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line "
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/building-details">
                    <span>Go back</span>
                  </Link>
                </li>
              </ol>
              {!companyId ? (
                <h4 className="main-title mb-0">Create Company</h4>
              ) : (
                <h4 className="main-title mb-0">Edit Company</h4>
              )}
            </div>
          </div>
        </div>

        <Col xs="12" md="12">
          <hr className="mb-5" />
          <Card.Body className="ps-md-3 " style={{ maxWidth: "1100px" }}>
            <Form onSubmit={handleSubmit}>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                  <div className="d-flex flex-column gap-2">
                    <Card.Title as="h6">
                      <b>Company Details</b>
                    </Card.Title>
                    <div style={{ maxWidth: "320px" }} className="text-muted">
                      Provide the necessary information to create a company.
                    </div>
                  </div>
                  {/* Company Name */}
                  <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter company name"
                          value={companyname}
                          onChange={handleCompanyNameChange}
                        />
                        {companynameError && (
                          <div className="text-danger">{companynameError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      {/* Location */}
                      <Form.Group>
                        <Form.Label>
                          Location <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Location"
                          value={companylocation}
                          onChange={handleLocationChange}
                        />
                        {locationError && (
                          <div className="text-danger">{locationError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          IT Park<span className="text-danger"></span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          as="select"
                          value={itpark}
                          onChange={handleITParkChange}
                          disabled={
                            roleName === "Phase Admin" ||
                            roleName === "Park Admin"
                          } // Disable the select if roleName is not set ( if role_name is Phase Admin )
                        >
                          <option value="">Select IT Park</option>
                          {itparkList.length === 0 && (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}
                          {Array.isArray(itparkList) &&
                            itparkList.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.parkName}
                              </option>
                            ))}
                        </Form.Select>

                        {itparkError && (
                          <div className="text-danger">{itparkError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          Phase<span className="text-danger"></span>
                        </Form.Label>

                        <Form.Select
                          aria-label="Default select example"
                          as="select"
                          value={phase}
                          onChange={handlePhaseChange}
                          disabled={roleName === "Phase Admin"} // Disable the select if roleName is not set ( if role_name is Phase Admin )
                        >
                          <option value="">Select Phase</option>
                          {itphaseList.length === 0 && (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}

                          {Array.isArray(itphaseList) &&
                            itphaseList.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.PhaseName}
                              </option>
                            ))}
                        </Form.Select>

                        {phaseError && (
                          <div className="text-danger">{phaseError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      {/* Building */}
                      <Form.Group>
                        <Form.Label>
                          Building <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          as="select"
                          value={building}
                          onChange={handleBuildingChange}
                        >
                          <option value="">Select building</option>
                          {itbuildingList.length === 0 && (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}
                          
                          {Array.isArray(itbuildingList) &&
                            itbuildingList.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.buildingName}
                              </option>
                            ))}
                        </Form.Select>
                        {companybuildingError && (
                          <div className="text-danger">
                            {companybuildingError}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      {/* Company Floor */}

                      <Form.Group>
                        <Form.Label>
                          Floor <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          value={selectFloor}
                          onChange={handleFloorChange}
                        >
                          <option value="">Select Floor</option>
                          {availableFloors.map((floor) => (
                            <option key={floor} value={floor}>
                              {floor}
                            </option>
                          ))}
                          
                        </Form.Select>
                        {selectfloorError && (
                          <div className="text-danger">{selectfloorError}</div>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>
                {!companyId && (
                  <div className="d-flex flex-column flex-md-row gap-md-5 gap-4 ">
                  <div className="d-flex flex-column gap-2">
                    <Card.Title as="h6">
                      <b>Company Credentials</b>
                    </Card.Title>
                    <div style={{ maxWidth: "320px" }} className="text-muted">
                      Create credentials for company admins to login to their
                      platform. Kindly ensure the security of your chosen
                      username and password.
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                    <div style={{ minWidth: "320px" }}>
                      {/* UserName */}
                      <Form.Group>
                        <Form.Label>
                          Username <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter username for company login"
                          value={username}
                          onChange={handleUserNameChange}
                        />
                        {usernameError && (
                          <div className="text-danger">{usernameError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      {/* Password */}
                      <Form.Group>
                        <Form.Label>
                          Password <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter passsword for company login"
                          value={password}
                          ref={passwordRef}
                          onChange={handlePasswordChange}
                        />
                        {showPassword ? (
                          <RiEyeOffFill
                            className="eye-icon-gate"
                            onClick={togglePasswordVisibility}
                          />
                        ) : (
                          <RiEyeFill
                            className="eye-icon-gate"
                            onClick={togglePasswordVisibility}
                          />
                        )}
                        {passwordError && (
                          <div
                            className="text-danger mb-3"
                            style={{ marginTop: "-20px", maxWidth: "480px" }}
                          >
                            {passwordError}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px", marginTop: "-18px" }}>
                      {/* Password */}
                      <Form.Group>
                        <Form.Label>
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Re-enter password for company login"
                          value={confirmPassword}
                          ref={confirmPasswordRef}
                          onChange={handleConfirmPassword}
                        />
                        {showConfirmPassword ? (
                          <RiEyeOffFill
                            className="eye-icon-gate"
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        ) : (
                          <RiEyeFill
                            className="eye-icon-gate"
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        )}
                        {confirmPasswordError && (
                          <div
                            className="text-danger mb-3"
                            style={{ marginTop: "-20px", maxWidth: "480px" }}
                          >
                            {confirmPasswordError}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>
                )}
                
                <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                  <div className="d-flex flex-column gap-2">
                    <Card.Title as="h6">
                      <b>Company Contact Details</b>
                    </Card.Title>
                    <div style={{ maxWidth: "320px" }} className="text-muted">
                      Update company contact details for future communications.
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                    <div style={{ minWidth: "320px" }}>
                      {/* Contact Name */}
                      <Form.Group>
                        <Form.Label>
                          Contact Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter contact name for the company"
                          value={contactname}
                          onChange={handleContactName}
                        />
                        {contactnameError && (
                          <div className="text-danger">{contactnameError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      {/* HR Email */}
                      <Form.Group>
                        <Form.Label className="form-label">
                          HR Email ID <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          type="email"
                          placeholder="Enter HR email id for company contact"
                          value={email}
                          onChange={handleEmail}
                        />
                        {emailError && (
                          <div className="text-danger">{emailError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      {/* Alternate Email */}
                      <Form.Group>
                        <Form.Label>
                          Alternate Email ID{" "}
                          <span className="text-danger"></span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter alternate email id for company contact"
                          value={altemail}
                          onChange={handleAltEmail}
                        />
                        {altemailError && (
                          <div className="text-danger">{altemailError}</div>
                        )}
                      </Form.Group>
                    </div>

                    <div style={{ minWidth: "320px" }}>
                      <Form.Group>
                        <Form.Label>
                          Contact Number<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter contact number for the company"
                          value={mobnumber}
                          onChange={handleMobNumber}
                        />
                        {mobnumberError && (
                          <div className="text-danger">{mobnumberError}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div style={{ minWidth: "320px" }}>
                      {companyId ? (
                        <Button
                          type="submit"
                          variant="primary"
                          className="btn-sign mt-4"
                          disabled={loading}
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Save"
                          )}
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="primary"
                          className="btn-sign mt-4"
                          disabled={loading}
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Create Company"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Col>
      </div>
      <Footer />
    </div>
  );
}
