import React, { useEffect, useState, useRef } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Form, Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { createPass, createBulkPass } from "../../services/Company";
import { showToast } from "../shared/Toaster";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { NonceProvider } from "react-select";
import sampleFormat from '../../assets/excel/sample-upload-format.xlsx';

export default function CreateNewPass() {
  const role_name = localStorage.getItem("role_name");
  const company_id = localStorage.getItem("companyId");
  const { key } = useParams();
  const [urlKey, setUrlKey] = useState("");
  const [loading, setLoading] = useState(false);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const [companyname, setCompanyName] = useState(() => {
    const storedCompany = localStorage.getItem("companyName");
    return storedCompany;
  });

  const [contactname, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [mobilenumber, setMobileNumber] = useState("");
  const [reason, setReason] = useState("");
  const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [idProof, setIdProof] = useState(null);
  const [bulkPassExcel, setBulkPassExcel] = useState(null);

  //  for error messages
  const [companynameError, setCompanyNameError] = useState("");
  const [contactnameError, setContactNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobilenumberError, setMobileNumberError] = useState("");
  const [startdateError, setStartDateError] = useState("");
  const [enddateError, setEndDateError] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [photoError, setPhotoError] = useState("");
  const [idProofError, setIdProofError] = useState("");
  const [bulkPassExcelError, setBulkPassExcelError] = useState("");

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/dashboard/generic"); // Navigate to the storage page
  };

  useEffect(() => {
    document.title = "Create New Pass";
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    console.log(key, "key & values");
    setUrlKey(key);
  });

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxFileSizeKB = 100; // Maximum file size allowed in kilobytes

      if (!validImageTypes.includes(file.type)) {
        setPhoto(null);
        setPhotoError("Only image files (jpeg, png) are allowed.");
        setTimeout(() => {
          setPhotoError("");
          document.getElementById("photoInput").value = "";
        }, 1000);
      } else if (file.size > maxFileSizeKB * 1024) {
        setPhoto(null);
        setPhotoError(`File size should be ${maxFileSizeKB} KB or less.`);
        setTimeout(() => {
          setPhotoError("");
          document.getElementById("photoInput").value = "";
        }, 1000);
      } else {
        setPhoto(file);
        setPhotoError(""); // Clear any previous error
      }
    } else {
      setPhoto(null);
    }
  };

  const handleIdProofChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      const maxFileSizeKB = 100; // Maximum file size allowed in kilobytes

      if (!validFileTypes.includes(file.type)) {
        setIdProof(null);
        setIdProofError(
          "Only image files (jpeg, png) and PDF files are allowed."
        );
        setTimeout(() => {
          setIdProofError("");
          document.getElementById("idProofInput").value = "";
        }, 1000);
      } else if (file.size > maxFileSizeKB * 1024) {
        setIdProof(null);
        setIdProofError(`File size should be ${maxFileSizeKB} KB or less.`);
        setTimeout(() => {
          setIdProofError("");
          document.getElementById("idProofInput").value = "";
        }, 1000);
      } else {
        setIdProof(file);
        setIdProofError(""); // Clear any previous error
      }
    } else {
      setIdProof(null);
    }
  };

  const handleBulkPassChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = [
        "application/vnd.ms-excel", // .xls files
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx files
      ];
      const maxFileSizeKB = 100; // Maximum file size allowed in kilobytes

      if (!validFileTypes.includes(file.type)) {
        setBulkPassExcel(null);
        setBulkPassExcelError("Only Excel files (.xls, .xlsx) are allowed.");
        setTimeout(() => {
          setBulkPassExcelError("");
          document.getElementById("bulkPassInput").value = "";
        }, 1000);
      } else if (file.size > maxFileSizeKB * 1024) {
        setBulkPassExcel(null);
        setBulkPassExcelError(
          `File size should be ${maxFileSizeKB} KB or less.`
        );
        setTimeout(() => {
          setBulkPassExcelError("");
          document.getElementById("bulkPassInput").value = "";
        }, 1000);
      } else {
        setBulkPassExcel(file);
        setBulkPassExcelError(""); // Clear any previous error
      }
    } else {
      setBulkPassExcel(null);
    }
  };

  const formatDate = (date) => {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
  };

  const today = new Date();
  const formattedToday = formatDate(today);

  const handleBulkPassUpload = async () => {
    if (!bulkPassExcel) {
      setBulkPassExcelError("Please upload a CSV file.");
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", bulkPassExcel); // Append bulk pass file

      // Proceed with bulk pass upload
      const response = await createBulkPass(formData);

      showToast("success", response.data.message);

	  navigate("/dashboard/pass-list", {
		state: {
		  start_date: formattedToday,
		  end_date: formattedToday,
		},
	  });

      // Clear the file input
      document.getElementById("bulkPassInput").value = "";
      setBulkPassExcel(null); // Clear the file state
    } catch (error) {
      // Handle submission error
      console.log(error);
	  // Clear the file input
      document.getElementById("bulkPassInput").value = "";
      setBulkPassExcel(null); // Clear the file state
      setLoading(false);
      if (error.response) {
        // Check if error.response.data.message is an array
        if (
          Array.isArray(error.response.data.message) &&
          error.response.data.message.length > 0
        ) {
          // Access the first message object
          const errorMessage = error.response.data.message[0].message;
          showToast("error", errorMessage);
        } else if (typeof error.response.data.message === "string") {
          // If message is a string
          showToast("error", error.response.data.message);
        } else if (error.response.status === 500) {
          showToast("error", error.response.statusText);
        } else {
          showToast("error", "An unknown error occurred.");
        }
      } else {
        showToast("error", "An unknown error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  const validateCompanyName = (value) => {
    if (!value.trim()) {
      return "Company name is required";
    }
    if (value.length > 100) {
      return "Company name must not exceed 100 characters";
    }

    return "";
  };

  const validateReason = (value) => {
    if (!value.trim()) {
      return "Reason for visit is required";
    }
    if (value.length > 100) {
      return "Reason must not exceed 100 characters";
    }
    return "";
  };

  const handleReasonChange = (event) => {
    const value = event.target.value;
    setReason(value);
    const error = validateReason(value);
    setReasonError(error);
  };

  const handleContactName = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setContactName(value);
      const error = validateContactName(value);
      setContactNameError(error);
    }
  };
  const handleEmail = (event) => {
    const value = event.target.value;
    setEmail(value);
    const error = validateEmail(value);
    setEmailError(error);
  };

  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    if (value.length <= 14) {
      setMobileNumber(value);
      const error = validateMobileNumber(value);
      setMobileNumberError(error);
    }
  };

  const validateMobileNumber = (value) => {
    // Check if the value is empty
    if (!value.trim()) {
      return "Contact number is required";
    }
    const numericRegex = /^[0-9]+$/;
    const noConsecutiveSameDigitRegex = /(.)\1{9}/;

    if (!numericRegex.test(value)) {
      return "Contact number must contain only numeric characters";
    }
    if (noConsecutiveSameDigitRegex.test(value)) {
      return "Continuous sequence of 10 same numbers is not allowed";
    }

    if (value.length > 14) {
      return "Contact number must not exceed 14 characters";
    }
    if (value.length < 10) {
      return "Contact number must contain at least 10 numeric characters";
    }

    return "";
  };

  const validateStartDate = (value) => {
    if (!value) {
      return "From date is required";
    }
    return "";
  };

  const validateEndDate = (value) => {
    if (!value) {
      return "To date is required";
    }
    return "";
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const startDateError = validateStartDate(date);
    setStartDateError(startDateError);

    // Check end date error
    if (enddate) {
      if (date.isAfter(enddate, "day")) {
        setEndDateError("To date must be on or after the from date");
      } else if (date.isSame(enddate, "day")) {
        if (date.isSameOrAfter(enddate)) {
          setEndDateError("End time must be greater than start time.");
        } else {
          setEndDateError("");
        }
      } else {
        setEndDateError("");
      }
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    const endDateError = validateEndDate(date);
    setEndDateError(endDateError);

    // Check start date error
    if (startdate) {
      if (date.isBefore(startdate, "day")) {
        setEndDateError("To date must be on or after the from date");
      } else if (date.isSame(startdate, "day")) {
        if (date.isSameOrBefore(startdate)) {
          setEndDateError("End time must be greater than start time.");
        } else {
          setEndDateError("");
        }
      } else {
        setEndDateError("");
      }
    }
  };

  const isValidStartDate = (current) => {
    return current.isSameOrAfter(moment(), "day");
  };

  const isValidEndDate = (current) => {
    return current.isSameOrAfter(startdate, "day");
  };

  const validateContactName = (value) => {
    if (!value.trim()) {
      return "Visitor name is required";
    }
    if (!/^[a-zA-Z][\w\s]*$/.test(value)) {
      return "Visitor name must start with a letter and contain only alphanumeric characters";
    }
    if (value.length > 100) {
      return "Visitor name must not exceed 100 characters";
    }
  };
  const validateEmail = (value) => {
    if (!value.trim()) {
      return "Email id is required";
    }

    const emailRegex =
      /^(?!.*\.\.)(?!.*\.[0-9])[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9.-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(value)) {
      return "Please enter a valid email id";
    }
  };

  // Submit handler
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate all fields
    const CompanyNameError = validateCompanyName(companyname);
    const ContactNameError = validateContactName(contactname);
    const EmailError = validateEmail(email);
    const MobileNumberError = validateMobileNumber(mobilenumber);
    const startdateError = validateStartDate(startdate);
    const enddateError = validateEndDate(enddate);
    const resonVisitError = validateReason(reason);

    // Set errors for each field
    setCompanyNameError(CompanyNameError);
    setContactNameError(ContactNameError);
    setEmailError(EmailError);
    setMobileNumberError(MobileNumberError);
    setStartDateError(startdateError);
    setEndDateError(enddateError);
    setReasonError(resonVisitError);
    setPhotoError(photoError);
    setIdProofError(idProofError);

    if (
      CompanyNameError ||
      ContactNameError ||
      EmailError ||
      MobileNumberError ||
      startdateError ||
      enddateError ||
      resonVisitError ||
      photoError ||
      idProofError
    ) {
      setLoading(false);
      return;
    } else {
      try {
        setLoading(true);
        // Format the startdate and enddate
        const formattedStartDate = moment(startdate).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = moment(enddate).format("YYYY-MM-DDTHH:mm:ss");
        const formData = new FormData();
        formData.append("name", contactname);
        formData.append("email", email);
        formData.append("phonenumber", mobilenumber);
        formData.append("purpose", reason);
        formData.append("start_datetime", formattedStartDate);
        formData.append("end_datetime", formattedEndDate);
        formData.append("company", company_id);
        formData.append("status", 2);

        // Append photo if not empty
        if (photo) {
          formData.append("photo", photo);
        }

        // Append idProof if not empty
        if (idProof) {
          formData.append("id_proof", idProof);
        }

        // Proceed with form submission
        const response = await createPass(formData);
        console.log(response);
        setLoading(false);
        setContactName("");
        setEmail("");
        setMobileNumber("");
        setStartDate(null);
        setEndDate(null);
        setReason("");
        setPhoto(null);
        setIdProof(null);
        showToast("success", response.data.message);
        const formatDate = (date) => {
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        };

        const today = new Date();
        const formattedToday = formatDate(today);

        if (role_name === "Company Admin" || role_name === "Company User") {
          navigate("/dashboard/pass-list"); // Navigate to the storage page
          navigate("/dashboard/pass-list", {
            state: {
              start_date: formattedToday,
              end_date: formattedToday,
            },
          });
        } else if (
          role_name === "Super Admin" ||
          role_name === "Park Admin" ||
          role_name === "Phase Admin"
        ) {
          navigate(`/dashboard/visitor-logs`); 
        }
        // Clear the file inputs
        document.getElementById("photoInput").value = "";
        document.getElementById("idProofInput").value = "";
        // Clear start date and end date inputs using refs
        startDateRef.current.state.inputValue = ""; // Clear start date
        endDateRef.current.state.inputValue = ""; // Clear end date
      } catch (error) {
        // Handle submission error
        console.log(error);
        setLoading(false);
        if (error.response) {
          // Check if error.response.data.message is an array
          if (
            Array.isArray(error.response.data.message) &&
            error.response.data.message.length > 0
          ) {
            // Access the first message object
            const errorMessage = error.response.data.message[0].message;
            showToast("error", errorMessage);
          } else if (typeof error.response.data.message === "string") {
            // If message is a string
            showToast("error", error.response.data.message);
          } else if (error.response.status === 500) {
            showToast("error", error.response.statusText);
          } else {
            showToast("error", "An unknown error occurred.");
          }
        } else {
          showToast("error", "Internal Server Error");
        }
      }
    }
  };

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <div>
        <Header onSkin={setSkin} />
        <div className=" main main-app d-flex flex-column align-items-start p-3 p-lg-4">
          <div className="my-3 ps-md-3 align-self-start">
            <div className="d-flex gap-2">
              <i
                className="ri-arrow-left-line "
                onClick={handleGoBack}
                style={{
                  marginRight: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              ></i>
              <div className="py-1">
                <ol className="breadcrumb fs-sm mb-1">
                  <li className="breadcrumb-item">
                    {(role_name === "Company Admin" ||
                      role_name === "Company User") && (
                      <Link to="/dashboard/generic">
                        <span>Go back</span>
                      </Link>
                    )}
                    {(role_name === "Super Admin" ||
                      role_name === "Phase Admin" ||
                      role_name === "Park Admin") && (
                      <Link to={`/dashboard/visitor-logs/${urlKey}/`}>
                        <span>Go back</span>
                      </Link>
                    )}
                  </li>
                </ol>
                <h4 className="main-title mb-0">Create New Pass</h4>
              </div>
            </div>
          </div>

          <Col xs="12" md="12">
            <hr className="mb-5" />
            <Card.Body className="ps-md-3 " style={{ maxWidth: "1200px" }}>
              <Form onSubmit={handleSubmit}>
                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-column flex-md-row gap-md-5 gap-2">
                    <div className="d-flex flex-column gap-2">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            style={{ textDecoration: "none" }}
                            eventKey="first"
                          >
                            Delegate Pass
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            style={{ textDecoration: "none" }}
                            eventKey="second"
                          >
                            Bulk Pass
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                          <div className="d-flex flex-column gap-4">
                            <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                              <div className="d-flex flex-column gap-2">
                                <Card.Title as="h6">
                                  <b>Visitor Details</b>
                                </Card.Title>
                                <div
                                  style={{ maxWidth: "320px" }}
                                  className="text-muted"
                                >
                                  Provide the necessary information to create a
                                  pass.
                                </div>
                              </div>
                              {/* Company Name */}
                              <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                                <div style={{ minWidth: "500px" }}>
                                  {/* Contact Name */}
                                  <Form.Group>
                                    <Form.Label>
                                      Visitor Name{" "}
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter name of visitor"
                                      value={contactname}
                                      onChange={handleContactName}
                                    />
                                    {contactnameError && (
                                      <div className="text-danger">
                                        {contactnameError}
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>
                                <div style={{ minWidth: "500px" }}>
                                  {/* HR Email */}
                                  <Form.Group>
                                    <Form.Label className="form-label">
                                      Email ID{" "}
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      className="form-control"
                                      type="email"
                                      placeholder="Enter email id for visitor contact"
                                      value={email}
                                      onChange={handleEmail}
                                    />
                                    {emailError && (
                                      <div className="text-danger">
                                        {emailError}
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>

                                <div style={{ minWidth: "500px" }}>
                                  {/* Mobile Number */}
                                  <Form.Group>
                                    <Form.Label>
                                      Contact Number (WhatsApp){" "}
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter contact number of visitor"
                                      value={mobilenumber}
                                      onChange={handleMobileNumberChange}
                                    />
                                    {mobilenumberError && (
                                      <div className="text-danger">
                                        {mobilenumberError}
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                              <div className="d-flex flex-column gap-2">
                                <Card.Title as="h6">
                                  <b>Visit Details</b>
                                </Card.Title>
                                <div
                                  style={{ maxWidth: "320px" }}
                                  className="text-muted"
                                >
                                  Enter details about the company and the
                                  purpose of the visit.
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                                <div style={{ minWidth: "500px" }}>
                                  <Form.Group>
                                    <Form.Label>
                                      Company Name{" "}
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter company name"
                                      value={companyname}
                                      disabled
                                    />
                                    {companynameError && (
                                      <div className="text-danger">
                                        {companynameError}
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>

                                <div style={{ minWidth: "500px" }}>
                                  <Form.Group>
                                    <Form.Label>
                                      Reason for Visit
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the reason for visit"
                                      value={reason}
                                      onChange={handleReasonChange}
                                      maxLength={100}
                                    />
                                    {reasonError && (
                                      <div className="text-danger">
                                        {reasonError}
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                              <div className="d-flex flex-column gap-2">
                                <Card.Title as="h6">
                                  <b>Validity</b>
                                </Card.Title>
                                <div
                                  style={{ maxWidth: "320px" }}
                                  className="text-muted"
                                >
                                  Specify the period during which the pass
                                  remains active.
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                                <div style={{ minWidth: "500px" }}>
                                  {/* Date Picker */}
                                  <Form.Group
                                    controlId="formStartDate"
                                    className="col-md-12 mb-0"
                                  >
                                    <Form.Label>
                                      From Date{" "}
                                      <span className="text-danger">*</span>
                                    </Form.Label>

                                    <Datetime
                                      value={startdate}
                                      onChange={handleStartDateChange}
                                      inputProps={{
                                        readOnly: true,
                                        placeholder: "Select the date and time",
                                      }}
                                      isValidDate={isValidStartDate}
                                      required
                                      ref={startDateRef}
                                    />

                                    {startdateError && (
                                      <div className="text-danger">
                                        {startdateError}
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>
                                <div style={{ minWidth: "500px" }}>
                                  {/* Date Picker */}
                                  <Form.Group
                                    controlId="formEndDate"
                                    className="col-md-12 mb-0"
                                  >
                                    <Form.Label>
                                      To Date
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Datetime
                                      value={enddate}
                                      onChange={handleEndDateChange}
                                      inputProps={{
                                        readOnly: true,
                                        placeholder: "Select the date and time",
                                      }}
                                      isValidDate={isValidEndDate}
                                      ref={endDateRef}
                                    />

                                    {enddateError && (
                                      <div className="text-danger">
                                        {enddateError}
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                              <div className="d-flex flex-column gap-2">
                                <Card.Title as="h6">
                                  <b>Document Uploads</b>
                                </Card.Title>
                                <div
                                  style={{ maxWidth: "320px" }}
                                  className="text-muted"
                                >
                                  Provide the necessary documents for
                                  verification purposes, ensuring compliance and
                                  authenticity.
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                                <div style={{ minWidth: "500px" }}>
                                  {/* Date Picker */}
                                  <Form.Group>
                                    <Form.Label>Upload Photo</Form.Label>
                                    <Form.Control
                                      type="file"
                                      accept="image/*"
                                      name="photo"
                                      id="photoInput"
                                      onChange={handlePhotoChange}
                                    />
                                    {photoError && (
                                      <div className="text-danger">
                                        {photoError}
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>
                                <div style={{ minWidth: "500px" }}>
                                  <Form.Group>
                                    <Form.Label>Upload Verified ID</Form.Label>
                                    <Form.Control
                                      type="file"
                                      accept="image/*,application/pdf"
                                      name="idProof"
                                      id="idProofInput"
                                      onChange={handleIdProofChange}
                                    />
                                    {idProofError && (
                                      <div className="text-danger">
                                        {idProofError}
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>

                                <div style={{ minWidth: "500px" }}>
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-sign mt-4"
                                    disabled={loading}
                                    style={{
                                      width: "150px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {loading ? (
                                      <>
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </>
                                    ) : (
                                      "Create Pass"
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                          <div className="d-flex flex-column gap-4">
                            <div className="d-flex flex-column flex-md-row gap-md-5 gap-4">
                              <div className="d-flex flex-column gap-2">
                                <Card.Title as="h6">
                                  <b>Document Upload</b>
                                </Card.Title>
                                <div
                                  style={{ maxWidth: "320px" }}
                                  className="text-muted"
                                >
                                  Upload the excel in the prescribed format for
                                  creating bulk pass.
                                  <br />
                                  <a
                                    href={sampleFormat}
                                    download
                                    className="link-primary" 
                                  >
                                    Click here
                                  </a>&nbsp;to download the format.
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-4 flex-grow-1 ps-md-5">
                                <div style={{ minWidth: "500px" }}>
                                  <Form.Group>
                                    <Form.Label>Upload</Form.Label>
                                    <Form.Control
                                      type="file"
                                      accept=".xls, .xlsx" // Accept both .xls and .xlsx files
                                      name="bulkPassExcel"
                                      id="bulkPassInput"
                                      onChange={handleBulkPassChange}
                                    />
                                    {bulkPassExcelError && (
                                      <div className="text-danger">
                                        {bulkPassExcelError}
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>

                                <div style={{ minWidth: "500px" }}>
                                  <Button
                                    type="button"
                                    variant="primary"
                                    className="btn-sign mt-4"
                                    disabled={loading}
                                    onClick={handleBulkPassUpload}
                                    style={{
                                      width: "150px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {loading ? (
                                      <>
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </>
                                    ) : (
                                      "Create Pass"
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Col>
        </div>
        <Footer />
      </div>
    </Tab.Container>
  );
}
