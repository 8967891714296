// ParkList.js
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Card, Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { showToast } from "../shared/Toaster";

import CardHeader from "react-bootstrap/esm/CardHeader";
import RandomColorGenerator from "../shared/RandomColorGenerator";
import { useSelector } from "react-redux";
import {
  getBuildingList,
  getPhaseAdminList,
  getGateList,
  getPhaseDetails,
  deletePhaseAdmin,
} from "../../services/Phase";
import { setPhaseId } from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import { setBuildingId } from "../../Redux/Actions";
import ConfirmationModal from "../shared/ConfirmationModal";


export default function PhaseDetailsScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location; // Extract state object from location
  const data = state && state.data; // Access data property from state object
  const searchParams = new URLSearchParams(location.search);
  const gen = searchParams.get("gen");
  const list = searchParams.get("list");
  const phaseId = useSelector((state) => state.phase.phaseId);
  const parkId = useSelector((state) => state.park.parkId);
  const [buildingList, setBuildingList] = useState([]);
  const [gateList, setGateList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [role, setRole] = useState("");
  const dispatch = useDispatch();
  const [passCount, setPassCount] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Page size of 6
  const [totalItems, setTotalItems] = useState(0);
  const [phaseadminList, setPhaseAdminList] = useState("");
  const [phaseDetails, setPhaseDetails] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [active, setActive] = useState(1);


  useEffect(() => {
    const role = localStorage.getItem("role_name");

    if (role == "Super Admin") {
      setRole("Super Admin");
    } else {
      setRole("Park Admin");
    }
  });

  // Now you can use gen to conditionally navigate
  const handleGoBack = () => {
    if (gen !== null && role === "Super Admin") {
      // navigate("/dashboard/phase-details");
      navigate("/dashboard/park-details/?gen");
    } else if (gen !== null && role === "Park Admin") {
      // navigate("/dashboard/phase-details");
      navigate("/dashboard/generic");
    } else if (list !== null) {
      navigate("/dashboard/phase-list/?gen");
    } else {
      navigate("/dashboard/building-list");
    }
  };

  // Skin switch logic
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const parkAddress = "Infopark Phase 1, Infopark Kochi P O, Kakkanad 682 042";

  useEffect(() => {
    document.title = "Phase Details";
    const PhaseId = phaseId;

    const fetchBuildingList = async () => {
      try {
        setLoading(true);
        const response = await getBuildingList(PhaseId, currentPage, pageSize, 1); // Assuming getITParkList is an async function that fetches data
        setBuildingList(response.data.data.Building_list.results); // Store the data in state
        setTotalItems(response.data.data.Building_list.count);
        setPassCount(response.data.data.Total_count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };


    //Function to fetch the gate list
    const fetchGateList = async () => {
      try {
        const response = await getGateList(phaseId, 1); // Assuming getITParkList is an async function that fetches data
        setGateList(response.data.data); // Store the data in state
      } catch (error) {
      }
    };

    fetchGateList();
    fetchBuildingList();
    switchSkin(skin);
  }, [skin, currentPage, phaseId, pageSize]);

  //Function to fetch the it park admins list
  const fetchPhaseAdminList = async () => {
    try {
      const response = await getPhaseAdminList(phaseId, active); // Assuming getITParkList is an async function that fetches data
      setPhaseAdminList(response.data.data.results); // Store the data in state
    } catch (error) {
      setPhaseAdminList("");
    }
  };

  useEffect(() => {
    fetchPhaseAdminList();
    switchSkin(skin);
  }, [skin, currentPage, pageSize, active]);

  //Delete functionality
  const handleDelete = async (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setShowModal(false);
    try {
      setLoading(true);
      const response = await deletePhaseAdmin(currentIndex);
      setLoading(false);
      showToast("success", response.data.message);
      fetchPhaseAdminList(); // Refetch the list after deletion
    } catch (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.message) {
          showToast("error", error.response.data.message);
        } else if (error.response.status === 500) {
          showToast("error", error.response.statusText);
        }
      } else {
        showToast("error", "Internal Server Error");
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setActive(newValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    //Function to fetch the gate list
    const fetchPhasDetails = async () => {
      try {
        const response = await getPhaseDetails(phaseId); // Assuming getITParkList is an async function that fetches data
        setPhaseDetails(response.data.data); // Store the data in state
      } catch (error) {
      }
    };

    fetchPhasDetails();
  }, []);

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 position-relative">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div className="d-flex gap-2">
            <i
              className="ri-arrow-left-line"
              onClick={handleGoBack}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            ></i>
            <div className="py-1">
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  {gen !== null && role === "Super Admin" ? (
                    <Link to="/dashboard/park-details/?gen">
                      <span>Go back</span>
                    </Link>
                  ) : gen !== null && role === "Park Admin" ? (
                    <Link to="/dashboard/generic">
                      <span>Go back</span>
                    </Link>
                  ) : (
                    <Link to="/dashboard/phase-list/?gen">
                      <span>Go back</span>
                    </Link>
                  )}
                </li>
              </ol>
              <h4 className="main-title mb-0">{phaseDetails?.PhaseName}</h4>
            </div>
          </div>
        </div>

        <div className="d-flex flex-md-row flex-column gap-2">
          <div className="mr-2 flex-grow-1 d-flex flex-column gap-2">
            <div>
              <Card.Body className="p-4">
                <div className="uppercase text-muted">Address</div>
                <hr />
                <div>
                  {" "}
                  {phaseDetails?.address?.split("+").map((line, index) => (
                    <p key={index} className="mb-1 text-secondary h6">
                      {line}
                    </p>
                  ))}
                </div>
              </Card.Body>
            </div>
            <Card className="card-one" style={{ maxHeight: "350px" }}>
              <Card.Header>
                <Card.Title as="h6">Gate</Card.Title>
              </Card.Header>
              <Card.Body className="scrollable-card-body">
                <Table className="table-two mb-4" responsive>
                  <thead>
                    <tr>
                      <th>Gate</th>
                      <th>Contact Name</th>
                      <th>Email ID</th>
                    </tr>
                  </thead>
                  <tbody className="p-0">
                    {gateList?.length > 0 ? (
                      gateList.slice(0, 4).map((item, index) => (
                        <tr key={index}>
                          <td className="gate-table">{item.gateName}</td>
                          <td className="gate-table">{item.contactName}</td>
                          <td className="gate-table">{item.email}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <h6
                  className="fw-semibold mb-1 text-left"
                  onClick={() => {
                    dispatch(setPhaseId(phaseId));
                  }}
                >
                  <Link to="/dashboard/gate-list/?gen">View all gates</Link>
                </h6>
              </Card.Footer>
            </Card>
            <Card className="card-one" style={{ maxHeight: "500px" }}>

              <Card.Header>
                <Card.Title as="h6">Building Logs</Card.Title>
              </Card.Header>
              <Card.Body className="p-3 scrollable-card-body">
                <div className="d-flex flex-wrap justify-content-between mb-4">
                  <div className="earning-item d-flex flex-column">
                    <div className="earning-icon bg-primary">
                      <i className="ri-building-3-line"></i>
                    </div>
                    <h4>{passCount.building || 0}</h4>
                    <label>Buildings</label>
                  </div>
                  <div className="earning-item d-flex flex-column">
                    <div className="earning-icon bg-twitter">
                      <i className="ri-file-list-2-line"></i>
                    </div>
                    <h4>{passCount.pass_generated || 0}</h4>
                    <label>Pass Generated</label>
                  </div>
                  <div className="earning-item d-flex flex-column">
                    <div className="earning-icon bg-success">
                      <i className="ri-team-line"></i>
                    </div>
                    <h4>{passCount.visited || 0}</h4>
                    <label>Visited</label>
                  </div>
                </div>
                <Table
                  className="table-two mb-4"
                  style={{ overflowY: "scroll" }}
                  responsive
                >
                  <thead>
                    <tr>
                      <th>Building Name</th>
                      <th>Total Pass Generated</th>
                      <th>Total Visited</th>
                    </tr>
                  </thead>
                  <tbody>
                    {buildingList?.length > 0 ? (
                      buildingList.slice(0, 4).map((item, index) => (
                        <tr key={index}>
                          <td
                            style={{ cursor: "pointer" }}
                            className="text-primary gate-table"
                            onClick={() => {
                              // Dispatch action to update building ID in Redux store
                              dispatch(setBuildingId(item.id)); // assuming 'item.id' is the building ID
                              dispatch(setPhaseId(phaseId));
                            }}
                          >
                            <Link to="/dashboard/building-details/?gen">
                              {item.buildingName}
                            </Link>
                          </td>
                          <td>{item.count.pass_generated || 0}</td>
                          <td className="text-success">
                            {item.count.visited || 0}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <h6
                  className="fw-semibold mb-1 text-left"
                  onClick={() => {
                    dispatch(setPhaseId(phaseId));
                  }}
                >
                  <Link to="/dashboard/building-list/?gen">
                    View all buildings
                  </Link>
                </h6>
              </Card.Footer>
            </Card>
          </div>
          <div className="d-flex flex-grow-1">
            <Card
              className="card-one d-flex flex-column flex-grow-1"
              style={{ maxHeight: "1100px", overflowY: "auto" }}
            >
              <CardHeader className="d-flex mb-2 justify-content-between align-items-center shadow-sm">
                <Card.Title as="h6">View Phase Admins</Card.Title>
                <div className="d-flex ml-auto align-items-center">
                  <div
                    className="form-check form-switch"
                    style={{
                      marginRight: "1rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="flexSwitchCheckDefault"
                      style={{ marginRight: "50px" }}
                    >
                      {active === 1 ? "Active" : "Inactive"}
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      name="active"
                      value={active}
                      checked={active === 1}
                      onChange={handleCheckboxChange}
                      style={{ width: "50px", height: "25px" }} // Adjust size as needed
                    />
                  </div>
                  <Button
                  as={Link}
                  to="/dashboard/create-phase-admin"
                  className="bg-primary text-white hover-translate"
                >
                  Create Phase Admin
                </Button>
                </div>
                
              </CardHeader>
              <div className="d-flex flex-column gap-2 flex-grow-1 scrollable-content">
                {Array.isArray(phaseadminList) ? (
                  phaseadminList.map((connection, index) => (
                    <div key={index}>
                      <div className="p-0 border-bottom border-1 border-muted">
                        <Card.Body className="d-flex gap-4">
                          <div
                            style={{
                              background: RandomColorGenerator({
                                name: connection.contact_name,
                                location: connection.email,
                              }),
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                          >
                            {connection.contact_name.charAt(0)}
                          </div>
                          <div className="d-flex flex-column flex-grow-1">
                            <h6 className="my-2 h5 d-flex justify-content-between">
                              <div className="contact-column">
                                {connection.contact_name}
                              </div>
                              {(active === 1)&& (
                                <div className="d-flex gap-3 align-items-center">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Edit</Tooltip>}
                                >
                                  <Link
                                    to={{
                                      pathname: `/dashboard/create-phase-admin/`,
                                      search: `?id=${connection.id}`,
                                    }}
                                    className="text-primary"
                                  >
                                    <FaPencilAlt
                                      style={{ cursor: "pointer" }}
                                      className="h6"
                                    />
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Delete</Tooltip>}
                                >
                                  <Link
                                    to="#"
                                    className="text-primary"
                                    onClick={() => handleDelete(connection.id)}
                                  >
                                    <FaTrash
                                      style={{ cursor: "pointer" }}
                                      className="h6"
                                    />
                                  </Link>
                                </OverlayTrigger>
                              </div>
                              )}
                            </h6>

                            <div className="d-flex flex-column flex-md-row gap-3 gap-md-5 flex-grow-1 py-2 border-top-2">
                              <div className="d-flex flex-column gap-1 column contact-column">
                                <div className="uppercase text-muted mobile-column">
                                  <strong>Contact Number</strong>
                                </div>
                                <div className="mobile-column">
                                  {connection.contact_number}
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-1 column email-column">
                                <div className="uppercase text-muted">
                                  <strong>Email ID</strong>
                                </div>
                                <div>{connection.email}</div>
                              </div>
                              <div className="d-flex flex-column gap-1 column email-column">
                                <div className="uppercase text-muted">
                                  <strong>Alternate Email ID</strong>
                                </div>
                                <div>{connection.alternate_email}</div>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{ textAlign: "center" }}>No Data Found</div>
                )}
              </div>
            </Card>
          </div>
          <ConfirmationModal
            show={showModal}
            onHide={() => setShowModal(false)}
            onConfirm={handleConfirm}
            message="Are you sure you want to delete the phase admin?"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
